import React, { useState , useEffect} from 'react';
import { useImmer } from 'use-immer';
import { Link, useHistory, useLocation} from 'react-router-dom';
import {
  InputAdornment,
  IconButton,
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MainContainer from '../MainContainer';

// Utils
import {
  LOGIN_PAGE,
  PRIVACY_POLICY_PAGE,
  TERMS_OF_SERVICE_PAGE
} from 'utils/routesUtils';
import { ERROR, ERROR_TEXT_REQUIRED, PENDING_EMAIL, PLATFORM } from 'utils/constants';

//services
import { signupUserService } from 'services/signup';
import { AttemptLoginService, saveUserTokenService } from 'services/login';

// Utils.
import { validateForm } from 'utils/validationUtils';
import {
  validateEmail,
  validatePassword,
  validatePasswordMessage,
  validatePhoneNumber
} from 'utils/validationUtils';
import { maskPhoneNumberInput, sanitizePhoneNumber } from 'utils/numberUtils';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { SHIPPER, CARRIER, INDIVIDUAL, BUSINESS, BROKER } from 'utils/users';
import { DASHBOARD_PAGE, VERIFY_PAGE } from 'utils/routesUtils';
import ButtonWithBackground from 'components/Form/Button';
import { sendOtpEmailService } from 'services/Verification';
import { ReactComponent as TickIcon } from 'assets/images/onboarding/check.svg';

import { createStyles } from '@mui/material';
import clsx from 'clsx';
import SelectDropdown from 'components/Form/SelectDropdown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ValidationPassward from '../ValidationPassword';
import { identifyUser, trackEvent } from 'utils/MixPanel';

const SignupPage = ({ setSnackbar }) => {
  const history = useHistory();
  const classes = useStyles();
  const [showPassord, setPasswordVisibility] = useState(false);
  const [showConfirmPassword, setConfirmPasswordVisibility] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [field, setField] = useState(null);
  const location = useLocation();

  const [state, setState] = useImmer({
    loader: false,
    triedToSubmitForm: false,
    showPassword: false,
    showConfirmPassword: false,
    values: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      confirm_password: '',
      password: '',
      phonePure: '',
      userRole: CARRIER,
      accountType: INDIVIDUAL,
      platform: PLATFORM
    },
    errors: {
      fname: 'Please enter your first name',
      lname: 'Please enter your last name',
      email: 'Please enter a valid email',
      phone: 'Please enter a valid phone number',
      confirm_password: ERROR_TEXT_REQUIRED,
      password: ERROR_TEXT_REQUIRED
    },
    shipperTypes: [
      {
        value: INDIVIDUAL,
        label: INDIVIDUAL
      },
      {
        value: BUSINESS,
        label: BUSINESS
      },
      // for now we are not working with brokers
      // {
      //   value: BROKER,
      //   label: BROKER
      // }
    ]
  });

  const handleClickShowPassword = () => {
    setState(draft => {
      draft.showPassword = !state.showPassword;
    });
  };

  const handleClickShowConfirmPassword = () => {
    setState(draft => {
      draft.showConfirmPassword = !state.showConfirmPassword;
    });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('source')) {
      const fieldValue = searchParams.get('source');
      setField(fieldValue);
    }
  }, [location.search]);



  const handleChange = event => {
    event.persist();
    const { value, name } = event.target;
    let errors = { ...state.errors };
    let values = { ...state.values };

    switch (name) {
      case 'user_role':
        values.userRole = value;
        break;
      case 'shipper_type':
        values.accountType = value;
        break;
      case 'fname':
        errors.fname = value.length ? '' : 'Please enter your first name';
        values.fname = value;
        break;
      case 'lname':
        errors.lname = value.length ? '' : 'Please enter your first name';
        values.lname = value;
        break;
      case 'email':
        errors.email = validateEmail(value) ? '' : 'Please enter a valid email';
        values.email = value;
        break;
      case 'phone':
        errors.phone = validatePhoneNumber(value) ? '' : 'Please enter a valid phone number';
        values.phone = maskPhoneNumberInput(value);
        values.phonePure = sanitizePhoneNumber(maskPhoneNumberInput(value));
        break;
      case 'password':
        errors.password = validatePassword(value) ? '' : ERROR_TEXT_REQUIRED;
        values.password = value;
        break;
      case 'confirm_password':
        errors.confirm_password = value === values.password&& validatePassword(value) ? '' : ERROR_TEXT_REQUIRED;
        values.confirm_password = value;
        break;
      default:
        break;
    }
  
    setState(draft => {
      draft.errors = { ...errors };
      draft.values = { ...values };
    });
  };

  const showLoader = val => {
    setState(draft => {
      draft.loader = val;
    });
  };

  const attemptLogin = async () => {
    try {
      const res = await AttemptLoginService(
        state.values.email.toLowerCase(),
        state.values.password
      );
      if (res.token) {
        saveUserTokenService(res);
        identifyUser();
        if (res.onboarding_step === PENDING_EMAIL) {
          sendOtpEmailService(state.values.email);
          history.push(VERIFY_PAGE);
        } else {
          history.push(DASHBOARD_PAGE);
        }
      }
    } catch (e) {
      showLoader(false);
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e.data)
      });
    }
  };

  const attemptSignup = async () => {
    if (state.values.password !== state.values.confirm_password) {
      setSnackbar({
        type: ERROR,
        message: 'Password & confirm password not matched!'
      });
      return;
    }

    showLoader(true);
    try {
      const obj = {
        first_name: state.values.fname,
        last_name: state.values.lname,
        email: state.values.email.toLowerCase(),
        phone: state.values.phonePure,
        password: state.values.password,
        platform: state.values.platform
      };

      if (state.values.userRole === SHIPPER)
        obj.account_type = state.values.accountType;

        if (field !== null) {
          obj.campaign_source = field;
        }

      await signupUserService(
        obj,
        obj.account_type === BROKER ? BROKER : state.values.userRole
      );
      attemptLogin();
      trackEvent('Create Account Form Filled');
    } catch (e) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e?.data)
      });

      showLoader(false);
    }
  };

  const handleSubmit = () => {
    if (validateForm(state.errors)) {
      attemptSignup();
    } else {
      setState(draft => {
        draft.triedToSubmitForm = true;
      });
      if (
      
        validatePasswordMessage(state.values.password) !== true
      ) {
        setSubmitClicked(true);
      }
    }
  };

  const UserRoleRadio = withStyles({
    root: {
      '&$checked': {
        color: 'red'
      }
    },
    checked: {}
  })(rest => <Radio color="default" {...rest} />);

  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  };

  return (
    <MainContainer>
      <Box className="navigation-box navigate-absolute">
        <Typography style={{ fontSize: 16, fontWeight: 600 }}>
          <Link to={LOGIN_PAGE}>Sign in</Link>
        </Typography>
      </Box>
      <Box className="form-box">
        <Typography variant="h3" className="heading">
          Get Started
        </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} className="user-type">
            <RadioGroup
              row
              value={state.values.userRole}
              name="user_role"
              onChange={handleChange}>
              <FormControlLabel
                title={SHIPPER}
                value={SHIPPER}
                style={{ marginRight: 8 }}
                control={
                  <UserRoleRadio
                    className={clsx(
                      classes.radio,
                      state.values.userRole === CARRIER ? classes.bg : null
                    )}
                    checkedIcon={<TickIcon />}
                  />
                }
                label={
                  <Typography
                    style={{
                      marginLeft: 10,
                      fontWeight: 500,
                      color:
                        state.values.userRole === SHIPPER
                          ? '#e43935'
                          : 'inherit'
                    }}>
                    I'm a Shipper
                  </Typography>
                }
              />
              <FormControlLabel
                title={CARRIER}
                value={CARRIER}
                style={{ marginLeft: 8 }}
                control={
                  <UserRoleRadio
                    className={clsx(
                      classes.radio,
                      state.values.userRole === SHIPPER ? classes.bg : null
                    )}
                    checkedIcon={<TickIcon />}
                  />
                }
                label={
                  <Typography
                    style={{
                      marginLeft: 10,
                      fontWeight: 500,
                      color:
                        state.values.userRole === CARRIER
                          ? '#e43935'
                          : 'inherit'
                    }}>
                    I'm a Carrier
                  </Typography>
                }
              />
            </RadioGroup>
          </Grid> 
          <Grid item xs={12} className="user-type">
            {state.values.userRole === SHIPPER ? (
              <SelectDropdown
                value={state.values.accountType}
                changeHandler={handleChange}
                name="shipper_type"
                options={state.shipperTypes}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="First name"
              name="fname"
              disabled={state.loader}
              error={
                state.triedToSubmitForm && state.errors.fname ? true : false
              }
              value={state.values.fname}
              onChange={handleChange}
              className={`${classes.activeTextField} input-box capitalizefield`}
              />
             {(state.triedToSubmitForm ) && state.errors.fname && (
    <span><Typography className={classes.errorMsg} >{state.errors.fname}</Typography></span>
    )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Last name"
              name="lname"
              disabled={state.loader}
              error={
                state.triedToSubmitForm && state.errors.lname ? true : false
              }
              value={state.values.lname}
              onChange={handleChange}
              className={`${classes.activeTextField} input-box capitalizefield`}
            
              />
             {(state.triedToSubmitForm ) && state.errors.lname && (
    <span><Typography className={classes.errorMsg} >{state.errors.lname}</Typography></span>
    )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Cell Phone Number"
              name="phone"
              disabled={state.loader}
              error={
                state.triedToSubmitForm && state.errors.phone ? true : false
              }
              value={state.values.phone}
              onChange={handleChange}
              className={`${classes.activeTextField} input-box`}
            />
               {(state.triedToSubmitForm ) && state.errors.phone && (
    <span><Typography className={classes.errorMsg} >{state.errors.phone}</Typography></span>
    )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Email Address"
              type="email"
              name="email"
              disabled={state.loader}
              error={
                state.triedToSubmitForm && state.errors.email ? true : false
              }
              value={state.values.email}
              onChange={handleChange}
              className={`${classes.activeTextField} input-box`}
            />
             {(state.triedToSubmitForm ) && state.errors.email && (
    <span><Typography className={classes.errorMsg} >{state.errors.email}</Typography></span>
  )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={!showPassord ? 'password' : 'text'}
              variant="outlined"
              fullWidth
              placeholder="Password"
              name="password"
              disabled={state.loader}
              value={state.values.password}
              error={
                state.triedToSubmitForm && state.errors.password
                  ? true
                  : false
              }
              onChange={handleChange}
              className={`${classes.activeTextField} input-box end-endorment`}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      style={{
                        padding: 12,
                        cursor: 'pointer',
                        color: '#757575'
                      }}
                      onClick={() => setPasswordVisibility(!showPassord)}>
                      {!showPassord ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirm_password"
              disabled={state.loader}
              error={
                state.triedToSubmitForm && state.errors.confirm_password
                  ? true
                  : false
              }
              value={state.values.confirm_password}
              onChange={handleChange}
              className={`${classes.activeTextField} input-box end-endorment`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      style={{
                        padding: 12,
                        cursor: 'pointer',
                        color: '#757575'
                      }}
                      onClick={() =>
                        setConfirmPasswordVisibility(!showConfirmPassword)
                      }>
                      {!showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <ValidationPassward passward={state.values.password} submitClicked={submitClicked}/>
<ButtonWithBackground
          disabled={state.loader}
          size="large"
          fullWidth
          clickHandler={handleSubmit}
          label="Create Account"
          style={{ fontWeight: 600, marginTop: 20 }}
        />
        <Typography
          variant="h6"
          className="privacy-statement"
          style={{ paddingTop: 10 }}>
          <Typography
            style={{ color: '#838383', fontSize: 12, fontWeight: 600 }}>
            By pressing 'Create Account' you agree to our
          </Typography>
        </Typography>
        <Typography variant="h6" className="privacy-statement">
          <Link
            style={{ color: '#e53835', fontWeight: 600 }}
            target="_blank"
            to={TERMS_OF_SERVICE_PAGE}>
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link
            style={{ color: '#e53835', fontWeight: 600 }}
            target="_blank"
            to={PRIVACY_POLICY_PAGE}>
            Privacy Policy.
          </Link>
        </Typography>
      </Box>
    </MainContainer>
  );
};
const useStyles = makeStyles(theme =>
  createStyles({
    bg: {
      '& > span': {
        background: '#fff'
      }
    },
    radio: {
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: 20,
      padding: 0,
      '& > span': {
        borderRadius: 20
      },
      '& > span > div': {
        color: '#fff',
        background: '#fff',
        borderRadius: 20,
        width: 20,
        height: 20,
        opacity: 0
      }
    },
    radio2: {
      border: '1px solid #ccc',
      borderRadius: 20,
      padding: 0,
      '& > span': {
        borderRadius: 20
      },
      '& > span > div': {
        color: '#F5F8FA',
        background: '#F5F8FA',
        borderRadius: 15,
        width: 15,
        height: 15,
        opacity: 0
      }
      
    },
    errorMsg:
    {
      color:'#ED3D2E',
       fontSize:14, 
       fontWeight:600,
       marginTop:12,
       marginBottom:-6,
       
    },
    activeTextField: {
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)' 
      }
    }

  }));
  export default SignupPage;

import React from 'react';

import { ReactComponent as CreateListingIcon } from 'assets/images/job/create.svg';
import { ReactComponent as RouteIcon } from 'assets/images/job/route.svg';
import { ReactComponent as PriceIcon } from 'assets/images/job/price.svg';
import { ReactComponent as MagnifyIcon } from 'assets/images/job/magnify.svg';

import { ReactComponent as CreateListingCompleteIcon } from 'assets/images/job/create-complete.svg';
import { ReactComponent as RouteCompleteIcon } from 'assets/images/job/route-complete.svg';
import { ReactComponent as PriceCompleteIcon } from 'assets/images/job/price-complete.svg';
import { ReactComponent as MagnifyCompleteIcon } from 'assets/images/job/magnify-complete.svg';

import { ReactComponent as CreateListingCompleteIcon2 } from 'assets/images/job/create-complete2.svg';
import { ReactComponent as RouteCompleteIcon2 } from 'assets/images/job/route-complete2.svg';
import { ReactComponent as PriceCompleteIcon2 } from 'assets/images/job/price-complete2.svg';
import { ReactComponent as MagnifyCompleteIcon2 } from 'assets/images/job/magnify-complete2.svg';
import axiosClient from 'utils/axiosClient';
import { errorMessageSingleLine } from 'utils/errorUtils';
import {
  ERRORS,
  LOAD_STATUS,
  LOAD_STEP,
  MESSAGES,
  SHIPMENT_DETAIL,
  SOMETHING_WENT_WRONG
} from 'utils/constants';
import { getUserIdService, getUserTypeService } from 'services/login';
import { convertToLabel } from 'utils/textUtils';
import { CARRIER, SHIPPER } from 'utils/users';
import { shipmentService, shipmentStatus } from 'services/Shipments';
import Axios from 'axios';
import { trackEvent } from 'utils/MixPanel';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const version = process.env.REACT_APP_API_VER;

//declarations
const SET_JOB = 'SET_JOB';
const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
const SET_AVAILABLE_JOBS = 'SET_AVAILABLE_JOBS';
const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';
const CATEGORIES_LOADING = 'categories_loading';
const SUBCATEGORIES_LOADING = 'subcategories_loading';
const SET_SUBCATS = 'set_subcategories';
const QUOTE_LOADING = 'QUOTE_LOADING';
const BOOKING_LOADING = 'BOOKING_LOADING';
const QUOTE = 'QUOTE';
const BOOKING = 'BOOKING';
const SET_CATS = 'SET_CATS';
const QUOTE_SUCCESS = 'QUOTE_SUCCESS';
const SET_BID_STEP = 'SET_BID_STEP';
const SET_PLACE_BID_FORM_DATA = 'SET_PLACE_BID_FORM_DATA';
const CREATE_CHAT_URL_LOADING = 'CREATE_CHAT_URL_LOADING';
const SET_ACTIVE_TYPE = 'SET_ACTIVE_TYPE';
const SET_LOADS_LIST_LOADING = 'SET_LOADS_LIST_LOADING';
const SET_UPDATE_TABS_LOADING = 'SET_UPDATE_TABS_LOADING';
const UPDATE_TABS = 'UPDATE_TABS';
const SET_ADMIN_LOADS_LIST_LOADING = 'SET_ADMIN_LOADS_LIST_LOADING';
const SET_LOADS = 'SET_LOADS';
const SET_ADMIN_LOADS = 'SET_ADMIN_LOADS'
const SET_LOADS_ORDERING = 'SET_LOADS_ORDERING';
const SET_INVOICE_ORDERING = 'SET_INVOICE_ORDERING';
const GET_INVOICE_DATA_LOADING = 'GETINVOICE_DATA_LOADING';
const GET_INVOICE_DATA = 'GET_INVOICE_DATA';
const LOAD_DETAIL_LOADING = 'LOAD_DETAIL_LOADING';
const SET_LOAD_DETAIL = 'SET_LOAD_DETAIL';
const BID_PLACED_CARRIER_LIST_LOADING = 'BID_PLACED_CARRIER_LIST_LOADING';
const BID_PLACED_CARRIER_LIST = 'BID_PLACED_CARRIER_LIST';
const BID_PLACED_CARRIER_LIST_LOAD_MORE = 'BID_PLACED_CARRIER_LIST_LOAD_MORE';
const ASSIGN_JOB_CARRIER_LOADING = 'ASSIGN_JOB_CARRIER_LOADING';
const ASSIGN_JOB_CARRIER = 'ASSIGN_JOB_CARRIER';
const DELETE_JOB_LOADING = 'DELETE_JOB_LOADING';
const DELETE_JOB = 'DELETE_JOB';
const JOB_NOTE_LOADING = 'JOB_NOTE_LOADING';
const JOB_NOTE = 'JOB_NOTE';
const NOTES_LIST_LOADING = 'NOTES_LIS_LOADING';
const SET_NOTES = 'SET_NOTES'
const DELETE_JOB_NOTE_LOADING = 'DELETE_JOB_NOTE_LOADING';
const DELETE_NOTE = 'DELETE_NOTE';
const EDIT_NOTE = 'EDIT_NOTE';
const CANCEL_EDIT_NOTE = 'CANCEL_EDIT_NOTE';
const UPDATE_SHIPMENT_NOTE = 'UPDATE_SHIPMENT_NOTE'
const JOB_FAVOURITE_LOADING = 'JOB_FAVOURITE_LOADING';
const JOB_FAVOURITE = 'JOB_FAVOURITE';
const CARRIERS_LIST_LOADING = 'CARRIERS_LIST_LOADING';
const CARRIERS_LIST = 'CARRIERS_LIST';
const CREATE_OFFER_LOADING = 'CREATE_OFFER_LOADING';
const CREATE_OFFER ='CREATE_OFFER';
const GET_OFFERS_LIST_LOADING = 'GET_OFFERS_LIST_LOADING';
const GET_OFFERS_LIST = 'GET_OFFERS_LIST';
const GET_OFFERS_NEXT_LIST_LOADING = 'GET_OFFERS_NEXT_LIST_LOADING';
const ACCEPT_OFFER_LOADING = 'ACCEPT_OFFER_LOADING';
const ACCEPT_OFFER = 'ACCEPT_OFFER';
const REJECT_OFFER_LOADING = 'REJECT_OFFER_LOADING';
const REJECT_OFFER = 'REJECT_OFFER';
const CANCEL_OFFER_LOADING = 'CACEL_OFFER_LOADING';
const CANCEL_OFFER = 'CACEL_OFFER';
const SET_OFFERED_LOADS_ORDERING = 'SET_OFFERED_LOADS_ORDERING';
const SET_FILTERED_LOADS = 'SET_FILTERED_LOADS';
const RESET_FILTERED_LOADS = 'RESET_FILTERED_LOADS';
const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
const DASHBOARD_DATA = 'DASHBOARD_DATA';
const DASHBOARD_GRAPH_DATA = 'DASHBOARD_GRAPH_DATA';
const MODIFY_IMAGE_URL = 'MODIFY_IMAGE_URL';
const SET_INFO_DOCS_SHARE = 'SET_INFO_DOCS_SHARE';
const SEND_DOCUMENT_MEDIA_LOADING = 'SEND_DOCUMENT_MEDIA_LOADING';
const SEND_DOCUMENT_MEDIA = 'SEND_DOCUMENT_MEDIA';
const RESET_DOCUMENT_MEDIA = 'RESET_DOCUMENT_MEDIA';
const SET_FAVAURITES = 'SET_FAVAURITES';
const FAVAURITES_LOADING = 'FAVAURITES_LOADING';
const FAVAURITES_NEXT_LOADING = 'FAVAURITES_NEXT_LOADING';
const SET_LOADS_NEXT_LIST = 'SET_LOADS_NEXT_LIST';
const LOADS_NEXT_LIST_LOADING = 'LOADS_NEXT_LIST_LOADING';
const SET_SAVED_LOADS_ORDERING = 'SET_SAVED_LOADS_ORDERING';
const SET_JOB_DELIVERED_LOADING = 'SET_JOB_DELIVERED_LOADING';
const SET_JOB_DELIVERED = 'SET_JOB_DELIVERED';
const SET_JOB_ACTIVE_LOADING = 'SET_JOB_ACTIVE_LOADING';
const SET_JOB_ACTIVE = 'SET_JOB_ACTIVE';
const SET_JOB_ACTIVE_FROM_DELIVERED_LOADING = 'SET_JOB_ACTIVE_FROM_DELIVERED_LOADING';
const SET_JOB_ACTIVE_FROM_DELIVERED = 'SET_JOB_ACTIVE_FROM_DELIVERED';
const SET_JOB_NEW_FROM_ACTIVE_LOADING = 'SET_JOB_NEW_FROM_ACTIVE_LOADING';
const SET_JOB_NEW_FROM_ACTIVE = 'SET_JOB_NEW_FROM_ACTIVE';
const SET_INITIAL_MARKED_DELEVERED_JOB_ID =
  'SET_INITIAL_MARKED_DELEVERED_JOB_ID';
const SET_INITIAL_MARKED_ACTIVE_FROM_DELIVERED_JOB_ID =
  'SET_INITIAL_MARKED_ACTIVE_FROM_DELIVERED_JOB_ID';
const SET_INITIAL_MARKED_NEW_FROM_ACTIVE_JOB_ID =
  'SET_INITIAL_MARKED_NEW_FROM_ACTIVE_JOB_ID';
const SET_INITIAL_MARKED_ACTIVE_JOB_ID = 'SET_INITIAL_MARKED_ACTIVE_JOB_ID';
const SET_CONVERSATION_URL = 'SET_CONVERSATION_URL';
const SET_ARCHIVE_JOB_COUNT = 'SET_ARCHIVE_JOB_COUNT';
const SET_UNARCHIVE_JOB_COUNT = 'SET_UNARCHIVE_JOB_COUNT';
const GET_CUSTOMER_LOADING = 'GET_CUSTOMER_LOADING';
const SET_INVOICE_LOADING = 'SET_INVOICE_LOADING';
const SET_INVOICE = 'SET_INVOICE';
const GET_INVOICE_LOADING = 'GET_INVOICE_LOADING';
const GET_INVOICE = 'GET_INVOICE';
const GET_INVOICE_NEXT_LIST_LOADING = 'GET_INVOICE_NEXT_LIST_LOADING';
const GET_INVOICE_NEXT_LIST = 'GET_INVOICE_NEXT_LIST';
const GET_INVOICE_DETAIL_LOADING = 'GET_INVOICE_DETAIL_LOADING';
const GET_INVOICE_DETAIL = 'GET_INVOICE_DETAIL';
const MARK_INVOICE_PAID_LOADING = 'MARK_INVOICE_PAID_LOADING';
const MARK_INVOICE_PAID = 'MARK_INVOICE_PAID';
const SEND_REMAINDER_LOADING = 'SEND_REMAINDER_LOADING';
const SEND_REMAINDER = 'SEND_REMAINDER';
const PREVIEW_INVOICE_LOADING = 'PREVIEW_INVOICE_LOADING';
const PREVIEW_INVOICE = 'PREVIEW_INVOICE';
const RESET_PREVIEW_INVOICE = 'RESET_PREVIEW_INVOICE';
const MARK_INVOICE_ARCHIVE_LOADING = 'MARK_INVOICE_ARCHIVE_LOADING';
const MARK_INVOICE_ARCHIVE = 'MARK_INVOICE_ARCHIVE';
const MARK_INVOICE_UNARCHIVE_LOADING = 'MARK_INVOICE_UNARCHIVE_LOADING';
const MARK_INVOICE_UNARCHIVE = 'MARK_INVOICE_UNARCHIVE';
const NO_VALID_INVOICE_LIST = 'NO_VALID_INVOICE_LIST';
const REDIRECTED_INVOICE ='REDIRECTED_INVOICE';
const REDIRECTED_SHIPMENT ='REDIRECTED_SHIPMENT';
const CLEAR_REDIRECTED_FOR_INVOICE ='CLEAR_REDIRECTED_FOR_INVOICE';
const GET_CUSTOMER = 'GET_CUSTOMER';
const GET_CUSTOMER_NEXT_LIST_LOADING = 'GET_CUSTOMER_NEXT_LIST_LOADING';
const GET_CUSTOMER_NEXT_LIST = 'GET_CUSTOMER_NEXT_LIST';
const GET_CUSTOMER_DETAILS_LOADING = 'GET_CUTOMER_DETAILS_LOADING';
const GET_CUSTOMER_DETAILS = 'GET_CUTOMER_DETAILS';
const GET_CUSTOMER_SHIPMENTS_DETAILS_LOADING = 'GET_CUSTOMER_SHIPMENTS_DETAILS_LOADING';
const GET_CUSTOMER_SHIPMENTS_DETAILS = 'GET_CUSTOMER_SHIPMENTS_DETAILS';
const NO_VALID_CUSTOMER_LIST = 'NO_VALID_CUSTOMER_LIST';
const EDIT_CUSTOMER_LOADING = 'EDIT_CUSTOMER_LOADING';
const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
const DELETE_CUSTOMER_LOADING = 'DELETE_CUSTOMER_LOADING';
const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
const CREATE_CUSTOMER_LOADING = 'CREATE_CUSTOMER_LOADING';
const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
const ADD_CUSTOMER_MODEL_OPEN = 'ADD_CUSTOMER_MODEL_OPEN';
const EDIT_CUSTOMER_MODEL_OPEN = 'EDIT_CUSTOMER_MODEL_OPEN';
const CANCEL_EDITING_CUSTOMER = 'CANCEL_EDIIING_CUSTOMER';
const PAYMENT_OPIONS_LOADING = 'PAYMENT_OPIONS_LOADING';
const PAYMENT_OPTIONS = 'PAYMENT_OPTIONS';
const LOAD_POSTED = 'LOAD_POSTED';
const GET_PICKUP_LOACATION_LOADING = 'GET_PICKUP_LOCATION_LOADING';
const GET_PICKUP_LOACATION = 'GET_PICKUP_LOCATION';
const GET_PINS_DETAILS_LOADING = 'GET_PINS_DETAILS_LOADING';
const GET_PINS_DETAILS = 'GET_PINS_LOADING';
const documentsToShareInitialState = {
  emails: [],
  subject: '',
  message: '',
  image_url: [],
  shipment_id:null
};

//initial state
const initialState = {
  conversationUrl: '',
  favourites: null,
  favouritesLoading: false,
  favouritesNextLoading: false,
  dashboardLoading: false,
  dashbaordData: null,
  job: null,
  cats: [],
  subCats: [],
  subcats_all: [],
  deleteJobIdLoading: '',
  deleteJob: null,
  paymentOptionsLoading:false,
  paymentOptions:[],
  loadSizes: [
    {
      name: 'small',
      label: 'Small'
    },
    {
      name: 'medium',
      label: 'Medium'
    },
    {
      name: 'full',
      label: 'Full'
    }
  ],
  categories_loading: false,
  subcategories_loading: false,
  steps: [
    // {
    //   key: 1,
    //   label: 'General Information',
    //   icon: <CreateListingIcon />,
    //   iconComplete: <CreateListingCompleteIcon />,
    //   iconComplete2: <CreateListingCompleteIcon2 />
    // },
    // {
    //   key: 2,
    //   label: 'Item Details',
    //   icon: <MagnifyIcon />,
    //   iconComplete: <MagnifyCompleteIcon />,
    //   iconComplete2: <MagnifyCompleteIcon2 />
    // },
    // {
    //   key: 3,
    //   label: 'Route',
    //   icon: <RouteIcon />,
    //   iconComplete: <RouteCompleteIcon />,
    //   iconComplete2: <RouteCompleteIcon2 />
    // },
    // {
    //   key: 4,
    //   label: 'Price',
    //   icon: <PriceIcon />,
    //   iconComplete: <PriceCompleteIcon />,
    //   iconComplete2: <PriceCompleteIcon2 />
    // }
  ],
  activeStep: 1,
  availableJobs: [],
  availableJobsTotalCount: 0,
  availableJobsNext: null,
  availableJobsPrev: null,
  error: '',
  message: '',
  loading: false,
  quote_loading: false,
  booking_loading: false,
  quote_success: false,
  quote_data: '',
  booking_data: '',
  booking_info: null,
  bid_step: SHIPMENT_DETAIL,
  create_chat_url_loading: '',
  loadsNextListLoading: false,
  customerNextListLoading: false,
  loadPosted: false,
  place_bid_form_data: {
    shipmentId: '',
    quoteAmount: 0,
    pickupDate: null,
    dropDate: null,
    terms: false
  },
  carrier_tabs: [
    {
      label: convertToLabel(LOAD_STEP.ALL),
      slug: LOAD_STEP.ALL,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.NEW),
      slug: LOAD_STEP.NEW,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.ACTIVE),
      slug: LOAD_STEP.ACTIVE,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.DELIVERED),
      slug: LOAD_STEP.DELIVERED,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.ARCHIVED),
      slug: LOAD_STEP.ARCHIVED,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.PENDING),
      slug: LOAD_STEP.PENDING,
      count: 0
    }
    // {
    //   label: 'My jobs',
    //   slug: LOAD_STEP.MY_JOBS
    // }
  ],
  shipper_tabs: [
    {
      label: convertToLabel(LOAD_STEP.ALL),
      slug: LOAD_STEP.ALL,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.OFFER),
      slug: LOAD_STEP.OFFER,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.UNASSIGNED),
      slug: LOAD_STEP.ORDERED,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.NEW),
      slug: LOAD_STEP.NEW,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.ACTIVE),
      slug: LOAD_STEP.ACTIVE,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.DELIVERED),
      slug: LOAD_STEP.DELIVERED,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.ARCHIVED),
      slug: LOAD_STEP.ARCHIVED,
      count: 0
    },
    {
      label: convertToLabel(LOAD_STEP.PENDING),
      slug: LOAD_STEP.PENDING,
      count: 0
    }
  ],
  active_type: LOAD_STEP.ALL,
  loads_list_loading: false,
  tabs_loading: false,
  admin_load_list_loading: false,
  loads_ordering: '-created_at',
  invoice_ordering: '-created_at',
  loads: null,
  filteredLoads: null,
  itemCounts: null,
  currentTabCount: null,
  loadDetailLoading: false,
  loadDetail: null,
  lengthUnitTypes: [
    {
      value: 'ft',
      label: 'FT'
    }
  ],
  widthUnitTypes: [
    {
      value: 'ft',
      label: 'FT'
    }
  ],
  heightUnitTypes: [
    {
      value: 'ft',
      label: 'FT'
    }
  ],
  weightUnitTypes: [
    {
      value: 'ton',
      label: 'TON'
    }
  ],
  bidPlacedCarrierListLoading: false,
  bidPlacedCarrierList: null,
  bidPlacedCarrierListLoadMore: false,
  assignJobCarrierLoadingId: '',
  assignJobCarrier: null,
  jobNoteLoading: false,
  jobNote: null,
  noteListLoading: false,
  noteList: null,
  deleteJobNoteLoading: false,
  deleteNote: null,
  editNote: null,
  isNoteEdited: false,
  updateNote: null,
  jobFavouriteLoading: '',
  carriersListLoading: false,
  carriersList: [],
  offered_loads_ordering:'-created_at',
  createOfferLoading:false,
  offerCreated:false,
  myOffersListLoading: false,
  myOffersList:null,
  myOffersNextListLoading: false,
  acceptOfferLoading: false,
  offerAccepted: null,
  rejectOfferLoading: false,
  offerRejected: null,
  cancelOfferLoading: false,
  offerCancelled:null,
  activeShipmentId:null,
  documentsToShare: documentsToShareInitialState,
  sendDocumentsMediaResponse: false,
  sendDocumentsMediaLoading: false,
  saved_loads_ordering: '-shipment_id',
  markJobDeliveredLoading: false,
  markJobActiveLoading: false,
  markJobActiveFromDeliveredLoading: false,
  markJobNewFromActiveLoading: false,
  markedActiveFromDeliveredJobId: '',
  markedNewFromActiveJobId: '',
  markedDeliveredJobId: '',
  markedJobActiveJobId: '',
  customersLoading: false,
  customers: null,
  customerDetailsLoading: false,
  customerDetails: null,
  totalCustomersLoading: false,
  totalCustomers: null,
  customerListValid: true,
  customerShipmentsDetails: null,
  customerShipmentsDetailsLoading: false,
  editCustomerLoading: false,
  customerEdited: false,
  deleteCustomerLoading: false,
  customerDeleted: false,
  createCustomerLoading: false,
  customerCreated: false,
  editCustomerModelOpen: false,
  addCustomerModelOpen: false,
  cancelEditCustomer: false,
  invoiceValid:true,
  invoiceListLoading: false,
  invoiceList: null,
  invoiceNextListLoading: false,
  setInvoiceLoading: false,
  setInvoice: null,
  previewInvoiceLoading:false,
  previewInvoice:null,
  markArchiveLoading:false,
  markArchive:null,
  markUnArchiveLoading:false,
  markUnArchive:null,
  invoiceDataLoading: false,
  invoiceData: null,
  invoiceDetailLoading:false,
  invoiceDetail:null,
  invoicePaidLoading:false,
  sendRemainderLoading: false,
  sendRemainder:null,
  invoicePaid:null,
  invoiceCount:null,
  redirectedInvoiceId:null,
  redirectedshipmentid:null,
  locationListLoading: false,
  locationList:null
};

//reducer functions
export const unsetMarkedDeleveredJobId = () => {
  return {
    type: SET_INITIAL_MARKED_DELEVERED_JOB_ID
  };
};

export const setRedirectedInvoiceId = (id) => {
  return {
    type: REDIRECTED_INVOICE,
    data:id
  };
};

export const setRedirectedShipmentId = (id) => {
  return {
    type: REDIRECTED_SHIPMENT,
    data:id
  };
};
export const setClearRedirectedInvoiceId = () => {
  return {
    type: CLEAR_REDIRECTED_FOR_INVOICE,
  };
};

export const unsetMarkedActivejobId = () => {
  return {
    type: SET_INITIAL_MARKED_ACTIVE_JOB_ID
  };
};

export const unsetMarkedActiveFromDeliveredJobId = () => {
  return {
    type: SET_INITIAL_MARKED_ACTIVE_FROM_DELIVERED_JOB_ID
  };
};

export const unsetMarkedNewFromActiveJobId = () => {
  return {
    type: SET_INITIAL_MARKED_NEW_FROM_ACTIVE_JOB_ID
  };
};

export const setJob = data => {
  return {
    type: SET_JOB,
    data
  };
};

export const resetDocumentMedia = () => {
  return {
    type: RESET_DOCUMENT_MEDIA
  };
};

export const modifyImageUrlInDocumentsToShare = image_url => {
  return {
    type: MODIFY_IMAGE_URL,
    data: {
      image_url
    }
  };
};

export const setInfoInDocumentsToShare = data => {
  return {
    type: SET_INFO_DOCS_SHARE,
    data
  };
};

export const setLoadsOrdering = order => {
  return {
    type: SET_LOADS_ORDERING,
    data: order
  };
};

export const setInvoiceOrdering = order => {
  return {
    type: SET_INVOICE_ORDERING,
    data: order
  };
};
export const setSavedLoadsOrdering = order => {
  return {
    type: SET_SAVED_LOADS_ORDERING,
    data: order
  };
};
export const setOfferedLoadsOrdering = order => {
  return {
    type: SET_OFFERED_LOADS_ORDERING,
    data: order
  };
};

export const setActiveType = type => {
  return {
    type: SET_ACTIVE_TYPE,
    data: type
  };
};

export const setActiveStep = step => {
  return {
    type: SET_ACTIVE_STEP,
    data: step
  };
};

export const setArchivedJobCount = () => {
  return {
    type: SET_ARCHIVE_JOB_COUNT
  };
};

export const setUnArchivedJobCount = () => {
  return {
    type: SET_UNARCHIVE_JOB_COUNT
  };
};

export const loadPostedByShipper = (boolean) => {
  return {
    type: LOAD_POSTED,
    data: boolean
  };
};
export const getCategories = () => {
  return async dispatch => {
    dispatch({
      type: CATEGORIES_LOADING
    });
    try {
      const response = await axiosClient.get(`/shipment/${version}/config/`, {
        params: {
          shipment_category: true,
          shipment_all_sub_categories: true
        }
      });

      dispatch({
        type: SET_CATS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR
      });
    }
  };
};

export const setFavourites = (url = '', params) => {
  return async dispatch => {
    dispatch({
      type: url ? FAVAURITES_NEXT_LOADING : FAVAURITES_LOADING
    });
    try {
      const response = await axiosClient.get(
        url || `shipment/${version}/favourite/`,
        { params }
      );

      dispatch({
        type: SET_FAVAURITES,
        data: response.data,
        isInitialData: url ? false : true
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR
      });
    }
  };
};

export const getOffersList = (url = '', params) => {
  return async dispatch => {
    dispatch({
      type: url ? GET_OFFERS_NEXT_LIST_LOADING : GET_OFFERS_LIST_LOADING
    });
    try {
      const response = await axiosClient.get(
        url || `shipment/${version}/offers/`,
        { params }
      );

      dispatch({
        type: GET_OFFERS_LIST,
        data: response.data,
        isInitialData: url ? false : true
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR
      });
    }
  };
};

export const acceptOffer = (offerId) => {
  return async dispatch => {
    dispatch({
      type: ACCEPT_OFFER_LOADING
    });
    try {
      const response = await axiosClient.patch( `shipment/${version}/offer/${offerId}/accept/`);
      dispatch({
        type: ACCEPT_OFFER,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR
      });
    }
  };
};

export const rejectOffer = (offerId) => {
  return async dispatch => {
    dispatch({
      type: REJECT_OFFER_LOADING
    });
    try {
      const response = await axiosClient.patch( `shipment/${version}/offer/${offerId}/reject/`);
      dispatch({
        type: REJECT_OFFER,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR
      });
    }
  };
};

export const cancelOffer = (offerId, shipmentId, cancelType) => {
  return async dispatch => {
    dispatch({
      type: CANCEL_OFFER_LOADING
    });
    try {
      let response;
      if (cancelType === 'post_to_marketPlace' && offerId) {
        response = await axiosClient.patch(
          `shipment/${version}/offer/${offerId}/cancel/`
        );
      } else {
        response = await axiosClient.put(
          `shipment/${version}/${shipmentId}/cancel/?cancel_type=${cancelType}`
        );
      }
      dispatch({
        type: CANCEL_OFFER,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR
      });
    }
  };
};
export const getSubCategories = parentCategory => {
  return async dispatch => {
    dispatch({
      type: SUBCATEGORIES_LOADING
    });
    try {
      const response = await axiosClient.get(`/shipment/${version}/config/`, {
        params: {
          shipment_sub_category: true,
          category_name: parentCategory
        }
      });

      dispatch({
        type: SET_SUBCATS,
        data: response.data.shipment_sub_categories
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR
      });
    }
  };
};

export const getLoadsList = (type, params) => {
  return async dispatch => {
    dispatch({
      type: SET_LOADS_LIST_LOADING
    });
    try {
      let url='';
      if( type==='offer')
         url = `/shipment/${version}/offers/?status=offer_pending`
      else
       url = `/shipment/${version}/${type}/`;

      // If job is private to the carrier only. (added by carrier)
      if (type === LOAD_STEP.MY_JOBS) url = `/shipment/${version}/`;

      // Trim  leading and trailing spaces from params
      const trimmedParams = {};
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          trimmedParams[key] = params[key].trim();
        }
      }

      const response = await axiosClient.get(url, {
        params: trimmedParams
      });

      dispatch({
        type: SET_LOADS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const getLoadsPickupLocations = (type, params) => {
  return async dispatch => {
    dispatch({
      type: GET_PICKUP_LOACATION_LOADING
    });
    try {
      let url = `shipment/${version}/shipments/locations/pins/?tab=${type}`;
      const trimmedParams = {};
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          trimmedParams[key] = params[key].trim();
        }
      }
      const response = await axiosClient.get(url, {
        params: trimmedParams
      });
      dispatch({
        type: GET_PICKUP_LOACATION,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const getPickupLocationsPinsDetails = (shipment_id) => {
  return async dispatch => {
    dispatch({
      type: GET_PINS_DETAILS_LOADING
    });
    try {
      let url = `shipment/${version}/locations/details/?ids=${shipment_id}`;
      const response = await axiosClient.get(url)
      dispatch({
        type: GET_PINS_DETAILS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const getShipmentCounts = (userType) => {
  return async (dispatch) => {
    dispatch({
      type: SET_UPDATE_TABS_LOADING
    });
    
    try {
      const response = await axiosClient.get(`/shipment/${version}/shipments/count/`);
      const { all_shipments_count,offer_shipments_count, unassigned_shipments_count, new_shipments_count, active_shipments_count, delivered_shipments_count, archived_shipments_count, pending_shipments_count } = response.data;

      let updatedTabs = [];

      updatedTabs.push({
        label: convertToLabel(LOAD_STEP.ALL),
        slug: LOAD_STEP.ALL,
        count: all_shipments_count,
      });
      if (userType === 'shipper') {

      updatedTabs.push({
        label: convertToLabel(LOAD_STEP.OFFER),
        slug: LOAD_STEP.OFFER,
         count: offer_shipments_count,
      });
      // If userType is shipper, include the "UNASSIGNED" tab
        updatedTabs.push({
          label: convertToLabel(LOAD_STEP.UNASSIGNED),
          slug: LOAD_STEP.ORDERED,
          count: unassigned_shipments_count,
        });
      }

      updatedTabs = updatedTabs.concat([
        {
          label: convertToLabel(LOAD_STEP.NEW),
          slug: LOAD_STEP.NEW,
          count: new_shipments_count,
        },
        {
          label: convertToLabel(LOAD_STEP.ACTIVE),
          slug: LOAD_STEP.ACTIVE,
          count: active_shipments_count,
        },
        {
          label: convertToLabel(LOAD_STEP.DELIVERED),
          slug: LOAD_STEP.DELIVERED,
          count: delivered_shipments_count,
        },
        {
          label: convertToLabel(LOAD_STEP.ARCHIVED),
          slug: LOAD_STEP.ARCHIVED,
          count: archived_shipments_count,
        },
        {
          label: convertToLabel(LOAD_STEP.PENDING),
          slug: LOAD_STEP.PENDING,
          count: pending_shipments_count,
        },
      ]);

      dispatch({
        type: UPDATE_TABS,
        carrier_tabs: updatedTabs,
        shipper_tabs: updatedTabs,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail),
      });
    }
  };
};

export const getFilteredLoadsList = (type, params) => {
  return async dispatch => {
    try {
      let url='';
      if( type==='offer')
         url = `/shipment/${version}/offers/?status=offer_pending`
      else
       url = `/shipment/${version}/${type}/`;
      if (type === LOAD_STEP.MY_JOBS) url = `/shipment/${version}/`;

      // Trim leading and trailing spaces from params
      const trimmedParams = {};
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          trimmedParams[key] = params[key].trim();
        }
      }
      const response = await axiosClient.get(url, {
        params: trimmedParams
      });
      
      dispatch({
        type: SET_LOADS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const getAdminLoadsList = (params) => {
  return async dispatch => {
    dispatch({
      type: SET_LOADS_LIST_LOADING,

    });
    try {
      let url = `dashboard/${version}/shipments/`;
      const response = await axiosClient.get(url, {
        params,
      });
      dispatch({
        type: SET_LOADS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const loadmoreLoads = url => {
  return async dispatch => {
    dispatch({
      type: SET_LOADS_LIST_LOADING
    });
    try {
      const response = await axiosClient.get(url);

      dispatch({
        type: SET_LOADS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const loadmoreLoadsList = url => {
  return async dispatch => {
    dispatch({
      type: LOADS_NEXT_LIST_LOADING
    });
    try {
      const response = await axiosClient.get(url);

      dispatch({
        type: SET_LOADS_NEXT_LIST,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const deleteShipment = shipment_id => {
  return async dispatch => {
    dispatch({
      type: DELETE_JOB_LOADING,
      data: shipment_id
    });
    try {
      const response = await axiosClient.delete(
        `/shipment/${version}/${shipment_id}/delete/`
      );

      dispatch(setDeletedJob(response.data));
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const setDeletedJob = (data = null) => {
  return {
    type: DELETE_JOB,
    data
  };
};

export const cancelShipment = shipment_id => {
  return async dispatch => {
    dispatch({
      type: DELETE_JOB_LOADING,
      data: shipment_id
    });
    try {
      const response = await axiosClient.put(
        `/shipment/${version}/${shipment_id}/cancel/`
      );

      dispatch(setDeletedJob(response.data));
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getAvailablejobs = data => {
  return async dispatch => {
    dispatch({
      type: SET_LOADING
    });
    try {
      const response = await axiosClient.get(
        `/shipment/${version}/available/`,
        {
          params: data
        }
      );
      if (response.data.results.length) {
        dispatch({
          type: SET_AVAILABLE_JOBS,
          data: response.data
        });
      } else {
        dispatch({
          type: SET_AVAILABLE_JOBS,
          data: null
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

export const loadMoreAvailableJob = nextPath => {
  return async dispatch => {
    dispatch({
      type: SET_LOADING
    });
    try {
      const response = await axiosClient.get(nextPath);
      if (response.data.results.length) {
        dispatch({
          type: SET_AVAILABLE_JOBS,
          data: response.data,
          loadMore: true
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

export const quote = data => {
  return async dispatch => {
    dispatch({
      type: QUOTE_LOADING
    });
    try {
      const response = await axiosClient.post(
        `/shipment/${version}/available/${data.shipmentId}/quote/`,
        {
          quote_amount: data.quoteAmount,
          transporter_pickup_date: data.pickupDate,
          transporter_delivery_date: data.dropDate
        }
      );
      if (response.data) {
        dispatch({
          type: QUOTE,
          data: response.data
        });
      }
      dispatch(setFavourites());
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data:
          errorMessageSingleLine(error.response?.data) === ERRORS.NO_PERMISSION
            ? MESSAGES.UNDER_VERIFICATION
            : errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const booking = shipment_id => {
  return async dispatch => {
    dispatch({
      type: BOOKING_LOADING
    });
    try {
      const response = await axiosClient.put(
        `/shipment/${version}/${shipment_id}/instant_book/`
      );

      if (response.data) {
        dispatch({
          type: BOOKING,
          data: response.data
        });
      }
      dispatch(setFavourites());
          //mixpanel event for instant book
          trackEvent('Instant booking done');
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data:
          errorMessageSingleLine(error.response?.data) === ERRORS.NO_PERMISSION
            ? MESSAGES.UNDER_VERIFICATION
            : errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const sendDocumentsMedia = data => {
  return async dispatch => {
    dispatch({
      type: SEND_DOCUMENT_MEDIA_LOADING
    });
    try {
      const response = await Axios.post(
        `${baseUrl}shipment/${version}/media/sent/mail/`,
        data
      );

      dispatch({
        type: SEND_DOCUMENT_MEDIA,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const setLoadDetail = data => {
  return {
    type: SET_LOAD_DETAIL,
    data
  };
};

export const getLoadDetail = (shipment_id, status) => {

  return async dispatch => {
    dispatch({
      type: LOAD_DETAIL_LOADING
    });

    dispatch(resetDocumentMedia());

    if (!shipment_id) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('Job not found!')
      });

      return false;
    }

    try {
      let endpoint = LOAD_STATUS.ASSIGNED;

      if (status === LOAD_STATUS.PENDING || status === LOAD_STATUS.REJECTED)
        endpoint = LOAD_STATUS.QUOTED;

      const response = await axiosClient.get(
        `/shipment/${version}/${endpoint}/${shipment_id}/`
      );

      const loadInfo = response.data;

      if (loadInfo) {
        dispatch(setLoadDetail(loadInfo));
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getCustomers = () => {
  return async dispatch => {
    dispatch({
      type: GET_CUSTOMER_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/shipment/${version}/contacts/`
      );
      dispatch({
        type: GET_CUSTOMER,
        data: response.data
      });
    } catch (error) {
      if (error.response.data.detail == "NOT_PREMIUM_USER") {
        dispatch({
          type: NO_VALID_CUSTOMER_LIST
        });
      } else {
        dispatch({
          type: SET_ERROR,
          data: errorMessageSingleLine('')
        });
      }
    };
  }
}

export const getInvoice = (param) => {
  return async (dispatch) => {
    dispatch({
      type: GET_INVOICE_LOADING,
    });

    try {
      const response = await axiosClient.get(`/shipment/${version}/invoice/`, {
        params: param,
      });

      dispatch({
        type: GET_INVOICE,
        data: response.data,
      });
    } catch (error) {
      if (error.response.data.detail == "NOT_PREMIUM_USER") {
        dispatch({
          type: NO_VALID_INVOICE_LIST
        });
      } else {
        dispatch({
          type: SET_ERROR,
          data: errorMessageSingleLine('')
        });
      };
    }
  };
};

export const getFilteredInvoice = (param) => {
  return async (dispatch) => {
   
    try {
      const response = await axiosClient.get(`/shipment/${version}/invoice/`, {
        params: param,
      });

      dispatch({
        type: GET_INVOICE,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};


export const getInvoiceDetail = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_INVOICE_DETAIL_LOADING,
    });

    try {
      const response = await axiosClient.get(`/shipment/${version}/invoice/${id}/`);

      dispatch({
        type: GET_INVOICE_DETAIL,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const getPaymentOptions = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: PAYMENT_OPIONS_LOADING,
    });
    let url = `${baseUrl}shipment/${version}/config/?payment_type=true`;

    // Check if the flag is true, then append the additional parameter
    if (obj) {
      url += "&invoice_payment_term=true";
    }
    try {
      const response = await axiosClient.get(url);

      dispatch({
        type: PAYMENT_OPTIONS,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(''),
      });
    }
  };
};
export const getInvoiceData = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_INVOICE_DATA_LOADING,
    });

    try {
      const response = await axiosClient.get(`/shipment/${version}/invoice/statistics/`);

      dispatch({
        type: GET_INVOICE_DATA,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getMoreInvoiceList = url => {
  return async dispatch => {
    dispatch({
      type: GET_INVOICE_NEXT_LIST_LOADING
    });
    try {
      const response = await axiosClient.get(url);

      dispatch({
        type: GET_INVOICE_NEXT_LIST,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};


export const markInvoice = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: SET_INVOICE_LOADING,
    });

    try {
      const response = await axiosClient.post(`/shipment/${version}/invoice/send/`, obj);

      dispatch({
        type: SET_INVOICE,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const sendRemainderInvoice = (obj,invoiceId) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_REMAINDER_LOADING,
    });

    try {
      const response = await axiosClient.patch(`/shipment/${version}/invoice/${invoiceId}/send_reminder/`, obj);

      dispatch({
        type: SEND_REMAINDER,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const previewInvoiceEmail = (obj) => {
  return async (dispatch) => {

    dispatch({
      type: PREVIEW_INVOICE_LOADING,
    });

    try {
      const response = await axiosClient.post(`/shipment/${version}/invoice/preview/`, obj);

      dispatch({
        type: PREVIEW_INVOICE,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const markInvoiceAsPaid = (selectedItemsObject, shipmentInvoiceId) => {
  return async (dispatch) => {
    dispatch({
      type: MARK_INVOICE_PAID_LOADING,
    });

    try {
      const response = await axiosClient.patch(`/shipment/${version}/invoice/${shipmentInvoiceId}/mark_paid/`, selectedItemsObject);

      dispatch({
        type: MARK_INVOICE_PAID,
        data: response.data,
      });

    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};


export const markArchiveInvoicing = (invoiceId) => {
  return async (dispatch) => {
    dispatch({
      type: MARK_INVOICE_ARCHIVE_LOADING,
    });

    try {
      const response = await axiosClient.patch(`/shipment/${version}/invoice/${invoiceId}/archive/`);

      dispatch({
        type: MARK_INVOICE_ARCHIVE,
        data: response.data,
      });

    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const markUnArchiveInvoicing = (invoiceId) => {
  return async (dispatch) => {
    dispatch({
      type: MARK_INVOICE_UNARCHIVE_LOADING,
    });

    try {
      const response = await axiosClient.patch(`/shipment/${version}/invoice/${invoiceId}/unarchive/`);

      dispatch({
        type: MARK_INVOICE_UNARCHIVE,
        data: response.data,
      });

    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const filterCustomers = (param) => {
  return async dispatch => {
    try {
      const response = await axiosClient.get(
        `/shipment/${version}/contacts/`, {
        params: {
          search: param
        }
      }
      );
      dispatch({
        type: GET_CUSTOMER,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  }
}

export const resetPreviewInvoice = () => {
  return(
    {
      type: RESET_PREVIEW_INVOICE
    }
  )
}

export const getFilteredCustomerShipments = (id, tab, search) => {
  return async dispatch => {
    dispatch({
      type: GET_CUSTOMER_SHIPMENTS_DETAILS_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/shipment/${version}/contacts/${id}/shipments/`,
        {
          params: {
            status: tab,
            search: search
          }
        }
      );
      dispatch({
        type: GET_CUSTOMER_SHIPMENTS_DETAILS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
}

export const getMoreCustomers = url => {
  return async dispatch => {
    dispatch({
      type: GET_CUSTOMER_LOADING
    });
    try {
      const response = await axiosClient.get(url);
      dispatch({
        type: GET_CUSTOMER,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};
export const getMoreCustomersList = url => {
  return async dispatch => {
    dispatch({
      type: GET_CUSTOMER_NEXT_LIST_LOADING
    });
    try {
      const response = await axiosClient.get(url);

      dispatch({
        type: GET_CUSTOMER_NEXT_LIST,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const deleteCustomer = (id) => {
  let url = `/shipment/${version}/contacts/${id}`

  return async dispatch => {
    dispatch({
      type: DELETE_CUSTOMER_LOADING
    });

    try {
      const response = await axiosClient.delete(url);

      dispatch({
        type: DELETE_CUSTOMER,
        data: id
      });
      //  dispatch(getCustomers())
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
}
export const editCustomer = (id, obj) => {
  let url = `/shipment/${version}/contacts/${id}`

  return async dispatch => {
    dispatch({
      type: EDIT_CUSTOMER_LOADING
    });

    try {
      const response = await axiosClient.patch(url, obj);

      dispatch({
        type: EDIT_CUSTOMER,
        data: response.data
      });
      dispatch(getCustomers())
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
}

export const createCustomer = (obj) => {
  let url = `/shipment/${version}/contacts/`

  return async dispatch => {
    dispatch({
      type: CREATE_CUSTOMER_LOADING
    });

    try {
      const response = await axiosClient.post(url, obj);

      dispatch({
        type: CREATE_CUSTOMER,
        data: response.data
      });
      dispatch(getCustomers())
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
}
export const setEditCustomerModelOpen = (boolean) => {
  return async dispatch => {
    dispatch({
      type: EDIT_CUSTOMER_MODEL_OPEN,
      data: boolean
    });
  }
}

export const setAddCustomerModelOpen = (boolean) => {
  return async dispatch => {
    dispatch({
      type: ADD_CUSTOMER_MODEL_OPEN,
      data: boolean
    });
  };
}

export const cancelEditingCustomers = () => dispatch => {
  dispatch({ type: CANCEL_EDITING_CUSTOMER });
}
export const getCustomersDetails = (id) => {
  return async dispatch => {
    dispatch({
      type: GET_CUSTOMER_DETAILS_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/shipment/${version}/contacts/${id}/`
      );
      dispatch({
        type: GET_CUSTOMER_DETAILS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
}

export const getCustomersShipmentDetails = (id, tab) => {
  return async dispatch => {
    dispatch({
      type: GET_CUSTOMER_SHIPMENTS_DETAILS_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/shipment/${version}/contacts/${id}/shipments/`,
        {
          params: {
            status: tab
          }
        }
      );
      dispatch({
        type: GET_CUSTOMER_SHIPMENTS_DETAILS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
}
export const getLoadDetailForAdmin = (user_id, shipment_id, status) => {
  return async dispatch => {
    dispatch({
      type: LOAD_DETAIL_LOADING
    });

    dispatch(resetDocumentMedia());

    if (!shipment_id) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('Job not found!')
      });
      return false;
    }

    try {
      let endpoint = LOAD_STATUS.ASSIGNED;
      if (status === LOAD_STATUS.PENDING || status === LOAD_STATUS.REJECTED)
        endpoint = LOAD_STATUS.QUOTED;
      const response = await axiosClient.get(
        `/shipment/${version}/${endpoint}/${shipment_id}/`,
        {
          params:
          {
            user_id: user_id
          }
        }
      );
      const loadInfo = response.data;
      if (loadInfo) {
        dispatch(setLoadDetail(loadInfo));
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const getLoadDetailShipper = shipment_id => {
  return async dispatch => {
    dispatch({
      type: LOAD_DETAIL_LOADING
    });

    dispatch(resetDocumentMedia());

    try {
      const response = await axiosClient.get(
        `/shipment/${version}/${shipment_id}/`
      );

      const loadInfo = response.data;

      if (loadInfo) {
        dispatch(setLoadDetail(loadInfo));
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const getLoadDetailShipperForAdmin = (user_id, shipment_id) => {
  return async dispatch => {
    dispatch({
      type: LOAD_DETAIL_LOADING
    });

    dispatch(resetDocumentMedia());

    try {
      const response = await axiosClient.get(
        `/shipment/${version}/${shipment_id}/`,
        {
          params: {
            user_id: user_id,

          }
        }
      );

      const loadInfo = response.data;

      if (loadInfo) {
        dispatch(setLoadDetail(loadInfo));
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getCustomerCount = state => state.customers.totalCustomers;
// export const getCustomerCount = () =>{
// // if(state => state.customersLoading == true ) return null ;
// return (
//   state => state.customers.totalCustomers
// )
// }
export const getLoadDetailCarrier = shipment_id => {
  return async dispatch => {
    dispatch({
      type: LOAD_DETAIL_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/shipment/${version}/available/${shipment_id}/`
      );

      const loadInfo = response.data;

      if (loadInfo) {
        dispatch(setLoadDetail(loadInfo));
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getBidPlacedList = shipment_id => {
  return async dispatch => {
    dispatch({
      type: BID_PLACED_CARRIER_LIST_LOADING
    });
    try {
      const response = await axiosClient.get(
        `/shipment/${version}/${shipment_id}/quotes/`,
        {
          params: {
            ordering: '-updated_at',
            limit: 5
          }
        }
      );
      dispatch({
        type: BID_PLACED_CARRIER_LIST,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: `Bidding info: ${errorMessageSingleLine(error.response?.data)}`
      });
    }
  };
};

export const getCarriersList = str => {
  return async dispatch => {
    let search_text = str.trim();

    if (!search_text) {
      dispatch({
        type: CARRIERS_LIST,
        data: []
      });
      return;
    }

    dispatch({
      type: CARRIERS_LIST_LOADING
    });
    try {
      const response = await axiosClient.get(`/carrier/${version}/list/`, {
        params: {
          company_name: search_text
        }
      });
      dispatch({
        type: CARRIERS_LIST,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const createOffer = (shipment_id,carrier_id) => {
  return async dispatch => {
    dispatch({
      type: CREATE_OFFER_LOADING,
    });
    try {
      const response = await axiosClient.post(
        `/shipment/${version}/${shipment_id}/offer/`,
        {
          carrier: carrier_id
        }
      );

      dispatch({
        type: CREATE_OFFER,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data.message)
      });
    }
  };
};

export const assignJob = (carrier_id, shipment_id) => {
  return async dispatch => {
    dispatch({
      type: ASSIGN_JOB_CARRIER_LOADING,
      data: carrier_id
    });
    try {
      const response = await axiosClient.put(
        `/shipment/${version}/${shipment_id}/assign/`,
        {
          transporter_id: carrier_id
        }
      );
      if (response.data.shipment_id && response.data.transporter_id)
        dispatch(setAssignedCarrier(response.data));
      else
        dispatch({
          type: SET_ERROR,
          data: SOMETHING_WENT_WRONG
        });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const markJobDelivered = (shipment_id, delivery_date) => {
  return async dispatch => {
    dispatch({
      type: SET_JOB_DELIVERED_LOADING
    });
    try {
      const response = await axiosClient.patch(
        `/shipment/${version}/${shipment_id}/private/status/update/`,
        {
          status: LOAD_STEP.DELIVERED,
          date: delivery_date
        }
      );
      dispatch({
        type: SET_JOB_DELIVERED,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const markJobActive = (shipment_id, pickup_date) => {
  return async dispatch => {
    dispatch({
      type: SET_JOB_ACTIVE_LOADING
    });
    try {
      const response = await axiosClient.patch(
        `/shipment/${version}/${shipment_id}/private/status/update/`,
        {
          status: LOAD_STATUS.PICKED_UP,
          date: pickup_date
        }
      );
      dispatch({
        type: SET_JOB_ACTIVE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const markJobActiveFromDelivered = (shipment_id) => {
  return async dispatch => {
    dispatch({
      type: SET_JOB_ACTIVE_FROM_DELIVERED_LOADING
    });
    try {
      const response = await axiosClient.post(
        `/shipment/${version}/${shipment_id}/mark/picked_up/`
      );
      dispatch({
        type: SET_JOB_ACTIVE_FROM_DELIVERED,
        data: response.data
      });

    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const markJobNewFromActive = (shipment_id) => {
  return async dispatch => {
    dispatch({
      type: SET_JOB_NEW_FROM_ACTIVE_LOADING
    });
    try {
      const response = await axiosClient.post(
        `/shipment/${version}/${shipment_id}/mark/new/`
      );
      dispatch({
        type: SET_JOB_NEW_FROM_ACTIVE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const setAssignedCarrier = data => {
  return {
    type: ASSIGN_JOB_CARRIER,
    data
  };
};

export const getBidPlacedListNext = next_url => {
  return async dispatch => {
    dispatch({
      type: BID_PLACED_CARRIER_LIST_LOAD_MORE
    });
    try {
      const response = await axiosClient.get(next_url);
      dispatch({
        type: BID_PLACED_CARRIER_LIST,
        data: response.data,
        loadMore: true
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: `Bidding info: ${errorMessageSingleLine(error.response?.data)}`
      });
    }
  };
};

export const create_chat_url = shipper_id => {
  return async dispatch => {
    dispatch({
      type: CREATE_CHAT_URL_LOADING,
      data: shipper_id
    });
    try {
      const response = await axiosClient.post(
        `/chat/create_conversation/${version}/`,
        {
          user_ids: [getUserIdService(), shipper_id]
        }
      );

      dispatch({
        type: SET_CONVERSATION_URL,
        data: response.data.conversation_sid
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data.message)
      });
    }
  };
};

export const unsetConversationUrl = () => {
  return async dispatch => {
    dispatch({
      type: SET_CONVERSATION_URL,
      data: ''
    });
  };
};

export const setBidStep = step => {
  return async dispatch => {
    dispatch({
      type: SET_BID_STEP,
      data: step
    });
  };
};

export const setPlaceBidFormData = data => {
  return dispatch => {
    dispatch({
      type: SET_PLACE_BID_FORM_DATA,
      data
    });
  };
};

export const setQuoteSuccess = bool => {
  return async dispatch => {
    dispatch({
      type: QUOTE_SUCCESS,
      data: bool
    });
  };
};

export const handleScroll = () => {
  return dispatch => {
    const scrollYPosition = window.pageYOffset;
    let windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight -
      80;
    let stepsLength = 4;
    let windowHeightQuarter = windowHeight / stepsLength;
    if (scrollYPosition >= windowHeightQuarter * stepsLength) {
      dispatch(setActiveStep(4));
    } else if (scrollYPosition >= windowHeightQuarter * (stepsLength - 1)) {
      dispatch(setActiveStep(3));
    } else if (scrollYPosition >= windowHeightQuarter * (stepsLength - 2)) {
      dispatch(setActiveStep(2));
    } else {
      dispatch(setActiveStep(1));
    }
  };
};

export const addJobNote = data => {
  return async dispatch => {
    dispatch({
      type: JOB_NOTE_LOADING
    });

    try {
      const response = await axiosClient.post(
        `/shipment/${version}/shipment/notes/`,
        data
      );

      dispatch({
        type: JOB_NOTE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const shipmentNotesList = (shipment_id) => {
  return async dispatch => {
    dispatch({
      type: NOTES_LIST_LOADING
    });
    try {
      let url = `/shipment/${version}/${shipment_id}/notes?ordering=-updated_at`;
      const response = await axiosClient.get(url);
      dispatch({
        type: SET_NOTES,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const deleteShipmentNote = noteId => {
  return async dispatch => {
    dispatch({
      type: DELETE_JOB_NOTE_LOADING
    });
    try {
      const response = await axiosClient.delete(`/shipment/${version}/note/${noteId}/`);
      dispatch({
        type: DELETE_NOTE,
        data: noteId
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const editShipmentNote = (note) => {
  return async dispatch => {
    dispatch({
      type: EDIT_NOTE,
      data: note
    });
  };
};

export const cancelEditNote = (note) => {
  return async dispatch => {
    dispatch({
      type: CANCEL_EDIT_NOTE,
      data: note
    });
  };
};
export const updateShipmentNote = (message, noteId, sender) => {
  return async dispatch => {
    dispatch({
      type: JOB_NOTE_LOADING
    });
    try {
      const response = await axiosClient.patch(`/shipment/${version}/note/${noteId}/`, { message, sender });
      dispatch({
        type: UPDATE_SHIPMENT_NOTE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};
export const adminNote = async (id, text) => {
  try {
    const response = await axiosClient.post(
      `/dashboard/${version}/create/note/`,
      {
        user_id: id,
        note: text
      }
    );
  } catch (error) {
  };
}

export const setJobFavourite = shipment_id => {
  return async dispatch => {
    dispatch({
      type: JOB_FAVOURITE_LOADING,
      data: shipment_id
    });

    try {
      const response = await axiosClient.post(
        `/shipment/${version}/favourite/`,
        { shipment: shipment_id }
      );

      dispatch({
        type: JOB_FAVOURITE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const setFilteredLoads = data => {
  return dispatch => {
    dispatch({
      type: SET_FILTERED_LOADS,
      data
    });
  };
};

export const resetFileredLoads = () => {
  return dispatch => {
    dispatch({
      type: RESET_FILTERED_LOADS
    });
  };
};

export const AdminGraphData = async (date_from, date_to, transaction_group) => {
  return async dispatch => {
    dispatch({
      type: DASHBOARD_LOADING
    });
    try {
      const response = await axiosClient.get(
        `dashboard/${version}/admin/data/`,
        {
          params: {
            date_from,
            date_to,
            transaction_group
          }
        }
      );
      {
        dispatch({
          type: DASHBOARD_DATA,
          data: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const dashboard = data => {
  return async dispatch => {
    dispatch({
      type: DASHBOARD_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/dashboard/${version}/data/?date_from=${data.date_from}&date_to=${data.date_to}`
      );
      dispatch({
        type: DASHBOARD_DATA,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const dashboardAdmin = data => {
  return async dispatch => {
    dispatch({
      type: DASHBOARD_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/dashboard/${version}/admin/data/?date_from=${data.date_from}&date_to=${data.date_to}&transaction_group=${data.transaction_group}`
      );

      dispatch({
        type: DASHBOARD_DATA,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const adminDashboardGraph = data => {
  return async dispatch => {
    dispatch({
      type: DASHBOARD_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/dashboard/${version}/admin/graph/?transaction_group=${data.transaction_group}`
      );
      dispatch({
        type: DASHBOARD_GRAPH_DATA,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONVERSATION_URL:
      return {
        ...state,
        create_chat_url_loading: '',
        conversationUrl: action.data
      };
    case SET_INITIAL_MARKED_ACTIVE_JOB_ID:
      return {
        ...state,
        markedJobActiveJobId: initialState.markedJobActiveJobId
      };
    case SET_INITIAL_MARKED_DELEVERED_JOB_ID:
      return {
        ...state,
        markedDeliveredJobId: initialState.markedDeliveredJobId
      };

    case SET_INITIAL_MARKED_ACTIVE_FROM_DELIVERED_JOB_ID:
      return {
        ...state,
        markedActiveFromDeliveredJobId: initialState.markedActiveFromDeliveredJobId
      };
    case SET_INITIAL_MARKED_NEW_FROM_ACTIVE_JOB_ID:
      return {
        ...state,
        markedNewFromActiveJobId: initialState.markedNewFromActiveJobId
      };
    case SET_JOB:
      return {
        ...state,
        loading: false,
        error: '',
        job: action.data
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.data
      };
    case SET_LOADS_ORDERING:
      return {
        ...state,
        loads_ordering: action.data
      };
    case SET_INVOICE_ORDERING:
      return {
        ...state,
        invoice_ordering: action.data
      };
      case LOAD_POSTED:
        return {
          ...state,
          loadPosted: action.data
        };
    case SET_SAVED_LOADS_ORDERING:
      return {
        ...state,
        saved_loads_ordering: action.data
      };
    case LOADS_NEXT_LIST_LOADING:
      return {
        ...state,
        loadsNextListLoading: true
      };
    case SET_ACTIVE_TYPE:
      return {
        ...state,
        active_type: action.data
      };
    case SET_AVAILABLE_JOBS:
      let availableJobs = action.data ? action.data.results : [];
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        availableJobs:
          action.loadMore === true
            ? state.availableJobs.concat(action.data.results)
            : availableJobs,
        availableJobsTotalCount:
          action.data && action.data.results.length ? action.data.count : 0,
        availableJobsNext: action.data ? action.data.next : null,
        availableJobsPrev: action.data ? action.data.previous : null
      };
    case SET_CATS:
      return {
        ...state,
        loading: false,
        categories_loading: false,
        error: '',
        cats: action.data.shipment_categories,
        subcats_all: action.data.shipment_all_sub_categories
      };
    case SET_SUBCATS:
      return {
        ...state,
        subcategories_loading: false,
        error: '',
        subCats: action.data
      };
    case MODIFY_IMAGE_URL:
      let image_urls = [...state.documentsToShare.image_url];

      if (!image_urls.includes(action.data.image_url)) {
        image_urls.push(action.data.image_url);
      } else {
        image_urls.splice(image_urls.indexOf(action.data.image_url), 1);
      }

      return {
        ...state,
        documentsToShare: {
          ...state.documentsToShare,
          image_url: image_urls
        }
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
        quote_loading: false,
        booking_loading: false,
        create_chat_url_loading: '',
        loads_list_loading: false,
        tabs_loading: false,
        categories_loading: false,
        subcategories_loading: false,
        loadDetailLoading: false,
        bidPlacedCarrierListLoading: false,
        bidPlacedCarrierListLoadMore: false,
        assignJobCarrierLoadingId: '',
        deleteJobIdLoading: '',
        jobNoteLoading: false,
        createOfferLoading:false,
        jobFavouriteLoading: '',
        carriersListLoading: false,
        dashboardLoading: false,
        sendDocumentsMediaLoading: false,
        favouritesLoading: false,
        favouritesNextLoading: false,
        loadsNextListLoading: false,
        markJobDeliveredLoading: false,
        markJobActiveLoading: false,
        createCustomerLoading: false,
        customerShipmentsDetailsLoading: false,
        customerDetailsLoading: false,
        editCustomerLoading: false,
        deleteCustomerLoading: false,
        customerNextListLoading: false,
        setInvoiceLoading: false,
        invoiceListLoading:false,
        invoicePaidLoading: false,
        sendRemainderLoading: false,
        previewInvoiceLoading: false,
        markArchiveLoading:false,
        markUnArchiveLoading: false,
        invoiceNextListLoading: false,
        createOfferLoading: false,
        myOffersListLoading: false,
        myOffersNextListLoading: false,
        acceptOfferLoading: false,
        rejectOfferLoading: false,
        cancelOfferLoading: false,
        locationListLoading: false,
        pinsDetailsLoading: false,
        pinsDetails: null
      };
    case SET_JOB_ACTIVE_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        markJobActiveLoading: true
      };
    case SET_JOB_ACTIVE:
      const updatedactiveTabs = state.carrier_tabs.map(tab => {
        if (tab.slug === LOAD_STEP.NEW) {
          return { ...tab, count: tab.count - 1 };
        } else if (tab.slug === LOAD_STEP.ACTIVE) {
          return { ...tab, count: tab.count + 1 };
        } else {
          return tab;
        }
      });

      return {
        ...state,
        carrier_tabs: updatedactiveTabs,
        shipper_tabs:updatedactiveTabs,
        markedDeliveredJobId: action.data.shipment_id,
        markJobDeliveredLoading: false,
        markJobActiveLoading: false
      };
    case FAVAURITES_LOADING:
      return {
        ...state,
        favouritesLoading: true
      };
    
    case SET_JOB_DELIVERED_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        markJobDeliveredLoading: true
      };
    case SET_JOB_DELIVERED:
      const updateddeliveredCarrierTabs = state.carrier_tabs.map(tab => {
        if (tab.slug === LOAD_STEP.ACTIVE) {
          return { ...tab, count: tab.count - 1 };
        } else if (tab.slug === LOAD_STEP.DELIVERED) {
          return { ...tab, count: tab.count + 1 };
        } else {
          return tab;
        }
      });

      return {
        ...state,
        carrier_tabs: updateddeliveredCarrierTabs,
        markedDeliveredJobId: action.data.shipment_id,
        markJobDeliveredLoading: false
      };

    case SET_ARCHIVE_JOB_COUNT:
      const updatedArchiveCarrierTabs = state.carrier_tabs.map(tab => {
        if (tab.slug === LOAD_STEP.DELIVERED) {
          return { ...tab, count: tab.count - 1 };
        } else if (tab.slug === LOAD_STEP.ARCHIVED) {
          return { ...tab, count: tab.count + 1 };
        } else {
          return tab;
        }
      });

      return {
        ...state,
        carrier_tabs: updatedArchiveCarrierTabs,

      };

    case SET_UNARCHIVE_JOB_COUNT:
      const updatedunArchiveCarrierTabs = state.carrier_tabs.map(tab => {
        if (tab.slug === LOAD_STEP.ARCHIVED) {
          return { ...tab, count: tab.count - 1 };
        } else if (tab.slug === LOAD_STEP.DELIVERED) {
          return { ...tab, count: tab.count + 1 };
        } else {
          return tab;
        }
      });

      return {
        ...state,
        carrier_tabs: updatedunArchiveCarrierTabs,

      };

    case SET_JOB_ACTIVE_FROM_DELIVERED_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        markJobActiveFromDeliveredLoading: true
      };
    case SET_JOB_ACTIVE_FROM_DELIVERED:
      return {
        ...state,
        markedActiveFromDeliveredJobId: action.data.shipment_id,
        markJobActiveFromDeliveredLoading: false,
      };
    case SET_JOB_NEW_FROM_ACTIVE_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        markJobNewFromActiveLoading: true
      };
    case SET_JOB_NEW_FROM_ACTIVE:
      return {
        ...state,
        markedNewFromActiveJobId: action.data.shipment_id,
        markJobNewFromActiveLoading: false,
      };
    case FAVAURITES_NEXT_LOADING:
      return {
        ...state,
        favouritesNextLoading: true
      };
    case SET_FAVAURITES:
      const resp = action.isInitialData
        ? action.data
        : {
          ...action.data,
          next: action.data.next,
          results: state.favourites.results.concat(action.data.results)
        };
      return {
        ...state,
        favouritesLoading: false,
        favouritesNextLoading: false,
        favourites: resp
      };
      case GET_OFFERS_LIST_LOADING:
        return {
          ...state,
          myOffersListLoading: true
        };
      case GET_OFFERS_NEXT_LIST_LOADING:
        return {
          ...state,
          myOffersNextListLoading: true
        };
      case GET_OFFERS_LIST:
      const offersList = action.isInitialData
        ? action.data
        : {
          ...action.data,
          next: action.data.next,
          offerRejected: null,
          offersAccepted: null,
        };
      return {
        ...state,
        myOffersListLoading: false,
        myOffersNextListLoading: false,
        myOffersList: offersList
      };
      case SET_OFFERED_LOADS_ORDERING:
      return {
        ...state,
        offered_loads_ordering: action.data
      };
    case ACCEPT_OFFER_LOADING:
      return {
        ...state,
        acceptOfferLoading: true
      };
    case ACCEPT_OFFER:
      return {
        ...state,
        acceptOfferLoading: false,
        offerAccepted: action.data,
        activeShipmentId: action.data.shipment_offer.shipment.shipment_id
      };
    case  REJECT_OFFER_LOADING:
      return {
        ...state,
        rejectOfferLoading: true
      };
    case REJECT_OFFER:
      return {
        ...state,
        rejectOfferLoading: false,
        offerRejected: action.data,
        activeShipmentId: action.data.shipment_offer.shipment
      }
    case CANCEL_OFFER_LOADING:
      return {
        ...state,
        cancelOfferLoading: true
      };
    case CANCEL_OFFER:
      return {
        ...state,
        cancelOfferLoading: false,
        offerCancelled: action.data,
      }
    case QUOTE_LOADING:
      return {
        ...state,
        quote_loading: true,
        error: '',
        message: ''
      };
    case DASHBOARD_LOADING:
      return {
        ...state,
        dashboardLoading: true,
        error: '',
        message: ''
      };
    case PAYMENT_OPTIONS:
      return {
        ...state,
        paymentOptionsLoading: false,
        paymentOptions: action.data,
      };
      case PAYMENT_OPIONS_LOADING:
      return {
        ...state,
        paymentOptionsLoading: true
      };
    case DASHBOARD_DATA:
      return {
        ...state,
        dashboardLoading: false,
        dashbaordData: action.data,
        error: ''
      };
    case DASHBOARD_GRAPH_DATA:
      return {
        ...state,
        dashboardLoading: false,
        dashbaordGraphData: action.data,
        error: ''
      };
    case SET_LOADS_LIST_LOADING:
      return {
        ...state,
        loads_list_loading: true,
        loads: null,
        error: '',
        message: ''
      };
    case SET_UPDATE_TABS_LOADING:
      return {
        ...state,
        tabs_loading: true,
        carrier_tabs: [],
        shipper_tabs:[],
        error: '',
        message: ''
      };
    case UPDATE_TABS:
      return {
        ...state,
        tabs_loading: false,
        carrier_tabs: action.carrier_tabs || [],
        shipper_tabs: action.shipper_tabs || [],

        error: ''
      };
    case SET_ADMIN_LOADS_LIST_LOADING:
      return {
        ...state,
        admin_load_list_loading: true,
        admin_loads: null,
        error: '',
        message: ''
      };
    case SET_LOADS:
      return {
        ...state,
        loads_list_loading: false,
        loads: action.data,
        filteredLoads: action.data,
        currentTabCount: action.data.count,
        itemCount: action.data.items_count,
        error: ''
      };
    case SET_ADMIN_LOADS:
      return {
        ...state,
        admin_load_list_loading: false,
        admin_loads: action.data,
        filteredLoads: action.data,
        error: ''
      };

    case SET_LOADS_NEXT_LIST:
      return {
        ...state,
        loadsNextListLoading: false,
        loads: {
          ...action.data,
          next: action.data.next,
          results: state.loads.results.concat(action.data.results)
        }
      };
    case GET_PICKUP_LOACATION_LOADING:
      return {
        ...state,
        locationListLoading: true,
      };
    case GET_PICKUP_LOACATION:
      return {
        ...state,
        locationListLoading: false,
        locationList: action.data,
      };
      case GET_PINS_DETAILS_LOADING:
      return {
        ...state,
        pinsDetailsLoading: true,
      };
    case GET_PINS_DETAILS:
      return {
        ...state,
        pinsDetailsLoading: false,
        pinsDetails: action.data,
      };
    case RESET_FILTERED_LOADS:
      return {
        ...state,
        filteredLoads: null
      };
    case SET_FILTERED_LOADS:
      let filteredLoads =
        state.loads && state.loads.results.length
          ? state.loads.results.filter(load => {
            return (
              // Filter by pickup address.
              (load.pickup_full_address || '')
                .toLowerCase()
                .includes(action.data.pickup_full_address.toLowerCase()) &&
              // Filter by delivery address.
              (load.delivery_full_address || '')
                .toLowerCase()
                .includes(action.data.delivery_full_address.toLowerCase()) &&
              load.driver_name
                .toLowerCase()
                .includes(action.data.driver_name.toLowerCase()) &&
              // Filter by shipment id.
              load.shipment_id
                .toLowerCase()
                .includes(action.data.shipment_id.toLowerCase()) &&
              // Filter by pickup date.
              (action.data.pickup_date
                ? new Date(load.pickup_date).setHours(0, 0, 0) ===
                new Date(action.data.pickup_date).setHours(0, 0, 0)
                : new Date(load.pickup_date) !== 0) &&
              // Filter by delivery date.
              (action.data.delivery_date
                ? new Date(load.delivery_date).setHours(0, 0, 0) ===
                new Date(action.data.delivery_date).setHours(0, 0, 0)
                : new Date(load.delivery_date) !== 0) &&
              //Filter by subcategory.
              load.category
                ?.replace(/_/g, ' ')
                .toLowerCase()
                .includes(action.data.sub_category.toLowerCase()) &&
              // Filter by customer.
              (load.is_private
                ? (load.customer_contact_name || '')
                  .toString()
                  .toLowerCase()
                  .includes(action.data.customer.toLowerCase())
                : (load.shipper_company_name || load.shipper_name || '')
                  .toString()
                  .toLowerCase()
                  .includes(action.data.customer.toLowerCase())) &&
              // Filter by price.
              (load.quote_status === LOAD_STATUS.PENDING &&
                getUserTypeService() === CARRIER
                ? load.transporter_quoted_amount.toString()
                : (load.accepted_amount || load.quote_amount)
                  .toString()
                  .includes(action.data.amount.toString())) &&
              // Filter by status.
              (getUserTypeService() === SHIPPER
                ? shipmentStatus(load.status)
                  .toLowerCase()
                  .includes(action.data.status)
                : // (load.is_private
                //     ? LOAD_STEP.MY_JOBS
                //     :
                shipmentStatus(load.quote_status || load.status)
                  //)
                  .toLowerCase()
                  .includes(action.data.status.toLowerCase()))
            );
          })
          : [];

      return {
        ...state,
        filteredLoads: {
          ...state.loads,
          results: filteredLoads
        }
      };
    case BID_PLACED_CARRIER_LIST:
      let bidCarriersList = action.data;
      if (action.loadMore) {
        bidCarriersList = {
          ...action.data,
          results: state.bidPlacedCarrierList.results.concat(
            action.data.results
          )
        };
      }
      return {
        ...state,
        bidPlacedCarrierListLoading: false,
        bidPlacedCarrierListLoadMore: false,
        bidPlacedCarrierList: bidCarriersList
      };
    case BOOKING_LOADING:
      return {
        ...state,
        booking_loading: true,
        error: '',
        message: ''
      };
    case CREATE_CHAT_URL_LOADING:
      return {
        ...state,
        create_chat_url_loading: action.data,
        error: '',
        message: ''
      };
    case SET_BID_STEP:
      return {
        ...state,
        bid_step: action.data
      };
    case SET_PLACE_BID_FORM_DATA:
      return {
        ...state,
        place_bid_form_data: action.data
      };
    case SET_INFO_DOCS_SHARE:
      return {
        ...state,
        documentsToShare: action.data
      };
    case QUOTE:
      return {
        ...state,
        quote_loading: false,
        availableJobs: state.availableJobs.filter(
          job => job.shipment_id !== action.data.shipment
        ),
        error: '',
        quote_data: action.data.shipment
      };
    case BOOKING:
      return {
        ...state,
        booking_loading: false,
        availableJobs: state.availableJobs.filter(
          job => job.shipment_id !== action.data.shipment_id
        ),
        error: '',
        booking_data: action.data.shipment_id,
        booking_info: action.data
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
        message: ''
      };
    case CATEGORIES_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        categories_loading: true,
        cats: [],
        subCats: []
      };
    case SEND_DOCUMENT_MEDIA_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        sendDocumentsMediaLoading: true,
        sendDocumentsMediaResponse: false
      };
    case SEND_DOCUMENT_MEDIA:
      return {
        ...state,
        error: '',
        message: action.data.message,
        sendDocumentsMediaLoading: false,
        sendDocumentsMediaResponse: true,
        documentsToShare: documentsToShareInitialState
      };
    case RESET_DOCUMENT_MEDIA:
      return {
        ...state,
        error: '',
        message: '',
        sendDocumentsMediaLoading: false,
        sendDocumentsMediaResponse: false,
        documentsToShare: documentsToShareInitialState
      };
    case BID_PLACED_CARRIER_LIST_LOADING:
      return {
        ...state,
        bidPlacedCarrierList: null,
        bidPlacedCarrierListLoading: true,
        error: '',
        message: ''
      };
    case ASSIGN_JOB_CARRIER_LOADING:
      return {
        ...state,
        assignJobCarrier: null,
        assignJobCarrierLoadingId: action.data,
        error: '',
        message: ''
      };
    case GET_CUSTOMER_LOADING:
      return {
        ...state,
        customersLoading: true
      }
      case NO_VALID_INVOICE_LIST:
        return {
          ...state,
          invoiceListLoading: false,
          invoiceValid: false
        }
    case SET_INVOICE:
      return {
        ...state,
        setInvoice: action.data,
        setInvoiceLoading: false
      }
    case SET_INVOICE_LOADING:
      return {
        ...state,
        setInvoiceLoading: true
      }
    case GET_INVOICE:
      return {
        ...state,
        invoiceList: action.data,
        invoiceListLoading: false,
        invoiceCount: action.data.count
      }
      case GET_INVOICE_NEXT_LIST_LOADING:
        return {
          ...state,
          invoiceNextListLoading: true
        };
      case GET_INVOICE_NEXT_LIST:
        return {
          ...state,
          invoiceNextListLoading: false,
          invoiceList: {
            ...action.data,
            next: action.data.next,
            results: state.customers.results.concat(action.data.results)
          }
        };
    case REDIRECTED_INVOICE:
      return {
        ...state,
        redirectedInvoiceId: action.data
      };

    case CLEAR_REDIRECTED_FOR_INVOICE:
      return {
        ...state,
        redirectedInvoiceId: null,
        redirectedshipmentid: null,
      };
    case REDIRECTED_SHIPMENT:
      return {
        ...state,
        redirectedshipmentid: action.data
      };
    case GET_INVOICE_DETAIL_LOADING:
      return {
        ...state,
        invoiceDetailLoading: true
      }
      case GET_INVOICE_DETAIL:
        return {
          ...state,
          invoiceDetail: action.data,
          invoiceDetailLoading: false
        }
      case GET_INVOICE_DATA_LOADING:
        return {
          ...state,
          invoiceDataLoading: true
        }
    case GET_INVOICE_DATA:
      return {
        ...state,
        invoiceData: action.data,
        invoiceDataLoading: false
      }
    case GET_INVOICE_LOADING:
      return {
        ...state,
        invoiceListLoading: true
      }
      case MARK_INVOICE_PAID_LOADING:
      return {
        ...state,
        invoicePaidLoading: true
      }
      case MARK_INVOICE_PAID:
        return {
          ...state,
          invoicePaid: action.data,
          invoicePaidLoading: false
        }
        case MARK_INVOICE_ARCHIVE_LOADING:
      return {
        ...state,
        markArchiveLoading: true
      }
      case MARK_INVOICE_ARCHIVE:
        return {
          ...state,
          markArchive: action.data,
          markArchiveLoading: false
        }
        case MARK_INVOICE_UNARCHIVE_LOADING:
          return {
            ...state,
            markUnArchiveLoading: true
          }
          case MARK_INVOICE_UNARCHIVE:
            return {
              ...state,
              markUnArchive: action.data,
              markUnArchiveLoading: false
            }
        case PREVIEW_INVOICE_LOADING:
          return {
            ...state,
            previewInvoiceLoading: true
          }
          case PREVIEW_INVOICE:
            return {
              ...state,
              previewInvoice: action.data,
              previewInvoiceLoading: false
            }
            case RESET_PREVIEW_INVOICE:
              return{
                ...state,
                previewInvoice:null
              }
        case SEND_REMAINDER_LOADING:
      return {
        ...state,
        sendRemainderLoading: true
      }
      case SEND_REMAINDER:
        return {
          ...state,
          sendRemainder: action.data,
          sendRemainderLoading: false
        }
    case GET_CUSTOMER:
      return {
        ...state,
        customers: action.data,
        totalCustomers: action.data.count,
        customersLoading: false
      }
    case NO_VALID_CUSTOMER_LIST:
      return {
        ...state,
        customersLoading: false,
        customerListValid: false
      }
    case GET_CUSTOMER_NEXT_LIST_LOADING:
      return {
        ...state,
        customerNextListLoading: true
      };
    case GET_CUSTOMER_NEXT_LIST:
      return {
        ...state,
        customerNextListLoading: false,
        customers: {
          ...action.data,
          next: action.data.next,
          results: state.customers.results.concat(action.data.results)
        }
      };
    case GET_CUSTOMER_DETAILS_LOADING:
      return {
        ...state,
        customerDetailsLoading: true
      }
    case GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.data,
        customerDetailsLoading: false
      }
    case GET_CUSTOMER_SHIPMENTS_DETAILS_LOADING:
      return {
        ...state,
        customerShipmentsDetailsLoading: true
      }
    case GET_CUSTOMER_SHIPMENTS_DETAILS:
      return {
        ...state,
        customerShipmentsDetails: action.data,
        customerShipmentsDetailsLoading: false
      }
    case DELETE_CUSTOMER_LOADING:
      return {
        ...state,
        deleteCustomerLoading: true,
        customerDeleted: false
      }
    case DELETE_CUSTOMER:
      return {
        ...state,
        deleteCustomerLoading: false,
        customerDeleted: action.data
      }
    case EDIT_CUSTOMER_LOADING:
      return {
        ...state,
        editCustomerLoading: true,
        customerEdited: false
      }
    case EDIT_CUSTOMER:
      return {
        ...state,
        editCustomerLoading: false,
        customerEdited: true,
        editCustomerModelOpen: false,
        // customerDetails: null
      }
    case EDIT_CUSTOMER_MODEL_OPEN:
      return {
        ...state,
        editCustomerModelOpen: action.data,
      };
    case CANCEL_EDITING_CUSTOMER:
      return {
        ...state,
        customerDetails: null,
        editCustomerModelOpen: false,
        cancelEditCustomer: true
      }
    case ADD_CUSTOMER_MODEL_OPEN:
      return {
        ...state,
        addCustomerModelOpen: action.data,
      };
    case CREATE_CUSTOMER_LOADING:
      return {
        ...state,
        createCustomerLoading: true,
        customerCreated: false,
        addCustomerModelOpen: false,
      }
    case CREATE_CUSTOMER:
      return {
        ...state,
        createCustomerLoading: false,
        customerCreated: true
      }
    case ASSIGN_JOB_CARRIER:
      return {
        ...state,
        error: '',
        message: 'Carrier has been assigned!',
        assignJobCarrierLoadingId: '',
        assignJobCarrier: action.data
      };
    case BID_PLACED_CARRIER_LIST_LOAD_MORE:
      return {
        ...state,
        bidPlacedCarrierListLoadMore: true,
        error: '',
        message: ''
      };
    case LOAD_DETAIL_LOADING:
      return {
        ...state,
        error: '',
        loadDetail: null,
        loadDetailLoading: true,
        message: ''
      };
    case SET_LOAD_DETAIL:
      return {
        ...state,
        loadDetail: action.data,
        jobNote: null,
        offerCreated:false,
        offerAccepted:false,
        offerRejected: null,
        loadDetailLoading: null
      };
    case SUBCATEGORIES_LOADING:
      return {
        ...state,
        subcategories_loading: true,
        subCats: [],
        error: '',
        message: ''
      };
    case JOB_NOTE_LOADING:
      return {
        ...state,
        jobNoteLoading: true,
        jobNote: null,
        updateNote: null,
        editNote: null,
        error: '',
        message: ''
      };
    case JOB_NOTE:
      return {
        ...state,
        jobNoteLoading: false,
        noteList: [action.data, ...state.noteList],
        jobNote: action.data
      };
    case NOTES_LIST_LOADING:
      return {
        ...state,
        noteListLoading: true,
        noteList: null,
        error: '',
        message: ''
      };
    case SET_NOTES:
      return {
        ...state,
        jobNoteLoading: false,
        noteList: action.data,
        error: ''
      };
    case DELETE_JOB_NOTE_LOADING:
      return {
        ...state,
        DeleteJobNoteLoading: true,
        deleteJob: null,
        error: '',
        message: ''
      };
    case DELETE_NOTE:
      return {
        ...state,
        deleteJobNoteLoading: false,
        deleteNote: action.data,
        noteList: state.noteList.filter(note => note.id !== action.data)
      };
    case EDIT_NOTE:
      return {
        ...state,
        editNote: action.data,
        isNoteEdited: true
      };

    case CANCEL_EDIT_NOTE:
      return {
        ...state,
        editNote: null,
        noteList: state.noteList.map(note => {
          if (note.id === action.data.id) {
            return { ...action.data };
          }
          return note;
        }),

      };
    case UPDATE_SHIPMENT_NOTE:
      return {
        ...state,
        jobNoteLoading: false,
        editNote: null,
        updateNote: action.data,
        noteList: [
          { ...action.data },
          ...state.noteList.filter(note => note.id !== action.data.id)
        ],
      };
    case DELETE_JOB:
      return {
        ...state,
        deleteJobIdLoading: '',
        deleteJob: action.data,
        message: 'Job has been archieved!'
      };
    case JOB_FAVOURITE_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        jobFavouriteLoading: action.data
      };
    case CARRIERS_LIST_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        carriersListLoading: true
      };
    case CARRIERS_LIST:
      return {
        ...state,
        carriersListLoading: false,
        carriersList: action.data.results
      };
      case CREATE_OFFER_LOADING:
      return {
        ...state,
        createOfferLoading: true
      };
    case CREATE_OFFER:
      return {
        ...state,
        createOfferLoading: false,
        offerCreated: true
      };
    case JOB_FAVOURITE:
      return {
        ...state,
        jobFavouriteLoading: '',
        // message: action.data.is_fav
        //   ? 'Job added in favourites'
        //   : 'Job removed from favourites',
        message: '',
        availableJobs: state.availableJobs.filter(job => {
          if (job.shipment_id === action.data.shipment) {
            job.is_fav = action.data.is_fav;
          }
          return job;
        })
      };
    default:
      return state;
  }
}

import React  from 'react';
import { isMobile } from 'react-device-detect';
import { IMaskInput } from 'react-imask';

export const ZipCodeMask = React.forwardRef(function ZipCodeMask(props, ref) {
  const { onChange, ...other } = props;
 return (
    <IMaskInput
      {...other}
      mask="00000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

export const UsDotMask = React.forwardRef(function USDOTMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="AAAAAAAA"
      definitions={{
        'A': /[A-Za-z0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );

});

export const getOperatingSystem = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const isWindows = /Win/i.test(platform);
    const isMac = /Mac/i.test(platform);
    const isLinux = /Linux/i.test(platform);
    let os = 'Unknown';
    if (isWindows) {
      os = 'Windows';
    } else if (isMac) {
      os = 'Mac';
    } else if (isLinux) {
      os = 'Linux';
    }
    return os;
  };
  
  export const getBrowser = () => {
    const userAgent = navigator.userAgent;
    const isChrome = /Chrome/i.test(userAgent);
    const isFirefox = /Firefox/i.test(userAgent);
    const isSafari = /Safari/i.test(userAgent);
    const isEdge = /Edg/i.test(userAgent);
    const isIE = /Trident/i.test(userAgent);
    if (isChrome) {
      return 'Chrome';
    } else if (isFirefox) {
      return 'Firefox';
    } else if (isSafari) {
      return 'Safari';
    } else if (isEdge || (/Edge/i.test(userAgent) && !isIE)) {
      return 'Microsoft Edge';
    } else if (isIE) {
      return 'Internet Explorer';
    }
    return 'Unknown';
  };

  export const getDeviceType = () => {
    const width = window.innerWidth;
    const isMobileScreen = window.matchMedia("(max-width: 768px)").matches;

    if (isMobile && isMobileScreen) {
      return 'MOBILE-WEB';
    } 
    else {
      return 'WEB';
    }
  };

  export const getUserOSVersion = () => {
    const userAgent = navigator.userAgent;
    let osVersion = 'Unknown';

    if (userAgent.includes('Windows NT')) {
      osVersion = userAgent.split('Windows NT')[1].split(';')[0].trim();
    } else if (userAgent.includes('Mac OS X')) {
      osVersion = userAgent.split('Mac OS X')[1].split(')')[0].trim();
    } else if (userAgent.includes('Linux')) {
      osVersion = 'Linux';
    } else {
      osVersion = 'Unknown';
    }
    return osVersion;
  };  
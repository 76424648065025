import React, { useEffect, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { connect } from 'react-redux';
import CardLoading from 'components/Skeleton/Payment/CardLoading';
import { PAYMENT_METHOD_TYPES, PAYMENT_STATUS } from 'utils/constants';
import {
  deletePaymentMethod,
  getStripeAccounts,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodPopupVisible,
  setDeletePaymentMethodPopupVisible
} from 'reducers/Payment';
import { getUserTypeService } from 'services/login';
import { CARRIER } from 'utils/users';
import SpinLoader from 'components/loader/SpinLoader';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const AddedCards = ({
  user,
  getStripeAccounts,
  stripeAccounts,
  stripeAccountsLoading,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  deletePaymentMethodLoading,
  setDefaultPaymentMethodPopupVisible,
  defaultPaymentMethodLoading,
  setPaymentMethod,
  setDeletePaymentMethodPopupVisible
}) => {
  const dot = (
    <Box
      style={{
        width: 6,
        height: 6,
        minWidth: 6,
        minHeight: 6,
        background: '#fff',
        borderRadius: 20,
        marginRight: 4
      }}></Box>
  );

  const userType = getUserTypeService();

  const classes = useStyles();

  useEffect(() => {
    if (userType === CARRIER) {
      getStripeAccounts();
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openElem, setOpenElem] = useState('');

  const handleClick = elem => event => {
    setAnchorEl(event.currentTarget);
    setOpenElem(elem.id);
  };

  if (stripeAccountsLoading && user.payment_status === PAYMENT_STATUS.UPDATED)
    return <CardLoading />;

  if (!stripeAccounts) return null;

  let body = stripeAccounts.external_accounts.data.map((account, index) => {
    return (
      <Box className={classes.cards} key={index}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            title={account.brand}
            style={{
              color: '#fff',
              fontWeight: 700
            }}>
            {account.object === PAYMENT_METHOD_TYPES.BANK
              ? account.bank_name
              : account.brand}
          </Typography>
          {account.default_for_currency === true ? (
            <Typography
              style={{ fontSize: 9, color: 'yellow', fontWeight: 600 }}>
              Primary
            </Typography>
          ) : (
            <>
              {anchorEl ? (
                <Menu
                  anchorEl={anchorEl}
                  open={openElem === account.id}
                  onClose={() => setAnchorEl(null)}>
                  <MenuItem
                    disabled={defaultPaymentMethodLoading}
                    onClick={() => {
                      setDefaultPaymentMethodPopupVisible(true);
                      setAnchorEl(null);
                      setPaymentMethod(account);
                    }}>
                    <Typography variant="h6">Set as primary</Typography>
                  </MenuItem>
                  <MenuItem
                    disabled={deletePaymentMethodLoading}
                    onClick={() => {
                      setAnchorEl(null);
                      setDeletePaymentMethodPopupVisible(true);
                      setPaymentMethod(account);
                    }}>
                    <Typography variant="h6">Remove</Typography>
                  </MenuItem>
                </Menu>
              ) : null}

              <Button
                onClick={handleClick(account)}
                disableRipple
                style={{
                  background: 'transparent',
                  padding: 0,
                  minWidth: 0
                }}>
                <MoreHorizIcon style={{ color: '#fff', cursor: 'pointer' }} />
              </Button>
            </>
          )}
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box
            style={{
              display: 'flex',
              marginRight: 12,
              alignItems: 'center'
            }}>
            {dot}
            {dot}
            {dot}
            {dot}
          </Box>

          <Box
            style={{
              display: 'flex',
              marginRight: 12,
              alignItems: 'center'
            }}>
            {dot}
            {dot}
            {dot}
            {dot}
          </Box>

          <Box
            style={{
              display: 'flex',
              marginRight: 12,
              alignItems: 'center'
            }}>
            {dot}
            {dot}
            {dot}
            {dot}
          </Box>

          <Typography style={{ color: '#fff', fontSize: 12, fontWeight: 700 }}>
            {account.last4}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography style={{ fontSize: 10, color: '#a69bf9' }}>
              Name
            </Typography>
            <Typography
              style={{
                fontSize: 10,
                color: '#c5c6c5',
                textTransform: 'capitalize'
              }}>
              {account.object === PAYMENT_METHOD_TYPES.BANK
                ? account.account_holder_name
                : `${user.first_name} ${user.last_name}`}
            </Typography>
          </Box>
          <Box>
            <Typography style={{ fontSize: 10, color: '#a69bf9' }}>
              {account.object === PAYMENT_METHOD_TYPES.BANK
                ? 'Routing no.'
                : 'Expiry Date'}
            </Typography>
            <Typography style={{ fontSize: 10, color: '#c5c6c5' }}>
              {account.object === PAYMENT_METHOD_TYPES.BANK
                ? account.routing_number
                : `${account.exp_month}/${account.exp_year}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  });

  return <>{body}</>;
};

const useStyles = makeStyles(theme => ({
  cards: {
    width: 250,
    minWidth: 250,
    height: 130,
    background: '#6a52ff',
    borderRadius: 11,
    marginRight: 12,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginTop: 8
    }
  }
}));

const mapStateToProps = state => ({
  stripeAccounts: state.Payment.stripeAccounts,
  stripeAccountsLoading: state.Payment.stripeAccountsLoading,
  user: state.User.user_data,
  deletePaymentMethodLoading: state.Payment.deletePaymentMethodLoading,
  defaultPaymentMethodLoading: state.Payment.defaultPaymentMethodLoading
});

const mapDispatchToProps = dispatch => {
  return {
    getStripeAccounts: () => dispatch(getStripeAccounts()),
    setDefaultPaymentMethod: payment_method_id =>
      dispatch(setDefaultPaymentMethod(payment_method_id)),
    deletePaymentMethod: payment_method_id =>
      dispatch(deletePaymentMethod(payment_method_id)),
    setDefaultPaymentMethodPopupVisible: bool =>
      dispatch(setDefaultPaymentMethodPopupVisible(bool)),
    setDeletePaymentMethodPopupVisible: bool =>
      dispatch(setDeletePaymentMethodPopupVisible(bool))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddedCards);

import axiosClient from 'utils/axiosClient';
import { APP_TOKEN } from 'utils/constants';
import {
    breakSingleLineMessage,
    errorMessageSingleLine
} from 'utils/errorUtils';

const version = process.env.REACT_APP_API_VER;
const baseUrl = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem(APP_TOKEN);
//declarations
const SETUP_PAYOUT_ACCOUNT_LOADING = 'SETUP_PAYOUT_ACCOUNT_LOADING';
const SETUP_PAYOUT_ACCOUNT = 'SETUP_PAYOUT_ACCOUNT';
const SUBMIT_DEAILS_LOADING = 'SUBMIT_DETAILS_LOADING';
const SUBMIT_DETAILS = 'SUBMIT_DETAILS';
const STRIPE_DASHBOARD_REDIRECT_LOADING = 'STRIPE_DASHBOARD_REDIRECT_LOADING';
const STRIPE_DASHBOARD_REDIRECT = 'STRIPE_DASHBOARD_REDIRECT';
const STRIPE_SESSION_ID_LOADING = 'STRIPE_SESSION_ID_LOADING';
const STRIPE_SESSION_ID = 'STRIPE_SESSION_ID';
const SHIPPER_PAYMENT_URL_LOADING = 'SHIPPER_PAYEMENT_URL_LOADING'
const SHIPPER_PAYMENT_URL = 'SHIPPER_PAYEMENT_URL'
const SET_ERROR = 'SET_ERROR'

//initial state
const initialState = {
    responseUrl: null,
    setuppayoutaccountloading: false,
    isPayoutAccount: false,
    submitDetailsLoading: false,
    detailsSubmitted: false,
    stripedashboardRedirectloading: false,
    directedStripeDashboard: false,
    sessionIdLoading: false,
    sessionId: null,
    shipperPaymenturlLoading: false,
    shipperPaymenturl: null
}

//connect account 
export const connectAccount = () => {
    return async dispatch => {
        let redirect_url = {
            return_url: token ? `${baseUrl}payout` : `${baseUrl}settings?tab=payout`,
            refresh_url: `${baseUrl}payout-onboarding`,

        };

        if (window.location.hostname === "localhost") {
            redirect_url = {
                return_url: token ? `http://localhost:3000/payout`
                    : 'http://localhost:3000/settings?tab=payout',
                refresh_url: 'http://localhost:3000/payout-onboarding'
            };
        }

        let url = `/payment/payout/setup/`;
        dispatch({
            type: SETUP_PAYOUT_ACCOUNT_LOADING
        });
        try {
            const response = await axiosClient.post(url, redirect_url);
            dispatch({
                type: SETUP_PAYOUT_ACCOUNT,
                data: response.data
            })
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine(error?.response?.data?.detail)
            });
        }
    }
}

//submit details
export const submitDetails = () => {
    return async dispatch => {
        let redirect_url = {
            return_url: token ? `${baseUrl}payout` : `${baseUrl}settings?tab=payout`,
            refresh_url: `${baseUrl}payout-onboarding`,

        };

        if (window.location.hostname === "localhost") {
            redirect_url = {
                return_url: token ? `http://localhost:3000/payout`
                    : 'http://localhost:3000/settings?tab=payout',
                refresh_url: 'http://localhost:3000/payout-onboarding'
            };
        }

        let url = `/payment/payout/onboarding/link/`;
        dispatch({
            type: SUBMIT_DEAILS_LOADING
        });
        try {
            const response = await axiosClient.post(url, redirect_url);
            dispatch({
                type: SUBMIT_DETAILS,
                data: response.data
            })
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine(error?.response?.data?.detail)
            });
        }
    }
}

//redirect to dashboard
export const redirectStripeDashboard = () => {
    return async dispatch => {
        let url = `/payment/payout/dashboard/link/`;
        dispatch({
            type: STRIPE_DASHBOARD_REDIRECT_LOADING
        });
        try {
            const response = await axiosClient.get(url)
            dispatch({
                type: STRIPE_DASHBOARD_REDIRECT,
                data: response.data
            })
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine(error?.response?.data?.detail)
            });
        }
    }
}

//get session link
export const getSessionLink = () => {
    return async dispatch => {
        let url = `/payment/payout/session/link/`;
        dispatch({
            type: STRIPE_SESSION_ID_LOADING
        });
        try {
            const response = await axiosClient.get(url)
            dispatch({
                type: STRIPE_SESSION_ID,
                data: response.data
            })
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine('Error. please try again later')
            });
        }
    }
}
export const getShipperPaymenturl = () => {
    return async dispatch => {
        let return_url = {
            return_url: `${baseUrl}settings?tab=payment`
        };

        if (window.location.hostname === "localhost") {
            return_url = {
                return_url: 'http://localhost:3000/settings?tab=payment'
            };
        }
        dispatch({
            type: SHIPPER_PAYMENT_URL_LOADING
        });
        try {
            const response = await axiosClient.post('/payment/shipper/billing/portal/', return_url);
            dispatch({
                type: SHIPPER_PAYMENT_URL,
                data: response.data
            });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine('Error. please try again later')
            });
        }
    }
}

//reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                setuppayoutaccountloading: false,
                submitDetailsLoading: false,
                stripedashboardRedirectloading: false,
                sessionIdLoading: false,
                shipperPaymenturlLoading: false
            };
        case SETUP_PAYOUT_ACCOUNT_LOADING:
            return {
                ...state,
                setuppayoutaccountloading: true
            };
        case SETUP_PAYOUT_ACCOUNT:
            return {
                ...state,
                isPayoutAccount: true,
                setuppayoutaccountloading: false,
                responseUrl: action.data.url
            };
        case SUBMIT_DEAILS_LOADING:
            return {
                ...state,
                submitDetailsLoading: true
            };
        case SUBMIT_DETAILS:
            return {
                ...state,
                detailsSubmitted: true,
                submitDetailsLoading: false,
                responseUrl: action.data.url
            };
        case STRIPE_DASHBOARD_REDIRECT_LOADING:
            return {
                ...state,
                stripedashboardRedirectloading: true
            };
        case STRIPE_DASHBOARD_REDIRECT:
            return {
                ...state,
                directedStripeDashboard: true,
                stripedashboardRedirectloading: false,
                responseUrl: action.data.url
            };
        case STRIPE_SESSION_ID_LOADING:
            return {
                ...state,
                sessionIdLoading: true
            };
        case STRIPE_SESSION_ID:
            return {
                ...state,
                sessionIdLoading: false,
                sessionId: action.data.client_secret
            };
            case SHIPPER_PAYMENT_URL_LOADING:
                return {
                    ...state,
                 shipperPaymenturlLoading   : true
                };
            case SHIPPER_PAYMENT_URL:
                return {
                    ...state,
                    shipperPaymenturl: action.data.url
                };
        default:
            return state;
    }
}

import React from 'react';
import { connect } from 'react-redux';

// Material UI.
import { Box, Button, Typography } from '@material-ui/core';

import Popup from 'components/Popup';
import { setDeletableDriver, deleteDriver } from 'reducers/Driver';
import ButtonWithBackground from 'components/Form/Button';

const DeleteDriver = ({
  setDeletableDriver,
  deletable_driver,
  delete_driver_loading,
  deleteDriver
}) => {
  return (
    <Popup
      open={deletable_driver ? true : false}
      onclosehandler={() => setDeletableDriver(null)}
      setMinHeight={150}>
      {deletable_driver ? (
        <Box
          style={{
            maxWidth: 600
          }}>
          <Typography
            style={{ color: '#6B6B6B', fontWeight: 600, marginBottom: 12 }}>
            Delete Driver
          </Typography>

          <Typography display="inline">
            Are you sure you want to permanently remove{' '}
          </Typography>
          <Typography display="inline" style={{ fontWeight: 600 }}>
            {deletable_driver.first_name} {deletable_driver.last_name}?
          </Typography>

          <Box style={{ position: 'absolute', bottom: 0, right: 16 }}>
            <Button
              onClick={() => setDeletableDriver(null)}
              disableRipple
              variant="text"
              style={{
                marginTop: '12px',
                borderRadius: '9px',
                fontSize: '11px',
                height: '30px',
                color: '#EE3C2D',
                fontWeight: 700,
                background: 'transparent'
              }}>
              Cancel
            </Button>
            <ButtonWithBackground
              disabled={delete_driver_loading}
              clickHandler={() => deleteDriver(deletable_driver.trucker_id)}
              loaderSize={14}
              style={{
                marginTop: 20,
                borderRadius: 9,
                fontSize: 10,
                textTransform: 'capitalize',
                minWidth: 100,
                height: 30,
                fontWeight: 700
              }}
              label={`Delete Driver`}
            />
          </Box>
        </Box>
      ) : null}
    </Popup>
  );
};

const mapStateToProps = state => {
  return {
    deletable_driver: state.Driver.deletable_driver,
    delete_driver_loading: state.Driver.delete_driver_loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDeletableDriver: driver => dispatch(setDeletableDriver(driver)),
    deleteDriver: driver_id => dispatch(deleteDriver(driver_id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDriver);

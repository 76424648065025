import axios from 'axios';
import axiosClient from '../utils/axiosClient';
import {
  APP_TOKEN,
  EXISTING_USER,
  PENDING_EMAIL,
  ROADCALL_FCM_TOKEN,
  ROADCALL_ID,
  ROADCALL_USER_TOKEN
} from 'utils/constants';
import { BROKER, CARRIER, SHIPPER } from 'utils/users';

//const url = 'http://34.200.209.143/api/auth/v1'
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const version = process.env.REACT_APP_API_VER;

const AttemptLoginService = async (username: string, password: string) => {
  try {
    // fetch data from a url endpoint
    const response = await axios.post(baseUrl + 'auth/login/' + version + '/', {
      username,
      password
    });
    localStorage.setItem(EXISTING_USER, '1');
    localStorage.setItem('roadcall_id', response.data.roadcall_id);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};
const saveUserTokenService = (data: any) => {
  localStorage.setItem(ROADCALL_USER_TOKEN, JSON.stringify(data));
  localStorage.setItem(ROADCALL_ID, data.roadcall_id);
  }

const saveFCMToken = async (data: any) => {
  if (localStorage.getItem(ROADCALL_FCM_TOKEN) === data.device_token)
    return false;

  try {
    const response = await axiosClient.post(
      'push_notifications/user_device_token/' + version + '/',
      data
    );
    localStorage.setItem(ROADCALL_FCM_TOKEN, data.device_token);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const checkUserTokenService = () => {
  const token: any = localStorage.getItem(ROADCALL_USER_TOKEN);
  if (token) return JSON.parse(token);
  else return false;
};

const userVerified = () => {
  const token = localStorage.getItem(ROADCALL_USER_TOKEN);

  if (token) {
    const parsedToken = JSON.parse(token);
    return parsedToken.onboarding_step !== PENDING_EMAIL;
  }
  return false;
};

const removeUserTokenService = async () => {
  try {
    const response = await axiosClient.delete('auth/logout/' + version + '/');
    localStorage.removeItem(ROADCALL_USER_TOKEN);
    localStorage.removeItem(ROADCALL_ID);
    localStorage.removeItem(APP_TOKEN)
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getUserTypeService = (strictMode: boolean = true) => {
  const token: any = localStorage.getItem(ROADCALL_USER_TOKEN);
  if (token) {
    const obj: any = JSON.parse(token);
    if (obj.user_type === BROKER && strictMode === true) return obj.broker_type;
    return obj.user_type;
  } else return false;
};

const isUserTypeBrokerService = () => {
  const token: any = localStorage.getItem(ROADCALL_USER_TOKEN);
  if (token) {
    const obj: any = JSON.parse(token);
    return obj.user_type === BROKER;
  }

  return false;
};

const userTypeService = (user: any) => {
  if (!user) return;
  else if (user.carrier_id) return CARRIER;
  else if (user.shipper_id) return SHIPPER;
  else if (user.broker_id) return BROKER;
  else return;
};

const getUserIdService = () => {
  const token: any = localStorage.getItem(ROADCALL_USER_TOKEN);
  if (token) {
    const obj: any = JSON.parse(token);
    return obj.roadcall_id;
  } else return false;
};

const getTypeFromId =(id: any) => {
  const obj = id.split('-');
  if (obj[0] === 'RC' && obj.length === 3) {
    return obj[1].toLowerCase();
  }
  return null;
}
export {
  AttemptLoginService,
  saveUserTokenService,
  checkUserTokenService,
  removeUserTokenService,
  getUserTypeService,
  userTypeService,
  getUserIdService,
  userVerified,
  saveFCMToken,
  isUserTypeBrokerService,
  getTypeFromId
};

import React from 'react';
import { connect } from 'react-redux';

// Material UI.
import { makeStyles, TableBody, TableRow, Typography } from '@material-ui/core';
import CustomTable from 'components/CustomTable';
import TransactionHeader from 'components/TableHeader/TransactionHeader';
import { TableCell } from '@mui/material';
import { getNextTransactions, getTransactions } from 'reducers/Payment';
import { convertUtcToLocal } from 'utils/timeUtils';
import { getStateAbbreviation, removeShipmentPrefix } from 'services/Shipments';
import { removeTransactionPrefix } from 'services/users';
import { formatUsCurrency } from 'utils/numberUtils';
import TransactionsLoading from 'components/Skeleton/TransactionsLoading';
import withScroll from 'components/HOC/withScroll';
import RowLoading from 'components/Skeleton/RowLoading';
import { LOADS } from 'utils/routesUtils';
import { useHistory } from 'react-router';

const TransactionsList = ({
  data,
  transactionsLoading,
  nextLoading,
  handleScroll,
  days,
  filterDays,
  ordering,
  setOrdering
}) => {
  const classes = useStyles();
  const history = useHistory();

  if (transactionsLoading) return <TransactionsLoading />;
  else if (data === null) return null;

  return (
    <CustomTable
      isStickyHeader
      className={classes.table}
      noBorder
      scrolling={handleScroll}>
      <TransactionHeader clickHandler={setOrdering} ordering={ordering} />
      <TableBody style={{ position: 'relative' }}>
        {data.results.map(transaction => {
          return (
            <TableRow
              key={transaction.transaction_id}
              style={{ border: '1px solid #eff0f4' }}>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}>
                  {transaction.status}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`${LOADS}?id=${transaction.shipment}`);
                  }}>
                  {removeShipmentPrefix(transaction.shipment)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}>
                  {removeTransactionPrefix(transaction.transaction_id)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}>
                  {transaction.pickup_city
                    ? `${transaction.pickup_city}, `
                    : 'N/A'}
                  {getStateAbbreviation(transaction.pickup_state)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}>
                  {transaction.delivery_city
                    ? `${transaction.delivery_city}, `
                    : 'N/A'}
                  {getStateAbbreviation(transaction.delivery_state)}{' '}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}>
                  {convertUtcToLocal(transaction.updated_at)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}>
                  {formatUsCurrency(transaction.price_per_mile)}/mi{' '}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.cellText}>
                  {formatUsCurrency(transaction.amount)}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
        {data.results.length === 0 ? (
          <Typography
            style={{
              position: 'absolute',
              right: '40%',
              top: '25vh',
              fontWeight: 600
            }}>{`No transactions in ${
            filterDays.find(d => d.value === days).label
          }!`}</Typography>
        ) : null}
        {nextLoading ? (
          <>
            <RowLoading columns={6} />
            <RowLoading columns={6} />
          </>
        ) : null}
      </TableBody>
    </CustomTable>
  );
};

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottomColor: '#eff0f4 !important'
  },
  cellText: {
    fontSize: 12,
    color: '#5c626a'
  },
  table: {
    height: 'calc(100vh - 330px)',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    '& tbody tr td:first-child': {
      borderLeft: '1px solid #eff0f4'
    },
    '& tbody tr td:last-child': {
      borderRight: '1px solid #eff0f4'
    },
    '& thead tr th:first-child': {
      borderTopLeftRadius: 16
    },
    '& thead tr th:last-child': {
      borderTopRightRadius: 16
    },
    '& th': {
      lineHeight: '10px'
    },
    '& thead tr': {
      border: '1px solid #eff0f4'
    },
    '& tbody tr td': {
      backgroundColor: '#fff'
    }
  }
}));

const mapStateToProps = state => ({
  data: state.Payment.transactions,
  transactionsLoading: state.Payment.transactionsLoading,
  nextLoading: state.Payment.transactionsNextLoading
});

const mapDispatchToProps = dispatch => {
  return {
    getTransactions: data => dispatch(getTransactions(data)),
    loadNext: nextUrl => dispatch(getNextTransactions(nextUrl))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(TransactionsList));

import React from 'react';
import { makeStyles, createStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SpinLoader from 'components/loader/SpinLoader';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const ButtonWithBackground = React.forwardRef(
  (
    {
      label,
      clickHandler,
      className,
      type,
      disabled,
      buttonType,
      disabledOnly,
      loaderSize,
      ...rest
    },
    ref
  ) => {
    const userType = getUserTypeService();
    const classes = useStyles({ userType, type });

    return (
      <Button
        ref={ref}
        disableRipple
        className={clsx(
          type === 'border'
            ? userType === SHIPPER
              ? classes.border2
              : classes.border
            : userType === SHIPPER
            ? classes.background2
            : classes.background,
          classes.button,
          className
        )}
        disabled={disabled || disabledOnly}
        color="primary"
        variant={type === 'border' ? 'outlined' : 'contained'}
        size="small"
        type={buttonType}
        {...rest}
        onClick={e => {
          if (clickHandler) clickHandler(e);
        }}>
        {disabled ? <SpinLoader size={loaderSize ? loaderSize : 25} /> : label}
      </Button>
    );
  }
);

ButtonWithBackground.defaultProps = {
  type: 'background',
  buttonType: 'button',
  disabled: false,
  disabledOnly: false
};

ButtonWithBackground.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  buttonType: PropTypes.string,
  disabledOnly: PropTypes.bool,
  clickHandler: PropTypes.func
};

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      fontWeight: 600,
      boxShadow: 'none !important',
      marginBottom: 8,
      boxShadow: 'none !important',
      '&:hover': {
        background: props =>
          props.userType === SHIPPER
            ? props.type === 'border'
              ? '#fff'
              : '#34898b'
            : props.type === 'border'
            ? '#fff'
            : '#D83528',
        border: props =>
          props.type === 'border'
            ? props.userType === SHIPPER
              ? '1px solid #34898b'
              : '1px solid #D83528'
            : 'auto',
        color: props =>
          props.type === 'border'
            ? props.userType === SHIPPER
              ? '#34898b'
              : '#D83528'
            : '#fff'
      },
      fontSize: 14
    },
    border: {
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: theme.spacing(2),
      color: theme.palette.primary.dark,
      background: theme.palette.secondary
    },
    background: {
      borderRadius: theme.spacing(2),
      background: theme.palette.primary.dark
    },
    border2: {
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: theme.spacing(2),
      color: theme.palette.secondary.dark,
      background: theme.palette.secondary
    },
    background2: {
      borderRadius: theme.spacing(2),
      background: theme.palette.secondary.dark
    }
  })
);

export default ButtonWithBackground;

import moment from 'moment-timezone';

const convertUtcToLocal = (utcTime:string, format:string = 'MMM DD, YYYY') => {
  if(!utcTime) return '';
  return moment.utc(utcTime).format(format);
}

const convertIsoToLocal = (isoTime:string, format = 'MMM DD, YYYY') => {
  if (!isoTime) return '';
  return moment.utc(isoTime).format(format);
};


const convertUtcToLocalWithTimeZOne = (utcTime: string, format: string = 'MMM DD, YYYY') => {
  if (!utcTime) {
    return '';
  }
  const localTime = moment.utc(utcTime).local();
  const formattedTime = localTime.format(`${format} [at] hh:mm a`);
  const localTimeZone = moment.tz(moment.tz.guess()).format('z');

  return formattedTime + ' ' + localTimeZone;
};

const convertUtcToRelativeTime = (utcTime:string) => {
  if (!utcTime) return '';

  const now = moment();
  const timeAgo = moment.utc(utcTime);
  const diffInSeconds = now.diff(timeAgo, 'seconds');
  const diffInDays = now.diff(timeAgo, 'days');
  const diffInMonths = now.diff(timeAgo, 'months');
  const diffInYears = now.diff(timeAgo, 'years');

  if (diffInSeconds < 60) {
    return `${diffInSeconds} sec${diffInSeconds === 1 ? '' : 's'} ago`;
  } else if (diffInSeconds < 3600) {
    return `${Math.floor(diffInSeconds / 60)} min${Math.floor(diffInSeconds / 60) === 1 ? '' : 's'} ago`;
  } else if (diffInSeconds < 86400) {
    return `${Math.floor(diffInSeconds / 3600)} hr${Math.floor(diffInSeconds / 3600) === 1 ? '' : 's'} ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  } else if (diffInMonths === 1) {
    return '1 month ago';
  } else if (diffInMonths > 1 && diffInMonths < 12) {
    return `${diffInMonths} months ago`;
  } else if (diffInYears === 1) {
    return 'a year ago';
  } else if (diffInYears > 1) {
    return `${diffInYears} years ago`;
  } else {
    return timeAgo.format('MMM DD, YYYY');
  }
};

const isToday = (time: string) => {
  const format = 'MMM DD, YYYY';
  const today = moment.utc().local().format(format)
  const createdDate = moment.utc(time).local().format(format)

  return today === createdDate
}

const formatDateOnly = (dateTimeString:any) => {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const convertNewShipmentDatetoUtc = (date:string) => {
  const localDateStringFormat = "MM/DD/YYYY HH:mm";
  const utcMoment = moment.utc(moment(date, localDateStringFormat ).utc().format('YYYY-MM-DD HH:mm:ss.SSSZ'))
  return utcMoment
}

export { convertUtcToLocal, convertIsoToLocal, convertUtcToLocalWithTimeZOne,formatDateOnly, convertUtcToRelativeTime, convertNewShipmentDatetoUtc, isToday}
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { DOCUMENT_VERIFICATION_STEPS, ERROR, S3_BUCKET_DIR } from 'utils/constants';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { ReactComponent as UploadIcon } from 'assets/images/common/upload-sm.svg';
import ButtonWithBackground from 'components/Form/Button';
import { setUserData, uploadVerificationDocuments } from 'reducers/User';
import { connect } from 'react-redux';
import { uploadFileService } from 'services/files';
import { getUserDetailService } from 'services/users';
import { getUserIdService, getUserTypeService } from 'services/login';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { makeStyles } from '@material-ui/core';
import UploadedFiles from './UploadedFiles';

const UploadDoc = ({
  uploadVerificationDocuments,
  currentVerificationTab,
  getUploadedDoc,
  setUserData,
  setTabValue,
  setSnackbar
}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const classes = useStyles();

  const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    accept: '.jpeg, .jpg, .png, .pdf',
    noClick: true,
    multiple: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      setFiles([...files, ...acceptedFiles]);
    }
  });

  useEffect(() => {
    const uploadedDocument = getUploadedDoc(
      DOCUMENT_VERIFICATION_STEPS[currentVerificationTab]
    );

    if (uploadedDocument !== undefined) {
      let docs = [];
      uploadedDocument.map(file => {
        docs.push({
          name: file
        });
      });

      setFiles(docs);
    }
  }, [currentVerificationTab]);

  const removeItem = index => {
    let allFiles = [...files];

    allFiles.splice(index, 1);

    setFiles(allFiles);
  };

  const moveNext = () => {
    let nextValue = 1;

    // If reaches last step then set to 1.
    if (
      currentVerificationTab ===
      DOCUMENT_VERIFICATION_STEPS.indexOf(
        DOCUMENT_VERIFICATION_STEPS[DOCUMENT_VERIFICATION_STEPS.length - 1]
      )
    )
      nextValue = 1;
    else nextValue = currentVerificationTab + 1;

    setTabValue(nextValue);
  };

  const uploadFiles = async () => {
    setLoading(true);

    try {
      let s3_bucket_url = [];

      let filesizes=[]
      for (const file of files) {
        const res = await uploadFileService(file.name, S3_BUCKET_DIR.CARRIER_MEDIA, file);

        s3_bucket_url.push(res);
        filesizes.push(
          file.size
        )
      }

      await uploadVerificationDocuments({
        document_type: DOCUMENT_VERIFICATION_STEPS[currentVerificationTab],
        s3_bucket_url,
        file_size:filesizes
      });

      const user = await getUserDetailService(
        getUserIdService(),
        getUserTypeService(false)
      );
      setUserData(user);

      moveNext();
    } catch (e) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e.response?.data)
      });
    }

    setLoading(false);
  };

  return (
    <Box>
      <div className="dropzone">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={clsx('dz-message', classes.dropzone)} onClick={open}>
            <UploadIcon />
            <Box>
              <Typography
                display="inline"
                className={classes.textUploadFiles}
                style={{
                  fontWeight: 600,
                  marginTop: 8,
                  fontSize: 12,
                  color: '#9D9EA5'
                }}>
                Drag and Drop files here or{' '}
              </Typography>

              <Typography
                display="inline"
                className={classes.textBrowse}
                style={{ fontWeight: 600, fontSize: 12, color: '#ED3D2E' }}>
                browse
              </Typography>
              <Typography
                className={classes.textUploadFiles}
                style={{
                  fontWeight: 600,
                  marginTop: 8,
                  fontSize: 10,
                  color: '#9D9EA5'
                }}>
                Max file size 25MB
              </Typography>
            </Box>
          </div>
        </div>
      </div>

      <UploadedFiles files={files} removeItem={removeItem} />

      <Box style={{ display: 'flex', marginTop: 24 }}>
        <ButtonWithBackground
          disabledOnly={files.length === 0 || loading}
          fullWidth
          type="border"
          label="Cancel"
          clickHandler={() => setFiles([])}
          style={{ margin: 8, borderRadius: 11 }}
        />
        <ButtonWithBackground
          disabled={loading}
          disabledOnly={files.length === 0}
          clickHandler={uploadFiles}
          fullWidth
          label={'Submit'}
          style={{ margin: 8, borderRadius: 11, color: '#fff' }}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  dropzone: {
    background: '#fff',
    border: '2px dashed #e2e2e2',
    '&:hover': {
      borderColor: '#e2e2e2'
    }
  }
}));

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    uploadVerificationDocuments: data =>
      dispatch(uploadVerificationDocuments(data)),
    setUserData: data => dispatch(setUserData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDoc);

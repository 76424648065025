import * as React from "react"

const EnvelopeSvg = (props) => (
  <svg
    data-name="Group 8601"
    xmlns="http://www.w3.org/2000/svg"
    width={15.921}
    height={11.491}
    {...props}
  >
    <defs>
      <clipPath id="envelop_7987987">
        <path
          data-name="Rectangle 1772"
          fill="#999db7"
          d="M0 0h15.921v11.491H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 8600" clipPath="url(#envelop_7987987)">
      <path
        data-name="Path 10615"
        d="M7.946 11.49H1.53A1.342 1.342 0 0 1 0 9.962V1.528A1.342 1.342 0 0 1 1.532 0h12.885a1.332 1.332 0 0 1 1.5 1.5v8.487a1.333 1.333 0 0 1-1.5 1.5H7.948m.015-4.883 5.616-5.25H2.34l5.621 5.25m-5.623 3.531h11.246L9.856 6.658c-.454.423-.876.822-1.3 1.213-.462.422-.721.421-1.186 0-.437-.4-.869-.8-1.3-1.208l-3.732 3.472m-.977-.91 3.728-3.48-3.727-3.48Zm13.206-6.97-3.736 3.492 3.737 3.484Z"
        fill="#999db7"
      />
    </g>
  </svg>
)

export default EnvelopeSvg

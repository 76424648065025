import React, { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Material UI.
import { Box, makeStyles, Typography } from '@material-ui/core';

import { ReactComponent as AddPaymentIcon } from 'assets/images/payment/add-payment.svg';
import { ReactComponent as UnavailableInBetaRibbon } from 'assets/images/settings/unavailable-in-beta-ribbon.svg';
import { connect } from 'react-redux';
import { setPaymentMethodPopupVisible } from 'reducers/Payment';

const AddPaymentPlaceholder = ({
  stripeAccountsLoading,
  setPaymentMethodPopupVisible,
  shipperCardsLoading,
  carrierShowShipperScreens
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.placeholderParentBox}>
      {!carrierShowShipperScreens && (
        <UnavailableInBetaRibbon className={classes.unavailableInBetaRibbon} />
      )}
      <Box
        className={
          carrierShowShipperScreens
            ? classes.placeholder
            : classes.disabledPlaceholder
        }
        onClick={() => {
          if (
            stripeAccountsLoading ||
            shipperCardsLoading ||
            !carrierShowShipperScreens
          )
            return;
          setPaymentMethodPopupVisible(true);
        }}>
        <AddPaymentIcon />
        <Typography
          style={{
            color: '#a7a7a8',
            fontWeight: 600,
            fontSize: 12,
            marginTop: 4
          }}>
          Add Payment Method
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  placeholderParentBox: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  placeholder: {
    backgroundColor: '#fff',
    width: 250,
    minWidth: 250,
    height: 130,
    borderRadius: 11,
    border: '5px dashed #E7E8E8',
    background: '#F0F0F0 0% 0% no-repeat padding-box',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginTop: 16
    }
  },
  disabledPlaceholder: {
    backgroundColor: '#F0F0F0',
    width: 250,
    minWidth: 250,
    height: 130,
    bottom: 0,
    borderRadius: 11,
    border: '5px dashed #E7E8E8',
    background: '#F0F0F0 0% 0% no-repeat padding-box',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  },
  unavailableInBetaRibbon: {
    height: '22px',
    position: 'relative',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: -11,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginTop: 16
    }
  }
}));

const mapStateToProps = state => ({
  stripeAccountsLoading: state.Payment.stripeAccountsLoading,
  shipperCardsLoading: state.Payment.shipperCardsLoading
});

const mapDispatchToProps = dispatch => {
  return {
    setPaymentMethodPopupVisible: bool =>
      dispatch(setPaymentMethodPopupVisible(bool))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentPlaceholder);

import { getShipmentMedia } from 'services/files';
import axiosClient from 'utils/axiosClient';
import { PAYMENT_STATUS } from 'utils/constants';
import {
  breakSingleLineMessage,
  errorMessageSingleLine
} from 'utils/errorUtils';
import { setUserIsCardAdded, setUserPaymentStatus } from './User';

const version = process.env.REACT_APP_API_VER;

//declarations
const SET_ERROR = 'SET_ERROR_TRUCK';
const SET_STRIPE_ACCOUNTS = 'SET_STRIPE_ACCOUNTS';
const SET_STRIPE_ACCOUNTS_LOADING = 'SET_STRIPE_ACCOUNTS_LOADING';
const SET_PAYMENT_METHOD_VISIBLE = 'SET_PAYMENT_METHOD_VISIBLE';
const SET_CREATE_STRIPE_ACCOUNT_LOADING = 'SET_CREATE_STRIPE_ACCOUNT_LOADING';
const SET_CREATE_PAYMENT_METHOD_LOADING = 'SET_CREATE_PAYMENT_METHOD_LOADING';
const SET_DELETE_PAYMENT_METHOD_LOADING = 'SET_DELETE_PAYMENT_METHOD_LOADING';
const SET_DEFAULT_PAYMENT_METHOD_LOADING = 'SET_DEFAULT_PAYMENT_METHOD_LOADING';
const SET_DELETE_PAYMENT_METHOD_POPUP_VISIBLE =
  'SET_DELETE_PAYMENT_METHOD_POPUP_VISIBLE';
const SET_DEFAULT_PAYMENT_METHOD_POPUP_VISIBLE =
  'SET_DEFAULT_PAYMENT_METHOD_POPUP_VISIBLE';
const SET_SHIPPER_CARDS_LOADING = 'SET_SHIPPER_CARDS_LOADING';
const SET_SHIPPER_CARDS = 'SET_SHIPPER_CARDS';
const SET_DEFAULT_SHIPPER_CARD_LOADING = 'SET_DEFAULT_SHIPPER_CARD_LOADING';
const SET_DEFAULT_SHIPPER_CARD = 'SET_DEFAULT_SHIPPER_CARD';
const SET_DELETE_SHIPPER_CARD_LOADING = 'SET_DELETE_SHIPPER_CARD_LOADING';
const SET_DELETE_SHIPPER_CARD = 'SET_DELETE_SHIPPER_CARD';
const SET_DEBIT_CARD_LOADING = 'SET_DEBIT_CARD_LOADING';
const SET_DEBIT_CARD = 'SET_DEBIT_CARD';
const SET_TRANSACTIONS_LOADING = 'SET_TRANSACTIONS_LOADING';
const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
const SET_TRANSACTIONS_NEXT_LOADING = 'SET_TRANSACTIONS_NEXT_LOADING';
const SET_NEXT_TRANSACTIONS = 'SET_NEXT_TRANSACTIONS';
const SET_EXPORT_TRANSACTIONS_LOADING = 'SET_EXPORT_TRANSACTIONS_LOADING';
const SET_EXPORT_TRANSACTIONS = 'SET_EXPORT_TRANSACTIONS';

//initial state
const initialState = {
  error: '',
  stripeAccounts: null,
  stripeAccountsLoading: false,
  paymentMethodPopupVisible: false,
  createStripeAccountLoading: false,
  createPaymentMethodLoading: false,
  deletePaymentMethodLoading: false,
  defaultPaymentMethodLoading: false,
  defaultPaymentMethodPopupVisible: false,
  removePaymentMethodPopupVisible: false,
  deletePaymentMethodPopupVisible: false,
  shipperCards: null,
  shipperCardsLoading: false,
  shipperDefaultCardLoading: false,
  shipperDeleteCardLoading: false,
  debitCardLoading: false,
  transactions: null,
  transactionsLoading: false,
  transactionsNextLoading: false,
  exportTransactionsLoading: false
};

export const setDeletePaymentMethodPopupVisible = bool => {
  return dispatch => {
    dispatch({
      type: SET_DELETE_PAYMENT_METHOD_POPUP_VISIBLE,
      data: bool
    });
  };
};

export const setPaymentMethodPopupVisible = bool => {
  return dispatch => {
    dispatch({
      type: SET_PAYMENT_METHOD_VISIBLE,
      data: bool
    });
  };
};

export const setDefaultPaymentMethodPopupVisible = bool => {
  return dispatch => {
    dispatch({
      type: SET_DEFAULT_PAYMENT_METHOD_POPUP_VISIBLE,
      data: bool
    });
  };
};

export const getStripeAccounts = () => {
  return async dispatch => {
    dispatch({
      type: SET_STRIPE_ACCOUNTS_LOADING
    });

    try {
      const response = await axiosClient.get(
        `/payment/retrievestripeaccount/${version}/`
      );

      if (!response.data.error && response.data.stripe_account)
        dispatch({
          type: SET_STRIPE_ACCOUNTS,
          data: response.data.stripe_account
        });
      else
        dispatch({
          type: SET_STRIPE_ACCOUNTS_LOADING,
          data: false
        });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error)
      });
    }
  };
};

export const createStripeAccount = data => {
  return async dispatch => {
    dispatch({
      type: SET_CREATE_STRIPE_ACCOUNT_LOADING
    });
    try {
      await axiosClient.post(`/payment/customaccount/${version}/`, data);
      dispatch(setUserPaymentStatus(PAYMENT_STATUS.UPDATED));
      dispatch(getStripeAccounts());
      dispatch(setPaymentMethodPopupVisible(false));
      dispatch({
        type: SET_CREATE_STRIPE_ACCOUNT_LOADING,
        data: false
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: breakSingleLineMessage(
          error.response.data?.message || error.response.data?.error
        )
      });
    }
  };
};

export const createPaymentMethod = data => {
  return async dispatch => {
    dispatch({
      type: SET_CREATE_PAYMENT_METHOD_LOADING
    });
    try {
      const res = await axiosClient.post(
        `payment/add/paymentmethod/${version}/`,
        data
      );
      dispatch(getStripeAccounts());
      dispatch(setPaymentMethodPopupVisible(false));
      dispatch({
        type: SET_CREATE_PAYMENT_METHOD_LOADING,
        data: false
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: breakSingleLineMessage(
          error.response.data?.message || error.response.data?.error
        )
      });
    }
  };
};

export const setDefaultPaymentMethod = payment_method_id => {
  return async dispatch => {
    dispatch({
      type: SET_DEFAULT_PAYMENT_METHOD_LOADING
    });
    try {
      await axiosClient.post(
        `payment/default/paymentmethod/${payment_method_id}/${version}/`
      );
      dispatch(getStripeAccounts());
      dispatch(setDefaultPaymentMethodPopupVisible(false));
      dispatch({
        type: SET_DEFAULT_PAYMENT_METHOD_LOADING,
        data: false
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: breakSingleLineMessage(
          error.response.data?.message || error.response.data?.error
        )
      });
    }
  };
};

export const deletePaymentMethod = payment_method_id => {
  return async dispatch => {
    dispatch({
      type: SET_DELETE_PAYMENT_METHOD_LOADING
    });
    try {
      await axiosClient.delete(
        `payment/delete/paymentmethod/${payment_method_id}/${version}/`
      );
      dispatch(getStripeAccounts());
      dispatch(setDeletePaymentMethodPopupVisible(false));
      dispatch({
        type: SET_DELETE_PAYMENT_METHOD_LOADING,
        data: false
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: breakSingleLineMessage(
          error.response.data?.message || error.response.data?.error
        )
      });
    }
  };
};

export const getShipperAddedCards = () => {
  return async dispatch => {
    dispatch({
      type: SET_SHIPPER_CARDS_LOADING
    });
    try {
      const response = await axiosClient.get(`payment/cards/${version}/`);
      dispatch({
        type: SET_SHIPPER_CARDS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error)
      });
    }
  };
};

export const setDefaultShipperCard = (card_id, customer_id) => {
  return async dispatch => {
    dispatch({
      type: SET_DEFAULT_SHIPPER_CARD_LOADING
    });
    try {
      const response = await axiosClient.put(
        `payment/card/${customer_id}/update/${version}/`,
        {
          card_id
        }
      );
      dispatch(setDefaultPaymentMethodPopupVisible(false));
      dispatch(getShipperAddedCards());
      dispatch({
        type: SET_DEFAULT_SHIPPER_CARD,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: breakSingleLineMessage(
          error.response.data?.message || error.response.data?.error
        )
      });
    }
  };
};

export const deleteShipperCard = card_id => {
  return async dispatch => {
    dispatch({
      type: SET_DELETE_SHIPPER_CARD_LOADING
    });
    try {
      const response = await axiosClient.delete(
        `payment/card/${card_id}/delete/${version}/`
      );
      dispatch(setDeletePaymentMethodPopupVisible(false));
      dispatch(getShipperAddedCards());
      dispatch({
        type: SET_DELETE_SHIPPER_CARD,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: breakSingleLineMessage(
          error.response.data?.message || error.response.data?.error
        )
      });
    }
  };
};

export const addDebitCard = token => {
  return async dispatch => {
    dispatch({
      type: SET_DEBIT_CARD_LOADING
    });
    try {
      const response = await axiosClient.post(`payment/card/${version}/`, {
        token
      });
      dispatch(setUserIsCardAdded(true));
      dispatch(setUserPaymentStatus(PAYMENT_STATUS.UPDATED));
      dispatch(setPaymentMethodPopupVisible(false));
      dispatch(getShipperAddedCards());
      dispatch({
        type: SET_DEBIT_CARD,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response)
      });
    }
  };
};

export const getTransactions = data => {
  return async dispatch => {
    dispatch({
      type: SET_TRANSACTIONS_LOADING
    });
    try {
      const response = await axiosClient.get(
        `payment/transactions/${version}/`,
        {
          params: data
        }
      );

      dispatch({
        type: SET_TRANSACTIONS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response)
      });
    }
  };
};

export const getNextTransactions = nextUrl => {
  return async dispatch => {
    dispatch({
      type: SET_TRANSACTIONS_NEXT_LOADING
    });
    try {
      const response = await axiosClient.get(nextUrl);

      dispatch({
        type: SET_NEXT_TRANSACTIONS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response)
      });
    }
  };
};

export const exportTransactions = data => {
  return async dispatch => {
    dispatch({
      type: SET_EXPORT_TRANSACTIONS_LOADING
    });
    try {
      const response = await axiosClient.post(
        `payment/transactions/download/${version}/?days=${data.days}`
      );

      if (
        response.data &&
        Array.isArray(response.data) &&
        response.data.length === 1 &&
        response.data[0].substring(response.data[0].length - 5) === '.xlsx'
      ) {
        await getShipmentMedia(response.data[0]);
        dispatch({
          type: SET_EXPORT_TRANSACTIONS,
          data: response.data
        });
      } else
        dispatch({
          type: SET_ERROR,
          data: errorMessageSingleLine()
        });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response)
      });
    }
  };
};

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.data,
        stripeAccountsLoading: false,
        createStripeAccountLoading: false,
        createPaymentMethodLoading: false,
        deletePaymentMethodLoading: false,
        defaultPaymentMethodLoading: false,
        shipperCardsLoading: false,
        shipperDefaultCardLoading: false,
        shipperDeleteCardLoading: false,
        debitCardLoading: false,
        transactionsLoading: false,
        transactionsNextLoading: false,
        exportTransactionsLoading: false
      };
    case SET_DEBIT_CARD_LOADING:
      return {
        ...state,
        debitCardLoading: true
      };
    case SET_DEBIT_CARD:
      return {
        ...state,
        debitCardLoading: false
      };
    case SET_DELETE_SHIPPER_CARD:
      return {
        ...state,
        shipperDeleteCardLoading: false
      };
    case SET_DELETE_SHIPPER_CARD_LOADING:
      return {
        ...state,
        shipperDeleteCardLoading: true
      };
    case SET_DEFAULT_SHIPPER_CARD_LOADING:
      return {
        ...state,
        shipperDefaultCardLoading: true
      };
    case SET_DEFAULT_SHIPPER_CARD:
      return {
        ...state,
        shipperDefaultCardLoading: false
      };
    case SET_SHIPPER_CARDS:
      return {
        ...state,
        shipperCards: action.data,
        shipperCardsLoading: false
      };
    case SET_SHIPPER_CARDS_LOADING:
      return {
        ...state,
        shipperCardsLoading: true
      };
    case SET_STRIPE_ACCOUNTS:
      return {
        ...state,
        stripeAccounts: action.data,
        stripeAccountsLoading: false
      };
    case SET_STRIPE_ACCOUNTS_LOADING:
      return {
        ...state,
        error: '',
        stripeAccountsLoading: action.data === false ? false : true
      };
    case SET_CREATE_STRIPE_ACCOUNT_LOADING:
      return {
        ...state,
        error: '',
        createStripeAccountLoading: action.data === false ? false : true
      };
    case SET_DELETE_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        error: '',
        deletePaymentMethodLoading: action.data === false ? false : true
      };
    case SET_DEFAULT_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        error: '',
        defaultPaymentMethodLoading: action.data === false ? false : true
      };
    case SET_CREATE_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        error: '',
        createPaymentMethodLoading: action.data === false ? false : true
      };
    case SET_PAYMENT_METHOD_VISIBLE:
      return {
        ...state,
        paymentMethodPopupVisible: action.data
      };
    case SET_DEFAULT_PAYMENT_METHOD_POPUP_VISIBLE:
      return {
        ...state,
        defaultPaymentMethodPopupVisible: action.data
      };
    case SET_DELETE_PAYMENT_METHOD_POPUP_VISIBLE:
      return {
        ...state,
        deletePaymentMethodPopupVisible: action.data
      };
    case SET_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactionsLoading: true
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactionsLoading: false,
        transactions: action.data
      };
    case SET_TRANSACTIONS_NEXT_LOADING:
      return {
        ...state,
        transactionsNextLoading: true
      };
    case SET_EXPORT_TRANSACTIONS_LOADING:
      return {
        ...state,
        error: '',
        exportTransactionsLoading: true
      };
    case SET_EXPORT_TRANSACTIONS:
      return {
        ...state,
        exportTransactionsLoading: false
      };
    case SET_NEXT_TRANSACTIONS:
      return {
        ...state,
        transactionsNextLoading: false,
        transactions: {
          ...action.data,
          results: state.transactions.results.concat(action.data.results)
        }
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  CardMedia,
  Link,
  makeStyles,
  createStyles
} from '@material-ui/core';

// Assets.
import RC_Logo from 'assets/images/logos/rc_logo.png';
import { ReactComponent as TickIcon } from 'assets/images/common/tick.svg';

//components
import Markers from './Markers';
import clsx from 'clsx';

const MainContainer = ({ children, fullPage, title }) => {
  const classes = useStyles();
  const [mouseLoc, setMouseLoc] = useState({ x: 0, y: 0 });

  const mouseMoveHandler = evt => {
    let mouse_x = evt.pageX;
    let mouse_y = evt.pageY;

    setMouseLoc({
      x: mouse_x,
      y: mouse_y
    });
  };

  const options = [
    'Free to Sign Up',
    'Easy to Use',
    'Customer Support',
    'Dispatching Tools',
    'Carrier App',
    'Driver App',
    'Electronic BOLs',
    'Invoicing',
    'And much more!'
  ];

  if (fullPage)
    return (
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className="bg-white"
          onMouseMove={mouseMoveHandler.bind(this)}>
          <Box className="left-box">
            <Box
              className={clsx(
                'left-box___inner',
                classes.leftBoxContainerFull
              )}>
              <Link href="/">
                <CardMedia
                  component="img"
                  src={RC_Logo}
                  className={clsx('logo-placeholder', classes.logo)}
                  style={{ width: 180, marginTop: 20, marginBottom: 20 }}
                />
              </Link>
              <Typography variant="h3" style={{ color: '#ee4031', marginBottom: 20 }}>
                {title}
              </Typography>
            </Box>
            <Box style={{ paddingLeft: 50, paddingRight: 50 }}>{children}</Box>
          </Box>
        </Grid>
      </Grid>
    );

  return (
    <Grid container spacing={0} className="signin-signup-layout">
      <Grid
        item
        xs={0}
        md={6}
        sm={0}
        lg={7}
        //className="bg-white"
        onMouseMove={mouseMoveHandler.bind(this)}>
        <Box className="left-box">
        <Box className={classes.hidecontent}>
          <Box className="left-box___inner">

            <Link href="/">
              <CardMedia
                component="img"
                src={RC_Logo}
                className="logo-placeholder"
              />
            </Link>
            <Box style={{ marginTop: 40 }}>
              <Typography variant="h1" className={classes.title}>
                Your Auto Transport
              </Typography>
              <Typography variant="h1" className={classes.title}>
                Management Partner
              </Typography>
            </Box>
            <Box style={{ marginTop: '8%' }}>
              <Typography variant="h6" className={classes.body}>
                Designed to provide Auto Carriers with a streamlined and efficient way to manage your drivers and loads.
              </Typography>
            </Box>
            <Box className={classes.text}>
            <Box className="features">
              {options.map((option, index) => (
                <Box key={index} style={{ marginBottom: 8 }}>
                  <TickIcon />{' '}
                  <Typography
                    display="inline"
                    style={{ marginLeft: 10, fontWeight: 600, fontSize: 13 }}>
                    {option}
                  </Typography>
                </Box>
              ))}
            </Box>
            </Box>
          </Box>
          </Box>

          <Grid container spacing={0} className="markers-section">
            <Markers mouseLocation={mouseLoc} />
          </Grid>
        </Box>
      </Grid>
      <Grid className={classes.background}
        item
        xs={12}
        md={6}
        sm={12}
        lg={5}
        >
        <Box className="right-box">{children}</Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      fontWeight: 700,
      color: theme.palette.primary.dark
    },
    body: {
      fontWeight: 700,
      color: '#6B6B6B',
      fontSize: 13
    },
    leftBoxContainerFull: {
      //marginRight: `0px !important`,
      display: 'flex',
      flexDirection: 'column',
      //justifyContent: 'space-around',
      alignItems: 'center',
      textAlign: 'center',
     [ theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: `10px !important`,
      }
    },
    hidecontent:{
      [ theme.breakpoints.down('sm')]: {
        display:'none'
      }
    },
    text:{
      display:'none',
      [ theme.breakpoints.up('md')]: {
        display:'inline'
      }
    },
    background:{
      background: '#f5f8fa',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 25,
      display: 'flex',
      justifyContent: 'center',
      [ theme.breakpoints.up('md')]: {
        borderTopLeftRadius:25,
        alignItems: 'center'
      }
    },
    logo: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 16
      }
    }
  })
);

export default MainContainer;

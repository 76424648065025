import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import {
  getUserTypeService,
  isUserTypeBrokerService,
  removeUserTokenService
} from 'services/login';
import ButtonWithBackground from 'components/Form/Button';
import { DASHBOARD_PAGE, SETTINGS_PAGE } from 'utils/routesUtils';
import LineLoading from 'components/loader/LineLoader';

import { ReactComponent as ProfileIcon } from 'assets/images/profile-menu/profile.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/profile-menu/settings.svg';
import { ReactComponent as SwitchIcon } from 'assets/images/profile-menu/switch.svg';
import { CARRIER, SHIPPER } from 'utils/users';
import { getNameAbbreviation, updateBrokerType } from 'services/users';

import Menu from '@mui/material/Menu';
import { ERROR } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { resetMixPanel } from 'utils/MixPanel';

const S3_URL = process.env.REACT_APP_S3_URL;

function HeaderUserbox(props) {
  const { user_data, setSnackbar } = props;

  const userType = getUserTypeService();

  const [anchorEl, setAnchorEl] = useState(null);

  const [logoutLoading, setLogoutLoading] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = async () => {
    setLogoutLoading(true);
    try {
      await removeUserTokenService();
      resetMixPanel();
      window.location.href = '/';
    } catch (e) {
      window.location.href = '/';
    }
  };

  const switchBroker = async () => {
    setLogoutLoading(true);
    try {
      await updateBrokerType();
    } catch (e) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine()
      });
    }
    setLogoutLoading(false);
  };

  if (!user_data) {
    return (
      <>
        <Button
          disableRipple
          color="inherit"
          onClick={handleClick}
          className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
          <Box>
            <Avatar
              style={{
              width: 50,
              height: 50,
              fontWeight: 700,
              border: '8px solid #ABACC3'
              }} >
            </Avatar>
          </Box>
        </Button>
        <Menu
          className="profile-menu-main"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 2
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 35,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
            <List>
              <Link to="#" onClick={logoutUser}>
                <ListItem className="profile-menu-item">
                  <Typography
                    style={{ fontSize: 12, fontWeight: 500, color: '#1a1818' }}>
                    Log out
                  </Typography>
                </ListItem>
              </Link>
            </List>
            {logoutLoading ? <LineLoading /> : null}
          </div>
        </Menu>
      </>
    )}

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <Avatar
            //sizes="44"
            style={{
              width: 50,
              height: 50,
              fontWeight: 700
            }}
            src={
              user_data.profile_pic_url
                ? S3_URL + user_data.profile_pic_url
                : ''
            }
            className={
              userType === SHIPPER ? 'avatar-border2' : 'avatar-border'
            }>
            {getNameAbbreviation(user_data.first_name, user_data.last_name)}
          </Avatar>
        </Box>
      </Button>

      <Menu
        className="profile-menu-main"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 2
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 35,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List>
            <Link to={DASHBOARD_PAGE}>
              <ListItem onClick={handleClose} className="profile-menu-item">
                <ProfileIcon />{' '}
                <Typography
                  display="inline"
                  style={{
                    marginLeft: 10,
                    fontSize: 12,
                    fontWeight: 500,
                    color: '#1a1818'
                  }}>
                  Dashboard
                </Typography>
              </ListItem>
            </Link>
            <Link to={SETTINGS_PAGE}>
              <ListItem onClick={handleClose} className="profile-menu-item">
                <SettingsIcon />
                <Typography
                  display="inline"
                  style={{
                    marginLeft: 10,
                    fontSize: 12,
                    fontWeight: 500,
                    color: '#1a1818'
                  }}>
                  Settings
                </Typography>
              </ListItem>
            </Link>
            {isUserTypeBrokerService() ? (
              <Link to="#">
                <ListItem
                  onClick={() => switchBroker()}
                  className="profile-menu-item">
                  <SwitchIcon />
                  <Typography
                    display="inline"
                    style={{
                      marginLeft: 12,
                      fontSize: 12,
                      fontWeight: 500,
                      color: '#1a1818'
                    }}>
                    Switch to{' '}
                    {getUserTypeService() === SHIPPER ? CARRIER : SHIPPER} view
                  </Typography>
                </ListItem>
              </Link>
            ) : null}
            <Link to="#" onClick={logoutUser}>
              <ListItem className="profile-menu-item">
                <Typography
                  style={{ fontSize: 12, fontWeight: 500, color: '#1a1818' }}>
                  Log out
                </Typography>
              </ListItem>
            </Link>
          </List>
          {logoutLoading ? <LineLoading /> : null}
        </div>
      </Menu>
    </>
  );
}

export default connect(state => ({
  user_data: state.User.user_data
}))(HeaderUserbox);

import axiosClient from 'utils/axiosClient';
import { PREFIX_TRUCK } from 'utils/constants';

const version = process.env.REACT_APP_API_VER;

const addTruck = async (obj: any) => {
  let url = `truck/${version}/`;

  try {
    // fetch data from a url endpoint
    const response = await axiosClient.post(url, obj);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const updateTruck = async (truck_id: string, data: any) => {
  try {
    // fetch data from a url endpoint
    const response = await axiosClient.put(
      `/truck/${version}/${PREFIX_TRUCK}${truck_id}/update/`,
      data
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const updateTruckStatusService = async (truck_id: string, status: string) => {
  let url = `truck/${version}/${truck_id}/status/update/`;

  try {
    const response = await axiosClient.patch(url, {
      status
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export { addTruck, updateTruck, updateTruckStatusService };

import React from 'react';
import { useLocation } from 'react-router-dom';

// Material UI.
import { Box, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import Popup from 'components/Popup';
import {
  setDefaultPaymentMethodPopupVisible,
  setPaymentMethodPopupVisible,
  setDefaultPaymentMethod,
  setDefaultShipperCard
} from 'reducers/Payment';
import ButtonWithBackground from 'components/Form/Button';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const SetDefaultPaymentMethod = ({
  defaultPaymentMethodPopupVisible,
  setDefaultPaymentMethodPopupVisible,
  setDefaultPaymentMethod,
  defaultPaymentMethodLoading,
  paymentMethod,
  shipperDefaultCardLoading,
  setDefaultShipperCard
}) => {
  const location = useLocation();

  const userType = getUserTypeService();

  return (
    <Popup
      open={defaultPaymentMethodPopupVisible}
      onclosehandler={() => setDefaultPaymentMethodPopupVisible(false)}>
      <Box style={{ minHeight: 100 }}>
        <Typography variant="h4">PRIMARY PAYMENT METHOD</Typography>
        <Typography style={{ marginTop: 20, fontSize: 12 }}>
          This payment method will be your default payment method from now on.
        </Typography>
        <Box style={{ position: 'absolute', bottom: 0, right: 12 }}>
          <ButtonWithBackground
            label="Cancel"
            type="border"
            size="small"
            disabledOnly={
              defaultPaymentMethodLoading || shipperDefaultCardLoading
            }
            style={{
              marginRight: 8,
              borderRadius: 9,
              fontSize: 10,
              height: 30,
              width: 80
            }}
            clickHandler={() => setDefaultPaymentMethodPopupVisible(false)}
          />
          <ButtonWithBackground
            label="Confirm"
            size="small"
            style={{
              borderRadius: 9,
              fontSize: 10,
              height: 30,
              width: 80
            }}
            clickHandler={() => {
              if (userType === SHIPPER) {
                setDefaultShipperCard(paymentMethod.id, paymentMethod.customer);
              } else setDefaultPaymentMethod(paymentMethod.id);
            }}
            disabled={defaultPaymentMethodLoading || shipperDefaultCardLoading}
            loaderSize={14}
          />
        </Box>
      </Box>
    </Popup>
  );
};

const useStyles = makeStyles(theme => ({
  defaultMethod: {}
}));

const mapStateToProps = state => ({
  paymentMethodPopupVisible: state.Payment.paymentMethodPopupVisible,
  defaultPaymentMethodPopupVisible:
    state.Payment.defaultPaymentMethodPopupVisible,
  defaultPaymentMethodLoading: state.Payment.defaultPaymentMethodLoading,
  shipperDefaultCardLoading: state.Payment.shipperDefaultCardLoading
});

const mapDispatchToProps = dispatch => {
  return {
    setPaymentMethodPopupVisible: bool =>
      dispatch(setPaymentMethodPopupVisible(bool)),
    setDefaultPaymentMethodPopupVisible: bool =>
      dispatch(setDefaultPaymentMethodPopupVisible(bool)),
    setDefaultPaymentMethod: payment_mehod_id =>
      dispatch(setDefaultPaymentMethod(payment_mehod_id)),
    setDefaultShipperCard: (card_id, customer_id) =>
      dispatch(setDefaultShipperCard(card_id, customer_id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetDefaultPaymentMethod);

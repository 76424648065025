import React, { useRef, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Typography
} from '@material-ui/core';
import Header from '../Header';
import TermsOfServiceContent from './Content';
import { termsOfServiceIndexes } from 'utils/textUtils';
import { TncIndex } from 'containers/Settings';

const TermsService = () => {
  const classes = useStyles();

  const tncRefs = useRef([]);

  const [currentActiveIndex, setCurrentActiveIndex] = useState(
    termsOfServiceIndexes[0].title
  );

  const executeScroll = ref => {
    if (tncRefs.current) {
      setCurrentActiveIndex(ref);
      const y =
        tncRefs.current[ref].getBoundingClientRect().top +
        window.pageYOffset +
        -80;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      <Hidden mdUp>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 700,
            marginLeft: 35,
            color: '#6B6B6B',
            marginTop: 10
          }}>
          Terms of Service
        </Typography>
        <Typography
          style={{
            color: '#C1C1C1',
            marginLeft: 35,
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 12
          }}>
          Last Updated Sep 08, 2022
        </Typography>
      </Hidden>
      <Hidden smDown>
        <Header
          heading="Terms of Service"
          rightPart={
            <Typography style={{ fontWeight: 600 }} variant="h5">
              Last Updated Sep 08, 2022
            </Typography>
          }
        />
      </Hidden>

      <Grid container>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: '-1px' }}
          className={classes.verticalDivider}
        />
        <Hidden smDown>
          <Grid
            item
            lg={3}
            md={3}
            style={{
              padding: 12,
              height: 'calc(100vh - 90px)',
              overflow: 'auto',
              position: 'sticky',
              top: 82,
              paddingLeft: 20
            }}>
            {termsOfServiceIndexes.map((tnc, index) => (
              <TncIndex
                key={index}
                active={currentActiveIndex === tnc.title}
                clickHandler={() => executeScroll(tnc.title)}>
                {index + 1}. {tnc.title}
              </TncIndex>
            ))}
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{
              padding: 12,
              paddingLeft: 35
            }}>
            {termsOfServiceIndexes.map((tnc, index) => (
              <>
                <TncIndex
                  key={index}
                  active={currentActiveIndex === tnc.title}
                  clickHandler={() => executeScroll(tnc.title)}>
                  {index + 1}. {tnc.title}
                </TncIndex>
                <Divider style={{ marginTop: 4, marginBottom: 4 }} />
              </>
            ))}
          </Grid>
        </Hidden>

        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: '-1px' }}
          className={classes.verticalDivider}
        />

        <Grid item lg={9} md={9} style={{ padding: 20 }}>
          <Hidden smDown>
            <Typography
              style={{
                fontSize: 20,
                color: '#6B6B6B',
                fontWeight: 700,
                paddingLeft: 12
              }}>
              Terms of Service
            </Typography>
          </Hidden>
          <TermsOfServiceContent tncRefs={tncRefs} />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  verticalDivider: {
    background: '#e5e5e5',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset'
    }
  }
}));

export default TermsService;

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DownArrowIcon from 'assets/images/common/down.png';

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const BoxLayout2 = props => {
  const {
    children,
    className,
    collapse,
    title,
    infoRelative,
    color,
    hideNote,
    setHideNote,
    ...rest
  } = props;

  const userType = getUserTypeService();

  const [open, setOpen] = useState(collapse);

  const classes = useStyles({ isOpen: open, color });
  return (
    <Box className={clsx(classes.box, className)} {...rest}>
      <Box
        className={clsx(
          classes.header,
          userType === SHIPPER ? classes.background2 : classes.background
        )}
        onClick={() => {
          setOpen(!open);
          if (setHideNote) {
            setHideNote(!hideNote);
          }
        }}
      >
        <Box>
          <Typography style={{ color: 'white', fontWeight: 700, fontSize: 12 }}>
            {title}
          </Typography>
        </Box>
        <Box>
          <img
            src={DownArrowIcon}
            alt={open ? 'Open' : 'Close'}
            className={clsx(classes.icon, open ? classes.collapse : '')}
            width="16"
          />
        </Box>
      </Box>
      <Box
        className={clsx(classes.info, open ? classes.infoHide : '')}
        style={{ position: infoRelative ? 'relative' : 'initial' }}>
        {children}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    box: {
      //boxShadow: '10px 10px 15px 0px rgba(125,121,121,0.75)',
      boxShadow: '7px 7px 20px #00000033',
      borderRadius: '10px',
      background: '#fff'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 12,
      paddingBottom: 12,
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderBottomLeftRadius: props => (props.isOpen ? theme.spacing(1) : 0),
      borderBottomRightRadius: props => (props.isOpen ? theme.spacing(1) : 0)
    },
    background: {
      background: props =>
        props.color ? props.color : theme.palette.primary.dark
    },
    background2: {
      background: props =>
        props.color ? props.color : theme.palette.secondary.dark
    },
    icon: {
      transform: 'rotate(180deg)',
      transition: 'transform 0.5s',
      cursor: 'pointer'
    },
    collapse: {
      transform: 'unset'
    },
    info: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 22,
      paddingBottom: 22
    },
    infoHide: {
      display: 'none'
    }
  })
);

BoxLayout2.defaultProps = {
  collapse: false
};

BoxLayout2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  collapse: PropTypes.bool
};

export default BoxLayout2;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserTypeService } from 'services/login';
import {
    Box,
    Typography,
    makeStyles
} from '@material-ui/core';
import { getLoadsList } from 'reducers/Job';
import { SHIPPER } from 'utils/users';
import { ReactComponent as ErrorExIcon } from 'assets/images/common/error-ex.svg';
import { setPaymentUpdateVisibility} from 'reducers/User';

const PaymentUpdateDialogue = ({
    user,
    showPaymentUpdateDialogue,
    setPaymentUpdateVisibility,
    updatePaymentIssueLoading,
    paymentMethodUpdated,
    getLoadsList
}) => {
    const userType = getUserTypeService();
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (user && userType === SHIPPER) {
            if (user.payment_status === 'HAS_PAYMENT_ISSUES') {
                setPaymentUpdateVisibility(true);
            }
        }
    }, [user]);
     
    // close banner after updating payment method
    useEffect(() => {
        if (paymentMethodUpdated === true) {
            setPaymentUpdateVisibility(false);
        }
    }, [paymentMethodUpdated]);

    useEffect(() => {
        if (!updatePaymentIssueLoading && paymentMethodUpdated) {
            getLoadsList('all');
        }
    }, [paymentMethodUpdated]);
    if (
        !user ||
        userType !== SHIPPER ||
        showPaymentUpdateDialogue === false
    ) {
        return null;
    }
    return (
        <Box className={classes.container}>
            <Box style={{
                marginLeft: 20,
                display: 'flex',
                alignItems: 'center'
            }}>
                <ErrorExIcon />
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        className={classes.text}
                        display="inline"
                        style={{ marginLeft: 20 }}>
                        There was a payment issue while booking one of your loads. We have removed your loads off the load. Please verify your default                         &nbsp;
                    </Typography>
                    <div
                        onClick={() => history.push(`settings?tab=payment`)}>
                        <Typography
                            className={classes.link}>
                            payment method
                        </Typography>
                    </div>
                    <Typography
                        className={classes.text}>
                        then confirm you have fixed the issue to put your loads back on the load board.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    text: {
        color: '#9C9DC3',
        fontWeight: 600,
        fontSize: 11,
    },
    link: {
        color: '#ec3c2c',
        fontWeight: 700,
        fontSize: 11,
        textDecoration: 'underline',
        marginLeft: 2,
        marginRight: 6,
        cursor: 'pointer'
    },
    container: {
        backgroundColor: '#EFF0F4',
        zIndex: 999,
        position: 'absolute',
        width: '100%',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: 200
    }
});

const mapStateToProps = state => ({
    user: state.User.user_data,
    showPaymentUpdateDialogue: state.User.showPaymentUpdateDialogue,
    updatePaymentIssueLoading: state.User.updatePaymentIssueLoading,
    paymentMethodUpdated: state.User.paymentMethodUpdated
});

const mapDispatchToProps = dispatch => {
    return {
        setPaymentUpdateVisibility: bool => dispatch(setPaymentUpdateVisibility(bool)),
        getLoadsList: (type) => dispatch(getLoadsList(type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentUpdateDialogue);

import { makeStyles } from '@material-ui/core';
import React from 'react';
import Skeleton from '../Skeleton.js';

const CardLoading = () => {
  const classes = useStyles();
  return (
    <Skeleton
      animation="wave"
      width={250}
      height={130}
      variant="rect"
      className={classes.placeholder}
    />
  );
};

const useStyles = makeStyles(theme => ({
  placeholder: {
    minWidth: 250,
    marginRight: 12,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  }
}));

export default CardLoading;

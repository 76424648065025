import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const LineLoading = (props: any) => {
  const classes = useStyles();

  const userType = getUserTypeService();

  return (
    <LinearProgress
      {...props}
      classes={{
        colorPrimary: userType === SHIPPER? classes.colorPrimary2: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary
      }}
    />
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    colorPrimary: {
      backgroundColor: theme.palette.primary.dark
    },
    barColorPrimary: {
      backgroundColor: theme.palette.secondary.main
    },
    colorPrimary2: {
      backgroundColor: theme.palette.secondary.dark
    },
  })
);

export default LineLoading;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Backdrop, Slide, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/images/job/close.svg';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const Popup = props => {
  const {
    open,
    children,
    closeHandler,
    heading,
    nopadding,
    setMinWidth,
    borderCurve,
    onclosehandler,
    setMinHeight
  } = props;

  const userType = getUserTypeService();

  const classes = useStyles({
    userType,
    nopadding,
    setMinWidth,
    setMinHeight,
    borderCurve
  });

  return (
    <Modal
      disableEnforceFocus
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      open={open}
      className={classes.modal}
      onClose={() => {
        if (onclosehandler) onclosehandler();
      }}>
      <Slide in={open}>
        <Box className={classes.info}>
          {closeHandler && (
            <>
              <CloseIcon
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  cursor: 'pointer'
                }}
                onClick={closeHandler}
              />
              <Box style={{ marginTop: 35 }}></Box>
            </>
          )}
          {heading ? (
            <Typography variant="h4" className={classes.heading}>
              {heading}
            </Typography>
          ) : null}
          <Box className={heading ? classes.marginIfHeading : null}>
            {children}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  info: {
    background: theme.palette.secondary.main,
    padding: props => (props.nopadding ? 0 : theme.spacing(2)),
    borderRadius: props =>
      theme.spacing(props.borderCurve ? props.borderCurve : 1.5),
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minWidth: props => (props.setMinWidth ? props.setMinWidth : 400),
      minHeight: props => (props.setMinHeight ? props.setMinHeight : 200)
    }
  },
  heading: {
    color: props =>
      props.userType === SHIPPER
        ? theme.palette.secondary.dark
        : theme.palette.primary.dark,
    position: 'absolute',
    top: theme.spacing(2)
  },
  marginIfHeading: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  
}));

export default Popup;

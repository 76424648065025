import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const version = process.env.REACT_APP_API_VER;

const signupUserService = async (obj: any, role: string) => {
  try {
    const response = await axios.post(
      baseUrl + role + '/' + version + '/',
      obj
    );

    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export { signupUserService };

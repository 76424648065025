import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Header from './Header';

const ContactUs = () => {
  return (
    <>
      <Header heading="Contact us" />
      <Box className="container">
        <Typography gutterBottom>Email: michaelgrantjacobs@aol.com</Typography>
        <Typography gutterBottom>Phone: 1-845-6423927</Typography>
        <Typography gutterBottom>
          Address: 12 Joyce Dr New City, New York 10956-3904 United States
        </Typography>
      </Box>
    </>
  );
};

export default ContactUs;

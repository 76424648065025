import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '../Skeleton';

const SkeletonRow = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Skeleton
        variant="rect"
        width={80}
        style={{ margin: '0 10px', padding: '12px', borderRadius: '8px' }}
        animation="wave"
      />
      <Skeleton
        variant="rect"
        width={40}
        style={{ margin: '0 10px', padding: '12px', borderRadius: '8px' }}
        animation="wave"
      />
      <Skeleton
        variant="rect"
        width={40}
        style={{ margin: '0 10px', padding: '12px', borderRadius: '8px' }}
        animation="wave"
      />
      <Skeleton
        variant="rect"
        width={260}
        style={{ margin: '0 10px', padding: '12px', borderRadius: '8px' }}
        animation="wave"
      />
      <Box width={80} />
      <Skeleton
        variant="rect"
        width={120}
        style={{ margin: '0 10px', padding: '12px', borderRadius: '8px' }}
        animation="wave"
      />
    </Box>
  );
};

export default SkeletonRow;
import React from 'react';
import CellData from 'components/CustomTable/CellData';
import Skeleton from 'components/Skeleton/Skeleton';
import { TableRow } from '@material-ui/core';

const RowLoading = ({ columns, rowBgColor }) => {
  const showRow = () => {
    let rows = [];
    for (let i = 0; i < columns; i++) {
      rows.push(<CellData key={i} primary={<Skeleton animation="wave" />} />);
    }
    return rows;
  };

  return (
    <TableRow style={{ background: rowBgColor || '#fff' }}>
      {showRow()}
    </TableRow>
  );
};

export default RowLoading;

import React from 'react';

import projectLogo from 'assets/images/logos/rc_logomark_192px.png';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';

const Header = ({ heading, rightPart }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box
      style={{
        boxShadow: '0px 3px 6px #00000029',
        borderBottomRightRadius: 22,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 40,
        paddingRight: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        background: '#fff',
        zIndex: 1
      }}
      className={classes.header}>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <img
          width="40"
          height="40"
          alt="Logo"
          src={projectLogo}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/')}
        />

        <Typography
          style={{
            color: '#e53935',
            fontWeight: 700,
            fontSize: 20,
            marginLeft: 30
          }}>
          {heading}
        </Typography>
      </Box>
      <Box className={classes.rightPart}>{rightPart || null}</Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  rightPart: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    }
  }
}));

export default Header;

import React from 'react';

// Material UI.
import {
  Typography,
  makeStyles,
  Avatar,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

// Services.
import Skeleton from './Skeleton';

const DriverDetailLoading = () => {
  const classes = useStyles();

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Skeleton animation="wave" variant="circle" width={100} height={100} />
      <Skeleton
        animation="wave"
        width={150}
        height={30}
        style={{ marginTop: 12 }}
      />

      <Box
        style={{
          backgroundColor: '#fff',
          width: '100%',
          border: '1px solid #F0F0F0',
          borderRadius: 11,
          padding: 16,
          marginTop: 34,
          display: 'flex',
          justifyContent: 'space-around',
          textAlign: 'center',
          position: 'relative'
        }}>
        <Skeleton
          animation="wave"
          width={200}
          height={30}
          style={{ position: 'absolute', top: -16 }}
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Skeleton animation="wave" width={40} height={25} />
          <Typography style={{ color: '#919191' }}>Overdue Loads</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Skeleton animation="wave" width={40} height={25} />
          <Typography style={{ color: '#919191' }}>Open Loads</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Skeleton animation="wave" width={40} height={25} />
          <Typography style={{ color: '#919191' }}>Total trips</Typography>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: '#fff',
          width: '100%',
          border: '1px solid #F0F0F0',
          borderRadius: 11,
          padding: 16,
          marginTop: 40,
          paddingLeft: 26
        }}>
        <Table>
          <TableBody>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell style={{ borderBottom: 'unset', padding: 0 }}>
                <Typography className={classes.key}>Email</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Skeleton animation="wave" width={200} height={20} />
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingRight: 4,
                  paddingBottom: 8
                }}>
                <Typography className={classes.key}>Phone</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Skeleton animation="wave" width={140} height={20} />
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingRight: 4,
                  paddingBottom: 8
                }}>
                <Typography className={classes.key}>Truck</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Skeleton animation="wave" width={100} height={20} />
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingRight: 4,
                  paddingBottom: 8
                }}>
                <Typography className={classes.key}>Type</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Skeleton animation="wave" width={130} height={20} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  key: {
    color: '#4E4E4E',
    fontWeight: 600
  }
}));

export default DriverDetailLoading;

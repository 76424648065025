import React from 'react';
import { Typography, TableCell, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const CellData = ({ primary, secondry, className, alignment, ...rest }) => {
  const classes = useStyles();
  return (
    <TableCell
      align={alignment}
      {...rest}
      className={clsx(classes.item, className)}>
      <Typography
        className={clsx(!secondry ? classes.primary2 : classes.primary)}>
        {primary}
      </Typography>
      {secondry ? (
        <Typography className={clsx(classes.secondry)}>{secondry}</Typography>
      ) : null}
    </TableCell>
  );
};

CellData.defaultProps = {
  alignment: 'left',
  secondry: ''
};

const useStyles = makeStyles(theme => ({
  item: {
    //paddingLeft: 30,
    verticalAlign: 'top'
  },
  primary: {
    fontWeight: 700,
    fontSize: 12
  },
  primary2: {
    fontWeight: 600,
    fontSize: 12
  },
  secondry: {
    color: '#cccc',
    fontSize: 11
  }
}));

export default CellData;

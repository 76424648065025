import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Material UI.
import {
  Divider,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Badge,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button
} from '@material-ui/core';

// Services.
import { getUserTypeService } from 'services/login';
import PendingDriverIcon from 'assets/images/drivers/pending-detail.png';
import ActiveDriverIcon from 'assets/images/drivers/active-detail.png';
import DriverDetailLoading from 'components/Skeleton/DriverDetailLoading';
import {
  getDriver,
  setDriverPopupVisible,
  setEditableDriverId,
  setDeletableDriver
} from 'reducers/Driver';
import { getNameAbbreviation } from 'services/users';
import { formatUsNumber, formatUsNumber2 } from 'utils/numberUtils';
import { ReactComponent as PlusIcon } from 'assets/images/job/plus.svg';
import { capitalizeFirst } from 'utils/textUtils';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const S3_URL = process.env.REACT_APP_S3_URL;

const DriverDetail = ({
  user,
  getDriver,
  driver_loading,
  driver,
  truck_types,
  transport_types,
  setDriverPopupVisible,
  setEditableDriverId,
  setDeletableDriver
}) => {
  const userType = getUserTypeService();

  const location = useLocation();

  const classes = useStyles({ userType });

  const params = new URLSearchParams(location.search);

  const driver_id = params.get('id');

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (driver_id && !driver_loading) getDriver(driver_id);
  }, [driver_id]);

  if (!driver_id)
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 200px)',
          marginLeft:'auto'
        }}>
        <Box
          style={{ cursor: 'pointer', textAlign: 'center' }}
          onClick={setDriverPopupVisible}>
          <Typography
            style={{ marginBottom: 12, fontSize: 16, fontWeight: 600 }}>
            Add new driver
          </Typography>
          <PlusIcon />
        </Box>
      </Box>
    );

  if (driver_loading) return <DriverDetailLoading />;

  if (!driver) return null;

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight:'auto'
      }}>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setEditableDriverId(driver_id);
            setDriverPopupVisible(true);
          }}>
          <Typography variant="h6">Edit Driver</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setDeletableDriver(driver);
          }}>
          <Typography variant="h6">Delete Driver</Typography>
        </MenuItem>
      </Menu>
      <Button
        onClick={e => setAnchorEl(e.currentTarget)}
        disableRipple
        style={{
          background: 'transparent',
          padding: 0,
          minWidth: 0,
          position: 'absolute',
          top: 10,
          right:10
        }}>
        <MoreVertIcon style={{ color: '#ccc', cursor: 'pointer' }} />
      </Button>
      {driver.profile_pic_url ? (
        <Avatar
          style={{ background: '#E8A69D', width: 100, height: 100 }}
          src={S3_URL + driver.profile_pic_url}
        />
      ) : (
        <Avatar
          style={{
            background: '#E8A69D',
            fontSize: 24,
            fontWeight: 700,
            width: 100,
            height: 100
          }}>
          {getNameAbbreviation(driver.first_name, driver.last_name)}
        </Avatar>
      )}
      <Typography
        style={{
          color: '#4E4E4E',
          fontWeight: 600,
          marginTop: 12
        }}
        variant="h4">
        {capitalizeFirst(`${driver.first_name} ${driver.last_name}`)}
      </Typography>

      <Box
        style={{
          backgroundColor: '#fff',
          width: '100%',
          border: '1px solid #F0F0F0',
          borderRadius: 11,
          padding: 16,
          marginTop: 40,
          display: 'flex',
          justifyContent: 'space-around',
          textAlign: 'center',
          position: 'relative'
        }}>
        {driver.new_user === true ? (
          <img
            src={PendingDriverIcon}
            height="22px"
            style={{ position: 'absolute', top: -12 }}
          />
        ) : (
          <img
            src={ActiveDriverIcon}
            height="22px"
            style={{ position: 'absolute', top: -12 }}
          />
        )}
        <Box>
          <Typography
            style={{ color: '#919191', fontWeight: 700 }}
            variant="h4">
            {driver.overdue_count}
          </Typography>
          <Typography style={{ color: '#919191' }}>Overdue Loads</Typography>
        </Box>
        <Box>
          <Typography
            style={{ color: '#919191', fontWeight: 700 }}
            variant="h4">
            {driver.open_count}
          </Typography>
          <Typography style={{ color: '#919191' }}>Open Loads</Typography>
        </Box>
        <Box>
          <Typography
            style={{ color: '#919191', fontWeight: 700 }}
            variant="h4">
            {driver.delivered_count}
          </Typography>
          <Typography style={{ color: '#919191' }}>Total trips</Typography>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: '#fff',
          width: '100%',
          border: '1px solid #F0F0F0',
          borderRadius: 11,
          padding: 16,
          marginTop: 40,
          paddingLeft: 26
        }}>
        <Table>
          <TableBody>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell style={{ borderBottom: 'unset', padding: 0 }}>
                <Typography className={classes.key}>Email</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Typography display="inline" style={{ color: '#919191' }}>
                  {driver.email}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingRight: 4,
                  paddingBottom: 8
                }}>
                <Typography className={classes.key}>Phone</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Typography display="inline" style={{ color: '#919191' }}>
                  {formatUsNumber(driver.phone)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingRight: 4,
                  paddingBottom: 8
                }}>
                <Typography className={classes.key}>Truck</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Typography display="inline" style={{ color: '#919191' }}>
                  {
                    truck_types.find(
                      type => type.truck_type === driver.truck_type
                    )?.display_name
                  }
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'baseline' }}>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingRight: 4,
                  paddingBottom: 8
                }}>
                <Typography className={classes.key}>Type</Typography>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'unset',
                  padding: 0,
                  paddingLeft: 4,
                  paddingBottom: 8
                }}>
                <Typography display="inline" style={{ color: '#919191' }}>
                  {
                    transport_types.find(
                      type => type.name === driver.transport_type
                    )?.display_name
                  }
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  key: {
    color: '#4E4E4E',
    fontWeight: 600
  }
}));

const mapStateToProps = state => {
  return {
    user: state.User.user_data,
    truck_types: state.Truck.truck_types,
    driver_loading: state.Driver.driver_loading,
    driver: state.Driver.driver,
    transport_types: state.Truck.transport_types
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDriver: driver_id => dispatch(getDriver(driver_id)),
    setDriverPopupVisible: visibility =>
      dispatch(setDriverPopupVisible(visibility)),
    setEditableDriverId: driver_id => dispatch(setEditableDriverId(driver_id)),
    setDeletableDriver: driver => dispatch(setDeletableDriver(driver))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetail);

import React from 'react';
import { useLocation } from 'react-router-dom';

// Material UI.
import { Divider, Grid, makeStyles } from '@material-ui/core';

// Components.
import Tabs from './Tabs/Tabs';
import clsx from 'clsx';

const MenuWrapper = ({ children }) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const currentTab = params.get('tab');

  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        item
        lg={3}
        md={3}
        sm={12}
        xs={12}
        className={classes.section}
        style={{ backgroundColor: '#fff' }}>
        <Tabs
          currentTab={currentTab || 'account'}
          className={clsx(classes.tabs, currentTab ? classes.tabInfoHide : '')}
        />
      </Grid>

      <Divider
        orientation="vertical"
        flexItem
        style={{ marginRight: '-1px' }}
        className={classes.verticalDivider}
      />

      {children}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  tabs: {
    position: 'sticky',
    top: 74,
    paddingTop: 0
  },
  verticalDivider: {
    background: '#e5e5e5',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset'
    }
  },
  section: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  section2: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  tabInfoHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default MenuWrapper;

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, makeStyles, createStyles, Typography } from '@material-ui/core';

const UnAvailableInBetaTooltip = ({ children }) => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.betaToolTip, arrow: classes.customArrow }}
      title={
        <Typography
          style={{
            color: '#6B6B6B',
            fontWeight: 600,
            fontSize: 12
          }}>
          {/* Placing bids and booking loads
          <Box lineHeight={1} m={0.5} />
          is unavailable in Beta. Sit tight! */}
          Coming Soon
        </Typography>
      }
      placement="top"
      arrow>
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    betaToolTip: {
      padding: 20,
      margin: 0,
      width: 'fit-content',
      height: 'fit-content',
      color: '#6B6B6B',
      borderRadius: 11,
      textAlign: 'center',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 15px #00000033',
      border: '1px solid #00000000',
      opacity: 1
    },
    customArrow: {
      color: '#FFFFFF'
    }
  })
);

export default UnAvailableInBetaTooltip;

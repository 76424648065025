// Core
import React, { lazy, Suspense, useState} from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

// Material UI
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from 'theme';

import { LeftSidebar, PresentationLayout } from 'layout';

// Containers
import Login from 'containers/Login/LoginTab';
import Register from 'containers/Login/SignupTab';
import EmailVerification from 'containers/Login/EmailVerification';
import ForgetPassword from 'containers/Login/ForgetPassword';

// Components
import Snackbar from 'components/Snackbar';
import FullPageLoader from 'components/FullPageLoader';
import Notfound from 'components/NotFound';
import PrivacyPolicy from 'containers/PublicPage/PrivacyPolicy/PrivacyPolicy';
import BillOfLading from 'containers/PublicPage/BillOfLading/BillOfLading';
import ContactUs from 'containers/PublicPage/ContactUs';
import AppReturnPage from 'containers/PublicPage/App/ReturnPage';
import AppRefreshPage from 'containers/PublicPage/App/RefreshPage';
import PayoutAction from 'containers/Settings/TabInfo/Payout/PayoutAction';
// Services
import { getUserTypeService } from 'services/login';

// Utils
import { ADMIN, BROKER, CARRIER, SHIPPER } from 'utils/users';
import {
  LOGIN_PAGE,
  SIGNUP_PAGE,
  VERIFY_PAGE,
  DASHBOARD_PAGE,
  ONBOARDING_PAGE,
  SEARCH_PAGE,
  NOT_FOUND,
  POST_JOB_PAGE,
  TRUCKS_PAGE,
  INBOX_PAGE,
  ADMIN_SHIPPER_VERIFICATION,
  ADMIN_CARRIER_VERIFICATION,
  SHIPMENTS_ALL,
  LOADS,
  
  TRUCKS_ADD_PAGE,
  FORGET_PASS_PAGE,
  PRIVACY_POLICY_PAGE,
  TERMS_OF_SERVICE_PAGE,
  SETTINGS_PAGE,
  TRUCKS_EDIT_PAGE,
  JOB_COPY_PAGE,
  JOB_EDIT_PAGE,
  CARRIERS_PAGE,
  SHIPPERS_PAGE,
  USER_DETAIL_PAGE,
  APP_RETURN_PAGE,
  APP_REFRESH_PAGE,
  BROKERS_PAGE,
  SAVED_LOADS_PAGE,
  My_OFFERS_PAGE,
  CONTACT_US_PAGE,
  BOL_PAGE,
  PAYMENT_METHOD_PAGE,
  USERS,
  ADMINLOADS,
  DRIVERS_PAGE,
  CONTACT_BOOK,
  INVOICES,
  SEND_INVOICE,
  DELETE_ACCOUNT,
  PAYOUT,
  PAYOUT_ONBOARDING,
  PAYOUT_AUTH
} from 'utils/routesUtils';
import AddTruck from 'containers/Trucks/AddTruck';
import TermsService from 'containers/PublicPage/TermsService/TermsService';
import PaymentAuth from 'containers/PublicPage/Auth/Payment/Payment';
import PayoutAuth from 'containers/PublicPage/Auth/Payout/PayoutAuth';

// Routes
const Dashboard = lazy(() => import('containers/Dashboard'));
const VerificationTable = lazy(() => import('containers/Verification'));
const Shipments = lazy(() => import('containers/Shipments'));
const Onboarding = lazy(() => import('containers/Onboarding'));
const Search = lazy(() => import('containers/Search'));
const MyOffers = lazy(() => import('containers/MyOffers/OfferTable'));
const PostJob = lazy(() => import('containers/PostJob'));
const Trucks = lazy(() => import('containers/Trucks'));
const Inbox = lazy(() => import('containers/Inbox'));
const Loads = lazy(() => import('containers/Loads'));
const DeleteAccount = lazy(() => import('containers/DeleteUserAccount'));
const AdminLoads =  lazy(() => import('containers/Loads/AdminLoads'));
const Settings = lazy(() => import('containers/Settings'));
const UserDetail = lazy(() => import('containers/Users/Details/Detail'));
const SavedLoads = lazy(() => import('containers/SavedLoads/SavedLoads'));
const UserManagement = lazy(() => import('containers/Users'));
const  Drivers = lazy(() => import('containers/Settings/TabInfo/Driver'));
const  ContactBook = lazy(() => import('containers/ContactBook'));
const Invoices = lazy(() => import('containers/Invoices'))
const PayoutOnboarding = lazy(() => import('containers/Settings/TabInfo/Payout/PayoutOnboarding'))

const Routes = () => {
  const location = useLocation();

  const snackbarDataInitial = null;

  const [snackbar, setSnackbar] = useState(snackbarDataInitial);
  
  // Authenticated routes.
  let authRoutes = [DASHBOARD_PAGE];

  // Public routes.
  const publicRoutes = [
    LOGIN_PAGE,
    SIGNUP_PAGE,
    VERIFY_PAGE,
    FORGET_PASS_PAGE,
    PRIVACY_POLICY_PAGE,
    TERMS_OF_SERVICE_PAGE,
    CONTACT_US_PAGE,
    APP_RETURN_PAGE,
    APP_REFRESH_PAGE,
    BOL_PAGE,
    PAYMENT_METHOD_PAGE,
    DELETE_ACCOUNT,
    PAYOUT,
    PAYOUT_ONBOARDING,
    PAYOUT_AUTH
   ];

  const userType = getUserTypeService();

  if (userType === CARRIER) {
    authRoutes = [
      DASHBOARD_PAGE,
      SEARCH_PAGE,
      INBOX_PAGE,
      LOADS,
      DRIVERS_PAGE,
      POST_JOB_PAGE,
      JOB_COPY_PAGE,
      JOB_EDIT_PAGE,
      TRUCKS_PAGE,
      TRUCKS_ADD_PAGE,
      TRUCKS_EDIT_PAGE,
      SETTINGS_PAGE,
      SAVED_LOADS_PAGE,
      My_OFFERS_PAGE,
      CONTACT_BOOK,
      DELETE_ACCOUNT,
      INVOICES,
 ];
  // } else if (userType === SHIPPER) {
  //   authRoutes = [
  //     DASHBOARD_PAGE,
  //     POST_JOB_PAGE,
  //     JOB_COPY_PAGE,
  //     JOB_EDIT_PAGE,
  //     TRUCKS_PAGE,
  //     INBOX_PAGE,
  //     LOADS,
  //     SETTINGS_PAGE
  //   ];
  // } else if (userType === BROKER) {
  //   authRoutes = [
  //     DASHBOARD_PAGE,

  } else if (userType === SHIPPER) {
    authRoutes = [
      DASHBOARD_PAGE,
      POST_JOB_PAGE,
      JOB_COPY_PAGE,
      JOB_EDIT_PAGE,
      TRUCKS_PAGE,
      INBOX_PAGE,
      LOADS,
      SETTINGS_PAGE
    ];
   }
  //  else if (userType === BROKER) {
  //    authRoutes = [
  //      DASHBOARD_PAGE,
  //     SEARCH_PAGE,
  //    INBOX_PAGE,
  //     POST_JOB_PAGE,
  //      JOB_COPY_PAGE,
  //     JOB_EDIT_PAGE,
  //      TRUCKS_PAGE,
  //      LOADS,
  //      SETTINGS_PAGE,
  //     SAVED_LOADS_PAGE
  //    ];
  // }
   else if (userType === ADMIN) {
    authRoutes = [
      DASHBOARD_PAGE,
      ADMIN_SHIPPER_VERIFICATION,
      ADMIN_CARRIER_VERIFICATION,
      SHIPMENTS_ALL,
      CARRIERS_PAGE,
      SHIPPERS_PAGE,
      BROKERS_PAGE,
      USER_DETAIL_PAGE,
      USERS,
      LOADS,
      ADMINLOADS
    ];
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <Snackbar
        data={snackbar}
        onClose={() => setSnackbar(snackbarDataInitial)}
      />
      <AnimatePresence>
        <Suspense fallback={<FullPageLoader />}>
          <Switch>
            <Redirect exact from="/" to={DASHBOARD_PAGE} />
            <Route path={publicRoutes}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <Route
                    path={LOGIN_PAGE}
                    render={() => (
                      <Login setSnackbar={setSnackbar} tab="Signin" />
                    )}
                  />
                   <Route
                    path={PAYOUT_AUTH}
                    render={() => <PayoutAuth setSnackbar={setSnackbar} />}
                  />
                    <Route
                     path={DELETE_ACCOUNT}
                      render={() => <DeleteAccount/>}
                    />
                  <Route
                    path={PRIVACY_POLICY_PAGE}
                    render={() => <PrivacyPolicy />}
                  />
                  <Route
                    path={PAYMENT_METHOD_PAGE}
                    render={() => <PaymentAuth setSnackbar={setSnackbar} />}
                  />
                  <Route
                    path={TERMS_OF_SERVICE_PAGE}
                    render={() => <TermsService />}
                  />
                  <Route
                    path={BOL_PAGE}
                    render={() => <BillOfLading setSnackbar={setSnackbar} />}
                  />
                  <Route path={CONTACT_US_PAGE} render={() => <ContactUs />} />
                  <Route
                    path={SIGNUP_PAGE}
                    render={() => (
                      <Register setSnackbar={setSnackbar} tab="Signup" />
                    )}
                  />
              
                  <Route
                    path={VERIFY_PAGE}
                    render={() => (
                      <EmailVerification
                        setSnackbar={setSnackbar}
                        tab="Verify"
                      />
                    )}
                  />
                  <Route
                    path={FORGET_PASS_PAGE}
                    render={() => (
                      <ForgetPassword setSnackbar={setSnackbar} tab="Verify" />
                    )}
                  />
                  <Route
                    path={APP_RETURN_PAGE}
                    exact
                    render={() => <AppReturnPage />}
                  />
                  <Route
                    path={APP_REFRESH_PAGE}
                    exact
                    render={() => <AppRefreshPage />}
                  />
                  <Route
                    path={PAYOUT_ONBOARDING}
                    render={() => <PayoutOnboarding setSnackbar={setSnackbar} />}
                  />
                    <Route
                      path={PAYOUT}
                      render={() => <PayoutAction/>}
                    />
                </Switch>
              </PresentationLayout>
            </Route>

            <Route path={authRoutes}>
              <LeftSidebar setSnackbar={setSnackbar}>
                <Switch location={location} key={location.pathname}>
                  <motion.div initial="initial" animate="in" exit="out">
                    <Route path={DASHBOARD_PAGE} render={() => <Dashboard />} />
                    <Route path={USERS} render={() => <UserManagement />} />
                    <Route
                      path={SEARCH_PAGE}
                      render={() => <Search setSnackbar={setSnackbar} />}
                    />
                     <Route
                      path={INBOX_PAGE}
                      render={() => <Inbox setSnackbar={setSnackbar} />}
                    />
                    {/* disable trucks route for now */}
                    {/* <Route
                      path={TRUCKS_ADD_PAGE}
                      exact
                      render={() => <AddTruck setSnackbar={setSnackbar} />}
                    />
                    <Route
                      path={TRUCKS_EDIT_PAGE}
                      exact
                      render={() => <AddTruck setSnackbar={setSnackbar} />}
                    />
                    <Route
                      path={TRUCKS_PAGE}
                      exact
                      render={() => <Trucks setSnackbar={setSnackbar} />}
                    />  */}
                    <Route
                      path={POST_JOB_PAGE}
                      render={() => <PostJob setSnackbar={setSnackbar} />}
                    />
                    <Route
                      path={JOB_COPY_PAGE}
                      exact
                      render={() => <PostJob setSnackbar={setSnackbar} />}
                    />
                    <Route
                      path={JOB_EDIT_PAGE}
                      exact
                      render={() => <PostJob setSnackbar={setSnackbar} />}
                    />
                    
                    <Route
                      path={SAVED_LOADS_PAGE}
                      render={() => (
                        <Search
                          setSnackbar={setSnackbar}
                          isSavedLoadsView={true}
                        />
                      )}
                    /> 
                    <Route
                      path={My_OFFERS_PAGE}
                      render={() => (
                        <MyOffers
                          setSnackbar={setSnackbar}
                        />
                      )}
                    /> 
                    <Route
                      path={SETTINGS_PAGE}
                      render={() => <Settings setSnackbar={setSnackbar} />}
                    />
                    <Route
                      path="/Verification/Shipper"
                      render={() => <VerificationTable type={'shipper'} />}
                    />
                    <Route
                      path="/Verification/Carrier"
                      render={() => <VerificationTable type={'carrier'} />}
                    />
                    <Route
                      path="/Shipments/:stage/"
                      render={() => <Shipments setSnackbar={setSnackbar} />}
                    />
                    <Route
                      path={LOADS}
                      exact
                      render={() => <Loads setSnackbar={setSnackbar} />}
                    />
                     <Route
                      path={DRIVERS_PAGE}
                      exact
                      render={() => <Drivers setSnackbar={setSnackbar} />}
                      />
                    <Route
                      path={ADMINLOADS}
                      exact
                      render={() => <AdminLoads />}
                    />
                     <Route
                      path={CONTACT_BOOK}
                      exact
                      render={() => <ContactBook />}
                    />
                  
                    <Route
                      path={INVOICES}
                      exact
                      render={() => <Invoices />}
                    />
                    
                    <Route
                      path={USER_DETAIL_PAGE}
                      render={() => <UserDetail setSnackbar={setSnackbar} />}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={ONBOARDING_PAGE}
              render={() => <Onboarding setSnackbar={setSnackbar} />}
            />
            <Route path={NOT_FOUND} component={Notfound} />
            <Redirect to={NOT_FOUND} />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;

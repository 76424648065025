import axios from 'axios';
import axiosClient from 'utils/axiosClient';

const version = process.env.REACT_APP_API_VER;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const uploadFileService = async (
  file_name: string,
  s3_dir_name: string,
  file: any
) => {
  let url = `/core/s3_url/${version}/?files=${file_name}&dir=${s3_dir_name}&`;

  try {
    const signedUrlRes: any = await axiosClient.get(url);
    const signedUrlData = signedUrlRes.data.url;

    const form = new FormData();
    form.append('key', signedUrlData.fields.key);
    form.append('AWSAccessKeyId', signedUrlData.fields.AWSAccessKeyId);
    form.append('policy', signedUrlData.fields.policy);
    form.append('signature', signedUrlData.fields.signature);
    form.append('file', file);

    const options: any = {
      method: 'POST',
      url: signedUrlData.url,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: form
    };

    await axios.request(options);

    return signedUrlData.fields.key;
  } catch (error) {
    throw error.response;
  }
};

const updateUserMediaService = async (
  s3_bucket_url: string,
  user_id: string,
  user_type: string
) => {
  let url = `/${user_type}/media/${version}/`;
  try {
    const response = await axiosClient.post(url, {
      s3_bucket_url,
      [user_type]: user_id
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getPrivacyPolicyService = async () => {
  let url = baseUrl + 'core/' + version + '/info/';
  try {
    const response = await axios.get(url);
    return response.data.privacy_policy;
  } catch (error) {
    throw error.response;
  }
};

const validFileTypes = () => {
  return ['.jpeg', '.jpg', '.png', '.pdf'];
};

const getFileExtension = (file: string = '') => {
  return file.split('.').pop();
};

const isFileImage = (type: string) => {
  return type.includes('image');
};

const getShipmentMedia = async (url: string,shipment_id:string) => {
  const response = await axios.get(
    `${baseUrl}core/s3_media_url/${version}/?media_url=${url}`,
     {
      params: {
        shipment_id: shipment_id
      }
    }
  );

  window.open(response.data.media_url, '_blank');
};

const reportBug = async (
  email: string,
  operating_system: string,
  os_version_number: string,
  browser: string,
  app_version_number: string,
  issue: string,
  images_url: string[]
) => {
  let url = `/dashboard/${version}/bugs/`;
  try {
    let payload: any = {
      email,
      feedback_type: 'others',
      operating_system,
      os_version_number,
      browser,
      app_version_number,
      issue,
      platform: 'web',
      status: 'new',
    };

    if (images_url.length > 0) {
      const modifiedUrls = images_url.map(url => `https://roadcall-media-dev.s3.amazonaws.com/${url}`);
      payload.images_url = modifiedUrls;
    }

    const response = await axiosClient.post(url, payload);
    return response.data;
  }
  catch (error) {
    throw error.response;
  }
};

export {
  uploadFileService,
  updateUserMediaService,
  validFileTypes,
  isFileImage,
  getFileExtension,
  getShipmentMedia,
  getPrivacyPolicyService,
  reportBug
};

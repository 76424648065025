import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ClimbingBoxLoader } from 'react-spinners';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  })
);

export default function CustomBackdrop(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.backdrop}>
      <ClimbingBoxLoader color={'#5383ff'} loading={true} />
      {/* <div className="text-muted font-size-xl text-center pt-3">
        Loading please wait
      </div> */}
    </div>
  );
}

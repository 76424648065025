import React from 'react';

const AppRefreshPage = () => (
  <div
    style={{
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <h3>
      <a href={'roadcall://com.roadcall.co/payment/refresh_url'}>Refresh</a>
    </h3>
  </div>
);

export default AppRefreshPage;

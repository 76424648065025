import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI.
import {
  Divider,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Badge,
  Box,
  Grid
} from '@material-ui/core';

// Reducers.
import { setActiveThread } from 'reducers/Inbox';

// Services.
import { getUserIdService, getUserTypeService } from 'services/login';

// Utils.
import {  SETTINGS_PAGE } from 'utils/routesUtils';
import { FILE } from 'utils/constants';
import clsx from 'clsx';
import moment from 'moment';
import { SHIPPER } from 'utils/users';
import PendingDriverIcon from 'assets/images/drivers/pending.png';
import DriverListLoading from 'components/Skeleton/DriverListLoading';
import { getDrivers } from 'reducers/Driver';
import { getNameAbbreviation } from 'services/users';
import withScroll from 'components/HOC/withScroll';
import DriverSingleLoading from 'components/Skeleton/DriverSingleLoading';
import LineLoading from 'components/loader/LineLoader';

const S3_URL = process.env.REACT_APP_S3_URL;

const Drivers = ({
  loadNext,
  drivers_loading,
  data,
  truck_types,
  handleScroll,
  nextLoading
}) => {
  const userType = getUserTypeService();

  const history = useHistory();

  const classes = useStyles({ userType });

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const driver_id = params.get('id');

  useEffect(() => {
    loadNext();
  }, []);

  if (drivers_loading) return <DriverListLoading />;

  if (!data) return null;
  if (data.results.length === 0) return null;

  return (
    <>
      <Box className={classes.container}
        onScroll={handleScroll}>
        {data.results.map(driver => {
          return (
            <Box key={driver.trucker_id}>
              <ListItem
                style={{ paddingLeft: 12, paddingRight: 12 }}
                alignItems="flex-start"
                button
                disableRipple
                onClick={() => {
                  history.push({
                    pathname: SETTINGS_PAGE,
                    search: `?tab=drivers&id=${driver.trucker_id}`
                  });
                }}
                className={clsx(classes.channel)}
                selected={driver_id === driver.trucker_id}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      paddingRight: 0,
                      justifyContent: 'space-between',
                      width: '85%'
                    }}>
                    <ListItemAvatar
                      style={{
                        marginTop: 0,
                        minWidth: 'unset',
                        marginRight: 12,
                        borderRadius: '50%',
                         border: `3px solid ${driver.is_active_driver ? '#30C13A' : '#E8E8E8'}`,

                      }}>
                      {driver.profile_pic_url ? (
                        <Avatar
                          style={{ background: '#E8A69D',
                          border: '2px solid #FFFFFF'


                        }}
                          src={ S3_URL + driver.profile_pic_url}
                        />
                      ) : (
                        <Avatar
                          style={{
                            background: '#E8A69D',
                            fontSize: 14,
                            fontWeight: 700,
                            border: '2px solid #FFFFFF',
                          }}>
                          {getNameAbbreviation(
                            driver.first_name,
                            driver.last_name
                          )}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.lastMessage}
                      primary={
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          <Typography
                            display="inline"
                            style={{
                              color: '#4E4E4E',
                              fontWeight: 600,
                              textTransform: 'capitalize',
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                            {`${driver.first_name} ${driver.last_name}`}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"></Typography>
                          {
                            truck_types.find(
                              type => type.truck_type === driver.truck_type
                            )?.display_name
                          }
                        </Box>
                      }
                    />
                  </Box>

                  <Box
                    style={{
                      width: '15%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    {driver.new_user === true ? (
                      <img src={PendingDriverIcon} width="16px" height="16px" />
                    ) : null}
                  </Box>
                </Box>
              </ListItem>
              <Divider
                orientation="horizontal"
                style={{ background: '#e4e5e4' }}
              />
            </Box>
          );
        })}
        {nextLoading ? <LineLoading /> : null}
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginRight: '-1px' }}
        className={classes.verticalDivider}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  verticalDivider: {
    background: '#e5e5e5',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset'
    }
  },
  container:{
      padding: 0,
      width:400,
      height: 'calc(100vh - 90px)',
      overflow: 'auto',
      position: 'sticky',
      top: 82,
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  lastMessage: {
    '& p': {
      //overflowWrap: 'anywhere',
      lineBreak: 'anywhere',
      lineHeight: '1em',
      height: '1.7em',
      overflow: 'hidden'
    }
  },
  channel: {
    // background: '#fff',
    // marginBottom: 8,
    // borderRadius: 11,
    padding: 0
    // '&.activeChannel p': {
    //   color: '#fff !important'
    // },
    // '&.activeChannel': {
    //   background: props => (props.userType === SHIPPER ? '#24b6b9' : '#e53835')
    // }
  }
}));

const mapStateToProps = state => {
  return {
    truck_types: state.Truck.truck_types,
    data: state.Driver.drivers,
    drivers_loading: state.Driver.drivers_loading,
    nextLoading: state.Driver.drivers_next_loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadNext: nextUrl => dispatch(getDrivers(nextUrl))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(Drivers));

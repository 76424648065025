import axiosClient from 'utils/axiosClient';
import { errorMessageSingleLine } from 'utils/errorUtils';

const version = process.env.REACT_APP_API_VER;

const SET_ACTIVE_THREAD = 'set_active_thread';
const SET_ACTIVE_TAB = 'set_active_tab';
const SET_NOTIFICATIONS_LOADING = 'set_notifications_loading';
const SET_NOTIFICATIONS = 'set_notifications';
const SET_ERROR = 'set_error';
const SET_READ_NOTIFICATION = 'set_read_notification';
const SET_TOKEN = 'SET_TOKEN';
const SET_CLIENT = 'SET_CLIENT';
const SET_CONVO = 'SET_CONVO';
const SET_CURRENT_CONVO = 'SET_CURRENT_CONVO';
const SET_MESSAGES = 'SET_MESSAGES';
const SET_LAST_MSG = 'SET_LAST_MSG';
const SET_TWILIO_STATUS = 'SET_TWILIO_STATUS';
const UNSET_MESSAGES = 'UNSET_MESSAGES';
const SET_FCMTOKEN = 'SET_FCMTOKEN';
const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
const SET_PUSH_MESSAGE = 'SET_PUSH_MESSAGE';

//initial state
const initialState = {
  twilioStatus: '',
  fcmToken: '',
  token: '',
  client: null,
  convos: [],
  currentConvo: null,
  messages: null,
  activeThread: null,
  activeTab: 'messages',
  notifications: null,
  notifications_loading: false,
  lastMsg: [],
  newMessage: null
};

export const pushNewMessage = () => {
  return async dispatch => {
    dispatch({
      type: SET_PUSH_MESSAGE
    });
  };
};

export const setNewMessage = message => {
  return async dispatch => {
    dispatch({
      type: SET_NEW_MESSAGE,
      data: message
    });
  };
};

export const setFcmToken = token => {
  return async dispatch => {
    dispatch({
      type: SET_FCMTOKEN,
      data: token
    });
  };
};

export const unsetMessages = () => {
  return async dispatch => {
    dispatch({
      type: UNSET_MESSAGES
    });
  };
};

export const setTwilioStatus = status => {
  return async dispatch => {
    dispatch({
      type: SET_TWILIO_STATUS,
      data: status
    });
  };
};

export const setActiveThread = thread => {
  return async dispatch => {
    dispatch({
      type: SET_ACTIVE_THREAD,
      data: thread
    });
  };
};

export const setActiveTab = tab => {
  return async dispatch => {
    dispatch({
      type: SET_ACTIVE_TAB,
      data: tab
    });
  };
};

export const getNotifications = params => {
  return async dispatch => {
    dispatch({
      type: SET_NOTIFICATIONS_LOADING
    });
    try {
      const response = await axiosClient.get(
        `/push_notifications/notifications/${version}/`,
        { params }
      );

      dispatch({
        type: SET_NOTIFICATIONS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.message)
      });
    }
  };
};

export const markNotificationAsRead = notification_id => {
  return dispatch => {
    axiosClient.patch(
      `/push_notifications/notifications/${notification_id}/${version}/read-status/update/`,
      {
        is_read: true
      }
    );

    dispatch({
      type: SET_READ_NOTIFICATION,
      data: notification_id
    });
  };
};

export const getToken = () => {
  return async dispatch => {
    try {
      const response = await axiosClient.get(`/chat/get_token/${version}/`);

      dispatch({
        type: SET_TOKEN,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.message)
      });
    }
  };
};

export const setClient = client => {
  return async dispatch => {
    dispatch({
      type: SET_CLIENT,
      data: client
    });
  };
};

export const setConvo = convo => {
  return async dispatch => {
    dispatch({
      type: SET_CONVO,
      data: convo
    });
  };
};

export const setCurrentConvo = convo => {
  return async dispatch => {
    dispatch({
      type: SET_CURRENT_CONVO,
      data: convo
    });
  };
};

export const setMessages = messages => {
  return async dispatch => {
    dispatch({
      type: SET_MESSAGES,
      data: messages
    });
  };
};

export const setLastMsgs = (data, toBeRemoved = false) => {
  return async dispatch => {
    dispatch({
      type: SET_LAST_MSG,
      data: { data, toBeRemoved }
    });
  };
};

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FCMTOKEN:
      return {
        ...state,
        fcmToken: action.data
      };
    case SET_NEW_MESSAGE:
      return {
        ...state,
        newMessage: action.data
      };
    case SET_PUSH_MESSAGE:
      let updated = [...state.messages.items];
      updated.push(state.newMessage);
      const updatedMessages = {
        ...state.messages,
        items: updated
      };
      return {
        ...state,
        messages: updatedMessages,
        newMessage: null
      };
    case UNSET_MESSAGES:
      return {
        ...state,
        messages: null
      };
    case SET_TWILIO_STATUS:
      return {
        ...state,
        twilioStatus: action.data
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.data.token
      };
    case SET_CLIENT:
      return {
        ...state,
        client: action.data
      };
    case SET_CONVO:
      let currentConvos = state.convos;

      currentConvos = currentConvos.filter(
        item => item.sid !== action.data.sid
      );

      return {
        ...state,
        convos: [...currentConvos, action.data]
      };
    case SET_CURRENT_CONVO:
      return {
        ...state,
        currentConvo: action.data
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.data
      };
    case SET_LAST_MSG:
      let currentLastMesages = state.lastMsg;

      if (action.data.toBeRemoved === true) {
        currentLastMesages = currentLastMesages.filter(
          item => item.convoId !== action.data.data.convoId
        );
      }

      const updatedLastMst = [...currentLastMesages, action.data.data];
      return {
        ...state,
        lastMsg: updatedLastMst
      };
    case SET_ACTIVE_THREAD:
      return {
        ...state,
        activeThread: action.data
      };
    case SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        error: '',
        notifications_loading: true
      };
    case SET_READ_NOTIFICATION:
      let notifications = state.notifications;
      let notification_id = action.data;

      let updatedNotifications = notifications.results.filter(n => {
        if (n.notification_id === notification_id) n.is_read = true;
        return n;
      });

      return {
        ...state,
        notifications: {
          ...state.notifications,
          results: updatedNotifications
        }
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications_loading: false,
        notifications: action.data
      };
    case SET_ERROR:
      return {
        ...state,
        notifications_loading: false,
        error: action.data
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.data
      };
    default:
      return state;
  }
}

import React from 'react'
import { useLocation } from 'react-router-dom';
import Tabs from '../../Tabs/Tabs';
import clsx from 'clsx';
import PayoutAction from './PayoutAction';
import {
  Box,
  makeStyles,
  Divider
} from '@material-ui/core';

const Payout = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentTab = params.get('tab');
  return (
    <Box spacing={3} className={classes.container}>
      <Box className={classes.section}>
        <Tabs
          currentTab={currentTab || 'account'}
          className={clsx(
            classes.tabs,
            currentTab ? classes.tabInfoHide : ''
          )}
        />
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        style={{
          marginRight: '-1px',
        }}
        className={classes.verticalDivider}
      />
      <Box className={classes.section2}>
        <PayoutAction />
      </Box>
    </Box>
  )
}
const useStyles = makeStyles(theme => ({
  maincontainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  section: {
    width: '25%',
    minWidth: 350,
    marginRight: 20,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      width: '100%'
    }
  },
  section2: {
    marginTop: theme.spacing(1),
    width: '75%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: -20
    }
  },
  verticalDivider: {
    color: '#e5e5e5',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset'
    }
  },
}))

export default Payout

import React, { useState } from 'react';
import LogoRed from 'assets/images/logos/logo-red-left-top.png';
import LogoGreen from 'assets/images/logos/logo-circle.png';

// Utils
import { getRandomBetween } from 'utils/util';
import { GREEN } from 'utils/constants';

const MarkerIcon = ({ type, mouseLocation }) => {
  const [transform] = useState(getRandomBetween(1, 360));

  if (type === GREEN) return <img alt="Green" src={LogoGreen} />;

  return (
    <img
      src={LogoRed}
      alt="Red"
      ref={el => {
        if (!el || !(mouseLocation && mouseLocation.y && mouseLocation.x))
          return;

        let radians = Math.atan2(
          el.getBoundingClientRect().top - mouseLocation.y,
          el.getBoundingClientRect().left - mouseLocation.x
        );
        let degree = radians * (180 / Math.PI) * 1 + 320;

        el.style.transform = `rotate(${degree}deg)`;
      }}
      style={{ transform: `rotate(${transform}deg)`, zIndex: 999 }}
    />
  );
};

export default MarkerIcon;

import axiosClient from 'utils/axiosClient';
import { SHIPPER, CARRIER, INDIVIDUAL, BUSINESS, BROKER } from 'utils/users';
import { getUserTypeService, getUserIdService } from 'services/login';
import {
  PLATFORM,
  PREFIX_CARRIER,
  PREFIX_SHIPPER,
  PREFIX_TRANSACTION,
  ROADCALL_USER_DOC_VER_DIALOGUE_CLOSE,
  ROADCALL_USER_TOKEN
} from 'utils/constants';

const version = process.env.REACT_APP_API_VER;

const getNameAbbreviation = (first_name: string, last_name: string) => {
  return `${first_name.charAt(0)}${last_name.charAt(0)}`.toUpperCase();
};

const getNameAbbreviationByFullName = (full_name: string) => {
  if (!full_name || full_name === '') {
    return '';
  }
  const nameParts = full_name.split(' ');
  const first_name = nameParts[0];
  const last_name = nameParts[nameParts.length - 1];
  return `${first_name.charAt(0)}${last_name.charAt(0)}`.toUpperCase();
};

const getUserDetailService = async (id: string, type: string = '') => {
  const url = `${type}/${version}/${id}/`;

  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const notifyCarrierOnDocumentExpiration = async (data: any) => {
  const url = `carrier/${version}/document_expiration/`;

  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const notifyUserOnReject = async (data: any) => {
  const url = `${data.user_type}/${version}/reject_user/`;

  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const removeCarrierPrefix = (id: string = '') => {
  return id.replace(PREFIX_CARRIER, '');
};

const removeShipperPrefix = (id: string = '') => {
  return id.replace(PREFIX_SHIPPER, '');
};

const removeTransactionPrefix = (id: string = '') => {
  return id.replace(PREFIX_TRANSACTION, '');
};

const updateUserService = async (id: string, userType: string, obj: any) => {
  try {
    const response = await axiosClient.patch(`${userType}/${version}/${id}/`, {
      ...obj,
      platform: PLATFORM
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const updateBrokerType = async () => {
  const userId = getUserIdService();
  const broker_type = getUserTypeService() === SHIPPER ? CARRIER : SHIPPER;
  try {
    const response = await axiosClient.patch(
      `${getUserTypeService(false)}/${version}/${userId}/`,
      {
        broker_type
      }
    );

    const token: any = localStorage.getItem(ROADCALL_USER_TOKEN);
    if (token) {
      const parsedToken = JSON.parse(token);
      parsedToken.broker_type = broker_type;
      localStorage.setItem(ROADCALL_USER_TOKEN, JSON.stringify(parsedToken));
    }

    window.location.href = '/';

    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const UpdateProfilePictureService = async (file_url: string) => {
  const options: any = {
    method: 'PATCH',
    url: `/${getUserTypeService()}/${version}/${getUserIdService()}/`,
    data: {
      profile_pic_url: file_url
    }
  };

  await axiosClient.request(options);
};

const GetProfilePicService = async () => {
  try {
    let url = `/core/profile_pic/${version}/?roadcall_id=${getUserIdService()}`;

    const data = await axiosClient.get(url);

    return data.data.profile_pic[0];
  } catch (error) {
    return '';
  }
};

const documentVerificationDialogueClose = () => {
  localStorage.setItem(ROADCALL_USER_DOC_VER_DIALOGUE_CLOSE, '1');
};

const getDocumentVerificationDialogueCloseStatus = () => {
  return localStorage.getItem(ROADCALL_USER_DOC_VER_DIALOGUE_CLOSE);
};

const removeDocumentVerificationDialogueCloseStatus = () => {
  return localStorage.removeItem(ROADCALL_USER_DOC_VER_DIALOGUE_CLOSE);
};

const getUserFirstLastChar = (firstName: string, lastName: string) => {
  return (firstName.trim().charAt(0) + lastName.trim().charAt(0)).toUpperCase();
};

const isShipperTypeIndividual = (userData: any) => {
  return isShipper(userData) && userData.account_type === INDIVIDUAL;
};

const isShipperTypeBusiness = (userData: any) => {
  return isShipper(userData) && userData.account_type === BUSINESS;
};

const isShipperTypeBroker = (userData: any) => {
  return isShipper(userData) && userData.account_type === BROKER;
};

const isShipper = (userData: any) => {
  return userData && userData.shipper_id;
};

const isCarrier = (userData: any) => {
  return userData && userData.carrier_id;
};

export {
  notifyUserOnReject,
  getUserDetailService,
  updateUserService,
  UpdateProfilePictureService,
  GetProfilePicService,
  getUserFirstLastChar,
  isShipperTypeIndividual,
  isShipperTypeBusiness,
  isShipperTypeBroker,
  isShipper,
  isCarrier,
  removeCarrierPrefix,
  removeShipperPrefix,
  notifyCarrierOnDocumentExpiration,
  updateBrokerType,
  getNameAbbreviation,
  getNameAbbreviationByFullName,
  removeTransactionPrefix,
  documentVerificationDialogueClose,
  getDocumentVerificationDialogueCloseStatus,
  removeDocumentVerificationDialogueCloseStatus
};

// Get random number between two numbers.
const getRandomBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// Convert dropdown item list into a proper format e.g
//     {
//         value: item value
//         label: item label
//         image_url: item image url
//     }
const sanitizedItems = (
  items: any,
  _value: string = '',
  _label: string = '',
  _image_url: string = ''
) => {
  const newItems: any = [];

  items.map((item: any) => {
    const ob = item;

    if (_value) {
      ob.value = item[_value];
      //delete ob[_value];
    }

    if (_label) {
      ob.label = item[_label];
      //delete item[_label];
    }

    if (_image_url) {
      ob.image_url = item[_image_url];
      //delete item[image_url];
    }

    newItems.push(ob);
  });

  return newItems;
};

export { getRandomBetween, sanitizedItems };

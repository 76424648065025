import { ReactComponent as SearchIcon } from '../../assets/images/common/search.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/common/profile.svg';
import { ReactComponent as InboxIcon } from '../../assets/images/common/inbox.svg';
import { ReactComponent as LoadsIcon } from '../../assets/images/common/loads.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/common/settings.svg';
import { ReactComponent as TrucksIcon } from '../../assets/images/common/pinned-trucks.svg';

import { ReactComponent as SearchIconSelected } from '../../assets/images/common/search-selected.svg';
import { ReactComponent as ProfileIconSelected } from '../../assets/images/common/profile-selected.svg';
import { ReactComponent as InboxIconSelected } from '../../assets/images/common/inbox-selected.svg';
import { ReactComponent as LoadsIconSelected } from '../../assets/images/common/loads-selected.svg';
import { ReactComponent as SettingsIconSelected } from '../../assets/images/common/settings-selected.svg';
import { ReactComponent as SavedLoads } from '../../assets/images/common/saved-loads.svg';
import { ReactComponent as SavedLoadsSelected } from '../../assets/images/common/saved-loads-selected.svg';
import { ReactComponent as TrucksSelectedIcon } from '../../assets/images/common/pinned-trucks-selected.svg';

const iconsMap: any = {
  profileIcon: ProfileIcon,
  inboxIcon: InboxIcon,
  searchIcon: SearchIcon,
  loadsIcon: LoadsIcon,
  settingsIcon: SettingsIcon,
  profileSelectedIcon: ProfileIconSelected,
  inboxSelectedIcon: InboxIconSelected,
  searchSelectedIcon: SearchIconSelected,
  loadsSelectedIcon: LoadsIconSelected,
  settingsSelectedIcon: SettingsIconSelected,
  savedLoadsIcon: SavedLoads,
  savedLoadsSelectedIcon: SavedLoadsSelected,
  trucksIcon: TrucksIcon,
  trucksSelectedIcon: TrucksSelectedIcon
};

export default [
  {
    label: '',
    content: JSON.parse(
      `[
  {
    "label": "Dashboard",
    "icon": "profileIcon",
    "iconSelected": "profileSelectedIcon",
    "to": "/Dashboard"
  },
  {
    "label": "Inbox",
    "icon": "inboxIcon",
    "iconSelected": "inboxSelectedIcon",
    "to": "/inbox"
  },
  {
    "label": "Search",
    "icon": "searchIcon",
    "iconSelected": "searchSelectedIcon",
    "to": "/search"
  },
  {
    "label": "Saved loads",
    "icon": "savedLoadsIcon",
    "iconSelected": "savedLoadsSelectedIcon",
    "to": "/saved-loads"
  },
  {
    "label": "My Loads",
    "icon": "loadsIcon",
    "iconSelected": "loadsSelectedIcon",
    "to": "/loads"
  },
  {
    "label": "Pinned Trucks",
    "icon": "trucksIcon",
    "iconSelected": "trucksSelectedIcon",
    "to": "/trucks"
  },
  {
    "label": "More",
    "icon": "settingsIcon",
    "iconSelected": "settingsSelectedIcon",
    "to": "/settings"
  }
]`,
      (key, value) => {
        if (key === 'icon' || key === 'iconSelected') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];

import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const CustomizedSnackbars = (props: any) => {
  const position: any = document.getElementById('portal');
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { data, onClose } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose();
  };

  const renderAlert = (type: string, message: string) => {
    switch (type) {
      case 'success':
        return (
          <Alert onClose={handleClose} severity="success">
            {message}
          </Alert>
        );
      case 'error':
        return (
          <Alert onClose={handleClose} severity="error">
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
          </Alert>
        );
      case 'warning':
        return (
          <Alert onClose={handleClose} severity="warning">
            {message}
          </Alert>
        );
      default:
        return (
          <Alert onClose={handleClose} severity="info">
            {message}
          </Alert>
        );
    }
  };

  useEffect(() => {
    if (data?.type) {
      handleOpen();
    }
  }, [props, data]);

  return ReactDom.createPortal(
    <div className={classes.root}>
      {data?.message && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}>
          {renderAlert(data?.type, data?.message)}
        </Snackbar>
      )}
    </div>,
    position
  );
};

export default CustomizedSnackbars;

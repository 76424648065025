import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

// Material UI.
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import { getShipmentMedia } from 'services/files';
import Popup from 'components/Popup';
import RcTextfield from 'components/Form/Textfield';
import ButtonWithBackground from 'components/Form/Button';
import DownloadSvg from 'assets/components/Svg/DownloadSvg';
import EnvelopeSvg from 'assets/components/Svg/EnvelopeSvg';
import {
  modifyImageUrlInDocumentsToShare,
  setInfoInDocumentsToShare,
  sendDocumentsMedia
} from 'reducers/Job';
import { ERROR } from 'utils/constants';

const DocumentActions = ({
  document,
  className,
  documentsToShare,
  setInfoInDocumentsToShare,
  modifyImageUrlInDocumentsToShare,
  sendDocumentsMedia,
  loadDetail,
  shipment_id,
  setSnackbar,
  sendDocumentsMediaLoading
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const [currentDoc, setCurrentDoc] = useState(null);

  useEffect(() => {
    if (loadDetail) 
    {
    setInfoInDocumentsToShare({
      ...documentsToShare,
      shipment_id: loadDetail.shipment_id
    });
  }
  }, [documentsToShare.image_url.length]);

  useEffect(() => {
    // Close send message popup, if image url file lenght is zero.
    if (documentsToShare.image_url.length === 0) {
      setCurrentDoc(null);
      setAnchorEl(null);
    }
  }, [documentsToShare.image_url.length]);

  if(loadDetail){
    const shipment_id = loadDetail.shipment_id
  }

  return (
    <>
      <Popup
        open={Boolean(currentDoc)}
        onclosehandler={() => setCurrentDoc(null)}>
        <form
          onSubmit={e => {
            e.preventDefault();

            const emailRegex = /\S+@\S+\.\S+/;

            let finalEmails = [];

            if (documentsToShare.emails.length) {
              for (let i = 0; i < documentsToShare.emails.length; i++) {
                if (emailRegex.test(documentsToShare.emails[i]) === true) {
                  finalEmails.push(documentsToShare.emails[i]);
                }
              }

              setInfoInDocumentsToShare({
                ...documentsToShare,
                emails: finalEmails
              });
            }

            if (finalEmails.length === 0) {
              setSnackbar({
                type: ERROR,
                message: 'Enter valid email address'
              });
              return;
            }
            // else if (!documentsToShare.subject.trim()) {
            //   setSnackbar({
            //     type: ERROR,
            //     message: 'Add a subject'
            //   });
            //   return;
            // } else if (!documentsToShare.message.trim()) {
            //   setSnackbar({
            //     type: ERROR,
            //     message: 'Add a message'
            //   });
            //   return;
            // }

            sendDocumentsMedia(documentsToShare);
          }}>
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 700,
              color: '#EE3C2D',
              marginLeft: 20,
              marginTop: 14,
              marginBottom: 30
            }}>
            Send Email ({documentsToShare.image_url.length} document
            {documentsToShare.image_url.length > 1 ? 's' : ''})
          </Typography>
          <Grid container spacing={2} style={{ paddingRight: 50 }}>
            <Grid
              item
              lg={2}
              style={{
                display: 'flex',
                justifyContent: 'end'
                //alignItems: 'center'
              }}>
              <Typography
                variant="h5"
                style={{
                  textAlign: 'right',
                  color: '#868686',
                  fontWeight: 600,
                  paddingRight: 15,
                  paddingTop: 14
                }}>
                To:{' '}
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <RcTextfield
                placeholder="Email Address (put comma to saperate multiple addresses)"
                className={classes.field}
                value={documentsToShare.emails.join()}
                changeHandler={e => {
                  setInfoInDocumentsToShare({
                    ...documentsToShare,
                    emails:
                      e.target.value.trim() && e.target.value.trim() !== ','
                        ? e.target.value.trim().split(',')
                        : []
                  });
                }}
              />
            </Grid>
            <Grid
              item
              lg={2}
              style={{
                display: 'flex',
                justifyContent: 'end'
                //alignItems: 'center'
              }}>
              <Typography
                variant="h5"
                style={{
                  textAlign: 'right',
                  color: '#868686',
                  fontWeight: 600,
                  paddingRight: 15,
                  paddingTop: 14
                }}>
                Subject:{' '}
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <RcTextfield
                placeholder="Add a subject"
                className={classes.field}
                value={documentsToShare.subject}
                changeHandler={e => {
                  setInfoInDocumentsToShare({
                    ...documentsToShare,
                    subject: e.target.value
                  });
                }}
              />
            </Grid>
            <Grid
              item
              lg={2}
              style={{
                display: 'flex',
                justifyContent: 'end'
                //alignItems: 'center'
              }}>
              <Typography
                variant="h5"
                style={{
                  textAlign: 'right',
                  color: '#868686',
                  fontWeight: 600,
                  paddingRight: 15,
                  paddingTop: 16
                }}>
                Message:{' '}
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <RcTextfield
                rows={5}
                multiline
                placeholder="Add description"
                className={classes.field}
                value={documentsToShare.message}
                changeHandler={e => {
                  setInfoInDocumentsToShare({
                    ...documentsToShare,
                    message: e.target.value
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 30, marginBottom: 30 }}>
            <Grid item lg={8}></Grid>
            <Grid item lg={4} style={{ display: 'flex', paddingRight: 40 }}>
              <ButtonWithBackground
                fullWidth
                type="border"
                label="Cancel"
                clickHandler={() => setCurrentDoc(null)}
                style={{ margin: 8 }}
              />
              <ButtonWithBackground
                disabled={sendDocumentsMediaLoading}
                fullWidth
                buttonType="submit"
                label="Send"
                style={{ margin: 8 }}
              />
            </Grid>
          </Grid>
        </form>
      </Popup>
      <Box className={clsx(classes.loadActions, className)}>
        <IconButton
          disableRipple
          onClick={e => {
            setAnchorEl(e.currentTarget);

            if (
              documentsToShare.image_url.length === 0 ||
              !documentsToShare.image_url.includes(document.s3_bucket_url)
            )
              modifyImageUrlInDocumentsToShare(document.s3_bucket_url);
          }}
          style={{ marginRight: '-8px', background: '#fff' }}>
          <MoreVert className="more-vert" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => setCurrentDoc(document)}>
            <EnvelopeSvg />
            <Typography style={{ marginLeft: 8 }}>Email</Typography>
          </MenuItem>
          <MenuItem onClick={() => getShipmentMedia(document.s3_bucket_url, shipment_id)}>
            <DownloadSvg />
            <Typography style={{ marginLeft: 8 }}>Download</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  loadActions: {
    '& .more-vert': {
      width: 18,
      height: 18
    }
  },
  field: {
    '& > div': {
      borderRadius: `8px !important`
    },
    '& input': {
      borderRadius: `8px !important`,
      paddingLeft: 35,
      color: '#6b6b6b'
    },
    '& textarea': {
      borderRadius: `8px !important`
    },
    moreIcon: {
      '& svg': {
        width: 18
      }
    }
  }
}));

const mapStateToProps = state => {
  return {
    user: state.User.user_data,
    loadDetail: state.Job.loadDetail,
    documentsToShare: state.Job.documentsToShare,
    sendDocumentsMediaLoading: state.Job.sendDocumentsMediaLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setInfoInDocumentsToShare: data =>
      dispatch(setInfoInDocumentsToShare(data)),
    modifyImageUrlInDocumentsToShare: url =>
      dispatch(modifyImageUrlInDocumentsToShare(url)),
    sendDocumentsMedia: (data) => dispatch(sendDocumentsMedia(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentActions);

import React, { useEffect, useState } from 'react';

const withDevice = WrappedComponent => {
  const WithDeviceComponent = ({ ...props }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }, []);

    return (
      <WrappedComponent
        isMobile={width <= 768}
        isTab={width > 768 && width < 960}
        {...props}
      />
    );
  };
  return WithDeviceComponent;
};

export default withDevice;

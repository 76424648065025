import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';

// Material UI.
import {
  Box,
  Button,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';

import Popup from 'components/Popup';
import {
  setDriverPopupVisible,
  setEditableDriverId,
  updateDriver
} from 'reducers/Driver';
import ButtonWithBackground from 'components/Form/Button';
import { useForm } from 'react-hook-form';
import { addDriver } from 'reducers/Driver';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ERROR } from 'utils/constants';
import PhoneNumberMask from 'components/Form/PhoneNumberMask';
import { sanitizePhoneNumber } from 'utils/numberUtils';

const AddDriver = ({
  add_driver_popup_visible,
  setDriverPopupVisible,
  add_driver_loading,
  addDriver,
  truck_types,
  transport_types,
  driver_error,
  setSnackbar,
  editable_driver_id,
  setEditableDriverId,
  driver,
  updateDriver
}) => {
  const classes = useStyles();
  const formDefaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    sanitizedPhone:'',
    truck_type: '',
    transport_type: '',
    truck_licence: ''
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: formDefaultValues
  });

  useEffect(() => {
    if (!add_driver_popup_visible) {
      reset(formDefaultValues);
      setEditableDriverId();
    }
  }, [add_driver_popup_visible]);

  useEffect(() => {
    if (editable_driver_id) {
      setValue('first_name', driver.first_name);
      setValue('last_name', driver.last_name);
      setValue('email', driver.email);
      setValue('phone', driver.phone);
      setValue('truck_type', driver.truck_type);
      setValue('transport_type', driver.transport_type);
    } else reset(formDefaultValues);
  }, [editable_driver_id]);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (driver_error)
      setSnackbar({
        type: ERROR,
        message: driver_error
      });
  }, [driver_error]);

  const onSubmit = async formData => {
    const sanitizedPhone = sanitizePhoneNumber(formData.phone);
    const formDataWithSanitizedPhone = {
      ...formData,
      phone: sanitizedPhone,
    };
    if (editable_driver_id) {
      updateDriver(editable_driver_id, formDataWithSanitizedPhone);
    } else {
      addDriver(formDataWithSanitizedPhone);
    }
  };
  return (
    <Popup
      open={add_driver_popup_visible ? true : false}
      onclosehandler={() => setDriverPopupVisible(false)}>
      <Box
        style={{
          maxWidth: 650,
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 20,
          paddingBottom: 20
        }}>
        <Typography
          style={{ color: '#6B6B6B', fontWeight: 600, marginBottom: 12 }}>
          Add a Driver
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ marginTop: 8 }}>
            <Grid container spacing={2}>
              <Grid item lg={6} style={{ marginTop: 12 }}>
                <Typography className={classes.label}>First Name</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld fld-active capitalizefield"
                  placeholder="First name"
                  style={{ marginTop: 8 }}
                  helperText={errors.first_name?.message}
                  error={errors.first_name ? true : false}
                  value={watch('first_name')}
                  {...register('first_name', {
                    required: 'First name is required'
                  })}
                  onChange={e => {
                    setValue('first_name', e.target.value.trimLeft());
                  }}
                />
              </Grid>
              <Grid item lg={6} style={{ marginTop: 12 }}>
                <Typography className={classes.label}>Last Name</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld fld-active capitalizefield"
                  placeholder="Last name"
                  style={{ marginTop: 8 }}
                  helperText={errors.last_name?.message}
                  error={errors.last_name ? true : false}
                  value={watch('last_name')}
                  {...register('last_name', {
                    required: 'Last name is required'
                  })}
                  onChange={e => {
                    setValue('last_name', e.target.value.trimLeft());
                  }}
                />
              </Grid>
              <Grid item lg={6} style={{ marginTop: 12 }}>
                <Typography className={classes.label}>Email Address</Typography>
                <TextField
                  disabled={Boolean(editable_driver_id)}
                  variant="outlined"
                  fullWidth
                  className="inpt-fld fld-active"
                  placeholder="Email address"
                  style={{ marginTop: 8 }}
                  helperText={errors.email?.message}
                  error={errors.email ? true : false}
                  value={watch('email')}
                  {...register('email', {
                    required: 'Email address is required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Invalid email address'
                    }
                  })}
                  onChange={e => {
                    setValue('email', e.target.value.trimLeft());
                  }}
                />
              </Grid>
              <Grid item lg={6} style={{ marginTop: 12 }}>
                <Typography className={classes.label}>Phone Number</Typography>
                <TextField
                  InputProps={{
                    inputComponent: PhoneNumberMask
                  }}
                  variant="outlined"
                  fullWidth
                  className="inpt-fld fld-active"
                  placeholder="Phone number"
                  style={{ marginTop: 8 }}
                  helperText={errors.phone?.message}
                  error={errors.phone ? true : false}
                  value={watch('phone')}
                  {...register('phone', {
                    required: 'Phone number is required',
                  })}
                  onChange={e => {
                    setValue('phone', e.target.value);
                  }}
                />
              </Grid>
              <Grid item lg={6} style={{ marginTop: 12 }}>
                <Typography className={classes.label}>Truck Type</Typography>
                <TextField
                  style={{ marginTop: 8 }}
                  id="selected_truck_type"
                  variant="outlined"
                  fullWidth
                  select
                  className="inpt-fld-select fld-active2"
                  InputProps={{
                    endAdornment: (
                      <KeyboardArrowDownIcon className={classes.arrowIcon} />
                    )
                  }}
                  value={watch('truck_type')}
                  helperText={errors.truck_type?.message}
                  error={errors.truck_type ? true : false}
                  {...register('truck_type', {
                    required: 'Please select a truck type'
                  })}
                  onChange={e => {
                    setValue('truck_type', e.target.value);
                  }}>
                  {truck_types.map(truck_type => (
                    <MenuItem
                      key={truck_type.truck_type}
                      value={truck_type.truck_type}>
                      {truck_type.display_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} style={{ marginTop: 12 }}>
                <Typography className={classes.label}>
                  Transport Type
                </Typography>
                <TextField
                  style={{ marginTop: 8 }}
                  variant="outlined"
                  fullWidth
                  select
                  className="inpt-fld-select fld-active2"
                  InputProps={{
                    endAdornment: (
                      <KeyboardArrowDownIcon className={classes.arrowIcon} />
                    )
                  }}
                  value={watch('transport_type')}
                  helperText={errors.transport_type?.message}
                  error={errors.transport_type ? true : false}
                  {...register('transport_type', {
                    required: 'Please select transport type'
                  })}
                  onChange={e => {
                    setValue('transport_type', e.target.value);
                  }}>
                  {transport_types.map(tt => (
                    <MenuItem key={tt.name} value={tt.name}>
                      {tt.display_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: 35 }}>
            <Box style={{ position: 'absolute', bottom: 0, right: 45 }}>
              <Button
                onClick={() => setDriverPopupVisible(false)}
                disableRipple
                variant="text"
                style={{
                  marginTop: '12px',
                  borderRadius: '9px',
                  fontSize: '11px',
                  height: '30px',
                  color: '#EE3C2D',
                  fontWeight: 700,
                  background: 'transparent'
                }}>
                Cancel
              </Button>
              <ButtonWithBackground
                disabled={add_driver_loading}
                loaderSize={14}
                style={{
                  marginTop: 20,
                  borderRadius: 9,
                  fontSize: 10,
                  textTransform: 'capitalize',
                  minWidth: 100,
                  height: 30
                }}
                label={`${editable_driver_id ? 'Update' : 'Add'} Driver`}
                buttonType="submit"
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Popup>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    color: '#454647',
    fontSize: 12,
    marginLeft: 25,
    fontWeight: 600
  },
  arrowIcon: {
    color: '#c7c7c7',
    pointerEvents: 'none',
    position: 'absolute',
    right: 25
  }
}));

const mapStateToProps = state => {
  return {
    add_driver_popup_visible: state.Driver.add_driver_popup_visible,
    add_driver_loading: state.Driver.add_driver_loading,
    truck_types: state.Truck.truck_types,
    transport_types: state.Truck.transport_types,
    driver_error: state.Driver.error,
    editable_driver_id: state.Driver.editable_driver_id,
    driver: state.Driver.driver
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDriverPopupVisible: visibility =>
      dispatch(setDriverPopupVisible(visibility)),
    addDriver: data => dispatch(addDriver(data)),
    setEditableDriverId: driver_id => dispatch(setEditableDriverId(driver_id)),
    updateDriver: (driver_id, data) => dispatch(updateDriver(driver_id, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDriver);

import React, { useState, useEffect } from 'react'
import Tabs from '../../Tabs/Tabs';
import { styled, Link } from '@mui/material';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  makeStyles,
  Divider,
  LinearProgress,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  getSubscriptionDetails,
  createCustomerPortal
} from 'reducers/SubScriptionPlan';
import Crossicon from 'assets/images/common/Snakebarcrossicon.png'
import Warningicon from 'assets/images/common/Snakebarwarningicon.png'
import { ReactComponent as RightLabel } from 'assets/images/common/rightLabel.svg'
import PageLoader from 'components/loader';
import { convertUtcToLocal } from 'utils/timeUtils';
import { DATE_FORMAT } from 'utils/CommonFormats';

const BorderLinearProgress1 = styled(LinearProgress)(() => ({
  height: '7px',
  width: '50%',
  borderRadius: '11px',
  marginBottom: 10,
  minWidth: 150
}));

const Billing = ({
  getSubscriptionDetails,
  subscription_details,
  subscription_details_loading,
  currentPlan,
  createCustomerPortalLoading,
  createCustomerPortal,
  scheduled_for_cancelation
}) => {

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [plan, setPlan] = useState('Free')
  const params = new URLSearchParams(location.search);
  const currentTab = params.get('tab');
  const [open, setOpen] = useState(true);

 useEffect(() => {
      getSubscriptionDetails();
  }, []);

  useEffect(() => {
    if (currentPlan === "Premium Plan") {
      setPlan('Premium');
    } else
      setPlan('Free');
  }, [currentPlan]);

  if ((subscription_details == null)
    || subscription_details_loading) {
    return <PageLoader />
  }
  const daysRemaining = subscription_details.remaining_days
  const active_drivers = subscription_details.active_drivers
  const total_drivers = subscription_details.total_drivers
  const price = subscription_details.price
  const estimated_bill = subscription_details.next_billing_amount
  const cancelled_date = convertUtcToLocal(subscription_details.cancellation_date, DATE_FORMAT)

  const percentage = (active_drivers / total_drivers) * 100;

return (
    <Box spacing={3} className={classes.container}>
      <Box className={classes.section}>
        <Tabs
          currentTab={currentTab || 'account'}
          className={clsx(
            classes.tabs,
            currentTab ? classes.tabInfoHide : ''
          )}
        />
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        style={{
          marginRight: '-1px',
        }}
        className={classes.verticalDivider}
      />
      <Box className={classes.section2}>
        <Box
          style={{
            backgroundColor: '#FFD9D5',
            padding: '12px 20px',
            marginTop: -15,
            marginRight: -20,
            marginLeft: -20,
            display: scheduled_for_cancelation === true && open === true ? 'block' : 'none'
          }} >
          <Box className={classes.banner}>
            <img src={Warningicon} style={{width:24,height:24}} alt="" />
            <Typography className={classes.bannertext}>
    Your account will switch to the Free plan at the end of your billing cycle. {' '}
    <Link
      style={{ textDecoration: 'underline', color: '#ED3D2E', fontWeight:800,cursor:'pointer'}}
      onClick={() => {
        history.push(`settings?tab=plans_and_pricing`);
          }}
    >
      Upgrade here before{' '}
      {cancelled_date}
    {' '}
    to continue using current features.
    </Link>
  </Typography>
            <img src={Crossicon} style={{width:22,height:22}} onClick={() => setOpen(false)} />
          </Box>
        </Box>

        <Box className={classes.box}>
          <Typography className={classes.heading}>
            Current Subscription
          </Typography>
          <Box className={classes.maincontainer}>
            <Box className={clsx(
              classes.planDetail,
              classes.changeplan
            )}>
              <RightLabel style={{
                position: 'absolute',
                top: -8,
                right: -8
              }} />
              <Box style={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
                <span className={classes.plan}>
                  <Typography display='inline' className={classes.text}>
                    {plan.toUpperCase()}
                  </Typography>
                </span>
                <Typography style={{
                  marginLeft: 8,
                  fontSize: 18,
                  fontWeight: 700
                }}>Plan</Typography>
              </Box>
              <Box
                style={{
                  float: 'right',
                  display: 'inline-flex',
                  flexDirection: 'row',
                  marginTop: -10,
                }}
              >
                <Typography style={{
                  fontSize: 25,
                  fontWeight: 600,
                  marginTop: 8
                }}>
                  $
                </Typography>
                <Typography
                  style={{
                    fontSize: 40,
                    fontWeight: 600,
                    paddingLeft: 2,
                    verticalAlign: 'top',
                  }}
                >
                  {Math.round(price)}
                </Typography>
              </Box>
             {plan != "Free" && (
               <Typography
                style={{
                  marginTop: 15,
                  color: '#6A6A6A',
                  fontWeight: 600,
                  marginRight: 30,
                  marginBottom: 40,
                }}
              >
                {`${daysRemaining} days remaining`}
              </Typography>)}
              <Typography
                style={{
                  float: "right",
                  marginTop:plan == "Free" ? 60 : -60,
                  marginRight: plan == "Free"? -50 : 0,
                  color: '#A8A8A8',
                  paddingLeft: 20,
                }}
              >
                active driver/ month
              </Typography>

              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginTop:12,
                height: plan == 'Free' ? '64%' : '40%',
              }}>
                <BorderLinearProgress1
                  variant="determinate"
                  value={percentage}
                  sx={{
                    backgroundColor: `rgba(249, 217, 213, 1)`,
                    borderRadius: '11px',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: `rgba(237, 61, 46, 1)`
                    }
                  }}></BorderLinearProgress1>
              </Box>
              <Box style={{ float: 'right', marginTop: -20 }}>
                <Button className={classes.button} style={{
                  position: 'absolute',
                  bottom: 34,
                  right: 32,
                }}
                  onClick={() => {
                    history.push(`settings?tab=plans_and_pricing`);
                  }}>Change Plan</Button>
              </Box>
              <Typography style={{
                marginTop: 4,
                paddingBottom: 30, color: '#6A6A6A',
                fontSize: 15, fontWeight: 600,
                maxWidth: '55%'
              }}>
                {`${active_drivers} active drivers of ${total_drivers} total`}
              </Typography>
            </Box>
            <Box className={clsx(
              classes.planDetail,
              classes.managepayment
            )}>
              <Typography
                style={{
                  color: '#6B6B6B',
                  fontWeight: 600,
                  fontSize: 15,
                  marginBottom: 10,
                  letterSpacing: 1.5,
                }}
              >
                NEXT ESTIMATED BILL
              </Typography>
              <Box style={{
                display: 'inline-flex',
                flexDirection: 'row',
                marginBottom: 10
              }}>
                <Typography style={{
                  fontSize: 25,
                  fontWeight: 600,
                  marginTop: 8
                }}>
                  $
                </Typography>
                <Typography
                  style={{
                    fontSize: 40,
                    fontWeight: 600,
                    paddingLeft: 4,
                    verticalAlign: 'top',
                  }}
                >
                  {estimated_bill}
                </Typography>
              </Box>
              <Typography style={{ color: '#6B6B6B', fontWeight: 600 }}>
                Your total isn’t final until your billing cycle is over.<br />
                Price is based on the amount of current active drivers.
              </Typography>
              <Button
                className={classes.Button}
                disabled={createCustomerPortalLoading}
                onClick={() => createCustomerPortal()}
                style={{
                  backgroundColor: '#FFFFFF',
                  minWidth: 150,
                  color: '#6B6B6B',
                  fontWeight: 700,
                  marginTop: 30,
                  marginBottom: 10,
                  border: '2px solid #EAEAEA',
                }}
              >
                {createCustomerPortalLoading ? (
                  <CircularProgress size={25} color='inherit' thickness={4} />
                ) : (
                  'Manage Payment'
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
const useStyles = makeStyles(theme => ({
  maincontainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('1200')]: {
      flexDirection: 'column'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 20,
    backgroundColor: '#F5F8FA',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  section: {
    width: '25%',
    minWidth: 350,
    marginRight: 20,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      width: '100%'
    }
  },
  section2: {
    marginTop: theme.spacing(1),
    width: '75%',
    backgroundColor: '#F5F8FA',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%'
    }
  },
  verticalDivider: {
    color: '#e5e5e5',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset'
    }
  },
  box: {
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '11px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: "#FFFFFF",
    padding: 40,
    margin: 30,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxWidth: 600
    }
  },
  heading: {
    color: '#6B6B6B',
    fontSize: 22,
    fontWeight: 700,
    marginBottom: 30
  },
  planDetail: {
    padding: '25px 36px',
    border: "3px solid #D2D2D2",
    borderRadius: '15px',
    alignItems: 'center',
    boxShadow: '0px 3px 10px #00000029',
    width: '54%',
    position: 'relative',
    minWidth: 400
  },
  changeplan:
  {
    width: '54%',
    position: 'relative',
    minWidth: 380,
    [theme.breakpoints.down(1200)]: {
      width: '100%'
    }
  },
  managepayment:
  {
    width: '46%',
    minWidth: 320,
    marginLeft: 40,
    [theme.breakpoints.down(1200)]: {
      marginLeft: 0,
      marginTop: 30,
      width: '100%'
    }
  },
  plan:
  {
    padding: '6px 10px',
    backgroundColor: '#132239',
    borderRadius: 6
  },
  button: {
    width: 125,
    height: 36,
    marginLeft: 20,
    textAlign: 'center',
    fontWeight: 600,
    backgroundColor: 'rgba(237, 61, 46, 1)',
    borderRadius: '11px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(237, 61, 46, 1)',
      color: '#FFFFFF',
    },
  },
  text:
  {
    color: '#FFFFFF',
    fontSize: 18,
    letterSpacing: 1.8,
    fontWeight: 500
  },
  banner: {
    marginTop: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxmarginLeft: 'auto',
  },
  bannertext: {
    paddingLeft: 20,
    paddingRight: '4%',
    marginTop:8,
    marginBottom:6,
    color: '#ED3D2E',
    justifyContent: 'center',
    fontWeight: 600
  }
}))
const mapStateToProps = (state) => {
  return {
    subscription_details_loading: state.SubscriptionPlan.subscription_details_loading,
    subscription_details: state.SubscriptionPlan.subscription_details,
    currentPlan: state.SubscriptionPlan.currentPlan,
    createCustomerPortalLoading: state.SubscriptionPlan.createCustomerPortalLoading,
    can_subscribe: state.SubscriptionPlan.can_subscribe,
    scheduled_for_cancelation: state.SubscriptionPlan.scheduled_for_cancelation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionDetails: () => dispatch(getSubscriptionDetails()),
    createCustomerPortal: () => dispatch(createCustomerPortal()),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Billing);
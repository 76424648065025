import React, { useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

// Material UI.
import { Box, Divider, Grid, makeStyles } from '@material-ui/core';

// Components.
import ButtonWithBackground from 'components/Form/Button';
import Tabs from '../../Tabs/Tabs';
import Drivers from '../../TabInfo/Driver/Drivers';
import DriverDetail from '../../TabInfo/Driver/Detail';
import AddDriver from '../../TabInfo/Driver/AddDriver';
import DeleteDriver from '../../TabInfo/Driver/DeleteDriver';

// Reducers.
import { setDriverPopupVisible } from 'reducers/Driver';

// Utils.
import { SETTINGS_PAGE } from 'utils/routesUtils';
import { trackEvent } from 'utils/MixPanel';

const Driver = ({
  setSnackbar,
  drivers_loading,
  drivers,
  setDriverPopupVisible,
  added_driver_id,
  deleted_driver_id
}) => {
  const location = useLocation();

  const history = useHistory();

  const params = new URLSearchParams(location.search);

  const currentTab = params.get('tab');
  const param_id = params.get('id');

  const classes = useStyles();

  useEffect(() => {
    trackEvent('My Drivers Page Nav');
  }, []);

  useEffect(() => {
    if (added_driver_id)
      history.push({
        pathname: SETTINGS_PAGE,
        search: `?tab=drivers&id=${added_driver_id}`
      });
  }, [added_driver_id]);

  useEffect(() => {
    if (deleted_driver_id) {
      history.replace({
        pathname: SETTINGS_PAGE,
        search: `?tab=drivers`
      });
    }
  }, [deleted_driver_id]);

  return (
    <>
      <DeleteDriver />
      <AddDriver setSnackbar={setSnackbar} />
      {param_id ? (
        <ButtonWithBackground
          label="Add New"
          style={{
            position: 'fixed',
            top: '22px',
            right: '35px',
            zIndex: '1',
            fontSize: '10px',
            borderRadius: '9px',
            width: '90px',
            height: '30px',
            fontWeight: 700
          }}
          clickHandler={setDriverPopupVisible}
        />
      ) : null}
      <Grid container>
        <Grid item lg={3} md={3} sm={12} xs={12} className={classes.section} style={{ backgroundColor: '#fff' }}>
          <Tabs
            currentTab={currentTab || 'account'}
            className={clsx(
              classes.tabs,
              currentTab ? classes.tabInfoHide : ''
            )}
          />
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12} className={classes.section2}>
          <Grid container>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: '-1px' }}
              className={classes.verticalDivider}
            />

            <Drivers />

            <Grid
              item
              lg={!drivers || drivers.results.length === 0 ? 12 : 8}
              md={!drivers || drivers.results.length === 0 ? 12 : 8}>
              <Box style={{ padding: 30, paddingLeft: 50, paddingRight: 50 }}>
                {drivers_loading ? null : <DriverDetail />}
              </Box>
            </Grid>
           </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  indexexList: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabs: {
    position: 'sticky',
    top: 74,
    paddingTop: 0
  },
  verticalDivider: {
    background: '#e5e5e5',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset'
    }
  },
  section: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  section2: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  tabInfoHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabInfo: {
    marginTop: theme.spacing(2)
  }
}));

const mapStateToProps = state => ({
  drivers_loading: state.Driver.drivers_loading,
  drivers: state.Driver.drivers,
  added_driver_id: state.Driver.added_driver_id,
  deleted_driver_id: state.Driver.deleted_driver_id
});

const mapDispatchToProps = dispatch => {
  return {
    setDriverPopupVisible: visibility =>
      dispatch(setDriverPopupVisible(visibility))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Driver);

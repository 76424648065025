import React, { useState } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as TickIcon3 } from 'assets/images/common/wrong_radio_button.svg';
import { ReactComponent as TickIcon2 } from 'assets/images/common/right_radio_button.svg';
import { ReactComponent as TickIcon } from 'assets/images/common/unchecked_radio_button.svg';

import { createStyles } from '@mui/material';

const ValidationPassward = ({passward,submitClicked}) => {
  const classes = useStyles();
  const [passward_strength,setPassward_strength] = useState(false)
  const hasSpecialCharacter = /[^a-zA-Z\d]/.test(passward);
  const hasDigit = /\d/.test(passward);
  const hasLowercase = /[a-z]/.test(passward);
  const hasUppercase = /[A-Z]/.test(passward);

 const UserRoleRadio1 = withStyles({
    root: {
      '&$checked': {
        color: '#30C13A'
      }
    },
    checked: {}
  })(rest => <Radio color="default" {...rest} />);
  return (
     <>
                <Grid container spacing={2} style={{marginTop:27, marginLeft:20}}>
        <RadioGroup
          row
          name="passward_strength"
              value={passward_strength}
              control={
                  <UserRoleRadio1
                  classes={{
                    root: classes.radio2,
                  }}
                  checked={!!passward && hasSpecialCharacter}
                  checkedIcon={<checkedIconRadio/>}
                  />
                }
          >
        <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControlLabel
              value={passward_strength}
              control={
                <UserRoleRadio1 
                icon={submitClicked ? <TickIcon3 /> : <TickIcon/>}
                classes={{
                  root: classes.radio2,
                }}
                checked={!!passward && passward.length >= 8} 
                checkedIcon={<TickIcon2/>}

                />
                }
                label={
                  <Typography
                  style={{
                    marginLeft: 10,
                    fontWeight: 500,
                    fontSize:14,
                    color: (submitClicked && !(passward && passward.length >= 8))
                    ? '#EE3C2D'
                    : (submitClicked || (passward && passward.length >= 8))
                      ? '#30C13A'
                      : '#71828A'
              }}
              >
                    8+ characters
                  </Typography>
                }
              />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <FormControlLabel
              value={passward_strength}
              control={
                <UserRoleRadio1
                icon={submitClicked ? <TickIcon3 /> : <TickIcon/>}
                classes={{
                  root: classes.radio2,
                }}
                checked={!!passward && hasDigit}
                checkedIcon={<TickIcon2/>}
              />
            
                }
                label={
                  <Typography
                    style={{
                      marginLeft: 10,
                      fontWeight: 500,
                      fontSize:14,
                      color:
                      (submitClicked && !(passward && hasDigit))
                        ? '#EE3C2D'
                        : (submitClicked || (passward && hasDigit))
                          ? '#30C13A'
                          : '#71828A'
                  }}
                >
                   At least one number
                  </Typography>
                }
              />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <FormControlLabel
              value={passward_strength}
              control={
                  <UserRoleRadio1
                  icon={submitClicked ? <TickIcon3 /> : <TickIcon/>}
                  classes={{
                    root: classes.radio2,
                  }}
                
                  checked={!!passward  && hasLowercase && hasUppercase}
                  checkedIcon={<TickIcon2/>}
                  />
                }
                label={
                  <Typography
                  style={{
                    marginLeft: 10,
                    fontWeight: 500,
                    fontSize:14,
                    color:
                    (submitClicked && !(passward && hasUppercase && hasLowercase))
                    ? '#EE3C2D'
                    : (submitClicked || (passward && hasUppercase && hasLowercase))
                      ? '#30C13A'
                      : '#71828A'

                     }}>
                  Upper and lowercase characters
                  </Typography>
                }
              />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <FormControlLabel
              value={passward_strength}
              control={
                <UserRoleRadio1
                icon={submitClicked ? <TickIcon3 /> : <TickIcon/>}
                classes={{
                  root: classes.radio2,
                }}
                  checkedIcon={<TickIcon2/>}
                   checked={!!passward && hasSpecialCharacter}
                />
              }
                label={
                  <Typography
                  style={{
                    marginLeft: 10,
                    fontWeight: 500,
                    fontSize:14,
                    color:
                    (submitClicked && !(passward && hasSpecialCharacter))
                    ? '#EE3C2D'
                    : (submitClicked || (passward && hasSpecialCharacter))
                      ? '#30C13A'
                      : '#71828A'
 }}>
                    At least one special character
                  </Typography>
                }
              />
        </Grid>

        </RadioGroup>
        </Grid>
</>
      
  );
};
const useStyles = makeStyles(theme =>
  createStyles({
    bg: {
      '& > span': {
        background: '#fff'
      }
    },
    radio2: {
     
      padding: 0,
      '& > span': {
        borderRadius: 20
      },
      '& > span > div': {
        color: '#F5F8FA',
        background: '#F5F8FA',
        borderRadius: 15,
        width: 15,
        height: 15,
        opacity: 0
      }
      
    },

  }));
  export default ValidationPassward;
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import PaymentView from 'containers/Settings/TabInfo/Payment/PaymentView';
import Loading from 'components/loader';
import { connect } from 'react-redux';
import { validatePaymentUrl } from 'reducers/User';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ERROR } from 'utils/constants';

const PaymentAuth = ({
  validatePaymentUrl,
  validatePaymentUrlLoading,
  user,
  error,
  payment_error,
  setSnackbar
}) => {
  const { customer_id } = useParams();

  useEffect(() => {
    validatePaymentUrl(customer_id);
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (error || payment_error)
      setSnackbar({
        type: ERROR,
        message: error || payment_error
      });
  }, [error, payment_error]);

  if (validatePaymentUrlLoading) return <Loading />;
  else if (!user) return null;

  return (
    <Box style={{ padding: 16 }}>
      <PaymentView />
    </Box>
  );
};

const mapStateToProps = state => ({
  user: state.User.user_data,
  payment_error: state.Payment.error,
  validatePaymentUrlLoading: state.User.validatePaymentUrlLoading,
  error: state.User.user_error
});

const mapDispatchToProps = dispatch => {
  return {
    validatePaymentUrl: id => dispatch(validatePaymentUrl(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAuth);

import React, { useEffect, useState } from 'react';

// Material UI.
import {
  Box,
  makeStyles,
  TableBody,
  TableRow,
  Typography
} from '@material-ui/core';

import AddPaymentPlaceholder from './AddPaymentPlaceholder';
import AddedCards from './AddedCards';
import AddPaymentMethod from './AddPayment';
import SetDefaultPaymentMethod from './SetDefaultPaymentMethod';
import RemovePaymentMethod from './RemovePaymentMethod';
import PaymentMethodErrors from './PaymentMethodErrors';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';
import ShipperCards from './ShipperCards';
import AddCard from './AddCard';
import CustomTable from 'components/CustomTable';
import TransactionHeader from 'components/TableHeader/TransactionHeader';
import { TableCell } from '@mui/material';
import Transactions from './Transactions';
import { getBooleanFlag } from 'services/featureFlags';

const PaymentView = ({ setSnackbar }) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [carrierShowShipperScreens, setCarrierShowShipperScreens] = useState(true)
  const classes = useStyles();

  useEffect(() => {
    const fetchFlag = async () => {
      const flagValue = await getBooleanFlag("show_shipper_screen");
      setCarrierShowShipperScreens(flagValue);
    };
    fetchFlag();
  }, []);
  const userType = getUserTypeService();

  return (
    <>
      <SetDefaultPaymentMethod paymentMethod={paymentMethod} />
      <RemovePaymentMethod paymentMethod={paymentMethod} />

      {userType === SHIPPER ? (
        <AddCard setSnackbar={setSnackbar} />
      ) : (
        <AddPaymentMethod />
      )}

      <Typography className={classes.title}>Payment Method</Typography>
      <Box className={classes.cards}>
        <Box className={classes.cardsInner}>
          {userType === SHIPPER ? (
            <ShipperCards setPaymentMethod={setPaymentMethod} />
          ) : (
            <AddedCards setPaymentMethod={setPaymentMethod} />
          )}
        </Box>
        <AddPaymentPlaceholder carrierShowShipperScreens={carrierShowShipperScreens} />
      </Box>

      {/* <PaymentMethodErrors /> */}

      <Transactions />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 20,
    color: '#6B6B6B',
    fontWeight: 700
  },
  cards: {
    display: 'flex',
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'inherit'
    }
  },
  cardsInner: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'inherit',
      display: 'inherit'
    }
  }
}));

export default PaymentView;

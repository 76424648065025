import React, { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as DownIcon } from 'assets/images/bol/down.svg';

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';

const BoxLayout5 = props => {
  const { children, className, collapse, title, togallable, ...rest } = props;

  const [open, setOpen] = useState(collapse);

  const classes = useStyles();

  return (
    <Box className={clsx(classes.box, className)} {...rest}>
      <Box
        className={classes.header}
        onClick={() => {
          if (togallable !== false) setOpen(!open);
        }}>
        <Box>
          <Typography style={{ fontWeight: 700, color: '#ABACC3', fontSize: 12 }}>
            {title}
          </Typography>
        </Box>
        {togallable !== false ? (
          <Box style={{ display: 'flex' }}>
            <DownIcon
              className={clsx(classes.icon, open ? classes.collapse : '')}
            />
          </Box>
        ) : null}
      </Box>
      <Box className={clsx(classes.info, open ? classes.infoHide : '')}>
        {children}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    box: {
      boxShadow: '0px 0px 10px #00000033',
      borderRadius: 14,
      paddingLeft: 35,
      paddingRight: 35,
      paddingTop: 16,
      paddingBottom: 16,
      background: '#fff'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    icon: {
      transform: 'rotate(180deg)',
      transition: 'transform 0.5s',
      cursor: 'pointer'
    },
    collapse: {
      transform: 'unset'
    },
    info: {},
    infoHide: {
      display: 'none'
    }
  })
);

export default BoxLayout5;

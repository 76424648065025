import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Material UI.
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';

// Components.
import Tabs from './Tabs/Tabs';
import TabInfo from './TabInfo/TabInfo';
import clsx from 'clsx';
import { ERROR, SUCCESS } from 'utils/constants';
import { connect } from 'react-redux';
import PrivacyPolicyContent from 'containers/PublicPage/PrivacyPolicy/Content';
import { privacyPoliciyIndexes, termsOfServiceIndexes } from 'utils/textUtils';
import { getUserTypeService } from 'services/login';
import { CARRIER } from 'utils/users';
import TermsOfServiceContent from 'containers/PublicPage/TermsService/Content';
import Payment from './TabInfo/Payment/Payment';
import Driver from './TabInfo/Driver';
import Document from './TabInfo/Document/Document';
import Bug from './TabInfo/Reportbug';
import Subscription from './TabInfo/Subscriptions/Subscription';
import Billing from './TabInfo/Billing/Billing';
import { trackEvent } from 'utils/MixPanel';
import Payout from './TabInfo/Payout/Payout';
import StripePayment from './TabInfo/StripePayment/StripePayment';
export const TncIndex = ({ active, children, clickHandler }) => (
  <Typography
    onClick={clickHandler}
    style={{
      color: active ? '#EF3B2D' : '#C1C1C1',
      fontWeight: 600,
      fontSize: 10,
      marginBottom: 8,
      cursor: 'pointer'
    }}>
    {children}
  </Typography>
);

const Settings = ({ setSnackbar, user_error, user_message, payment_error }) => {
  const location = useLocation();

  let currentUserType = getUserTypeService();

  const params = new URLSearchParams(location.search);

  const currentTab = params.get('tab');

  const classes = useStyles();

  const tncRefs = useRef([]);

  const [currentActiveIndex, setCurrentActiveIndex] = useState('');

  const executeScroll = ref => {
    if (tncRefs.current && tncRefs.current[ref]) {
      setCurrentActiveIndex(ref);
      const y =
        tncRefs.current[ref].getBoundingClientRect().top +
        window.pageYOffset +
        -80;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    trackEvent('More Page Nav');
  }, []);
  
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    // Not to call on initial render.
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const message = user_error || user_message || payment_error;
    const hasSuccess = user_message ? true : false;
    if (message)
      setSnackbar({
        type: hasSuccess ? SUCCESS : ERROR,
        message
      });
  }, [user_error, user_message, payment_error]);

  if (currentTab === 'payment') return <Payment setSnackbar={setSnackbar} />;
  if (currentTab === 'documents' && currentUserType === CARRIER)
    return <Document setSnackbar={setSnackbar} />;

  if (currentTab === 'report a bug')
    return <Bug/>;
    
  if (currentTab === 'plans_and_pricing')
    return <Subscription
      title={' Transparent pricing. Faster growth.'}
      subTitle={'Cost effective. No hidden fees. Pay based on your usage.'}
      TabOptions = {'true'}
    />;

    if (currentTab === 'accounts_and_billing')
    return <Billing/>;

  if (currentTab === 'payout') {
    return <Payout />;
  }

  if (currentTab === 'privacy_policy')
    return (
      <Grid container className={classes.inbox}>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          className={classes.section}
          style={{ backgroundColor: '#fff' }}>
          <Tabs
            currentTab={currentTab || 'account'}
            className={clsx(
              classes.tabs,
              currentTab ? classes.tabInfoHide : ''
            )}
          />
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12} className={classes.section2}>
          <Grid container>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: '-1px' }}
              className={classes.verticalDivider}
            />
            <Grid
              item
              lg={3}
              md={3}
              className={classes.indexexList}
              style={{
                padding: 12,
                height: 'calc(100vh - 90px)',
                overflow: 'auto',
                position: 'sticky',
                top: 82
              }}>
              {privacyPoliciyIndexes.map((tnc, index) => (
                <TncIndex
                  key={index}
                  active={currentActiveIndex === tnc.title}
                  clickHandler={() => executeScroll(tnc.title)}>
                  {index + 1}. {tnc.title}
                </TncIndex>
              ))}
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: '-1px' }}
              className={classes.verticalDivider}
            />

            <Grid item lg={9} md={9}>
              <Typography
                style={{
                  fontSize: 20,
                  color: '#6B6B6B',
                  fontWeight: 700,
                  paddingLeft: 12
                }}>
                Privacy policy
              </Typography>
              <PrivacyPolicyContent tncRefs={tncRefs} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  if (currentTab === 'terms_of_service')
    return (
      <Grid container className={classes.inbox}>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          className={classes.section}
          style={{ backgroundColor: '#fff' }}>
          <Tabs
            currentTab={currentTab || 'account'}
            className={clsx(
              classes.tabs,
              currentTab ? classes.tabInfoHide : ''
            )}
          />
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12} className={classes.section2}>
          <Grid container>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: '-1px' }}
              className={classes.verticalDivider}
            />
            <Grid
              item
              lg={3}
              md={3}
              className={classes.indexexList}
              style={{
                padding: 12,
                height: 'calc(100vh - 90px)',
                overflow: 'auto',
                position: 'sticky',
                top: 82
              }}>
              {termsOfServiceIndexes.map((tnc, index) => (
                <TncIndex
                  key={index}
                  active={currentActiveIndex === tnc.title}
                  clickHandler={() => executeScroll(tnc.title)}>
                  {index + 1}. {tnc.title}
                </TncIndex>
              ))}
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: '-1px' }}
              className={classes.verticalDivider}
            />

            <Grid item lg={9} md={9}>
              <Typography
                style={{
                  fontSize: 20,
                  color: '#6B6B6B',
                  fontWeight: 700,
                  paddingLeft: 12
                }}>
                Terms of Service
              </Typography>
              <TermsOfServiceContent tncRefs={tncRefs} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  if (currentTab === 'drivers') return <Driver setSnackbar={setSnackbar} />;

  return (
    <Grid container className={classes.inbox}>
      <Grid
        item
        lg={3}
        md={3}
        sm={12}
        xs={12}
        className={classes.section}
        style={{ backgroundColor: '#fff' }}>
        <Tabs
          currentTab={currentTab || 'account'}
          className={clsx(classes.tabs, currentTab ? classes.tabInfoHide : '')}
        />
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        className={classes.verticalDivider}
      />

      <Grid item lg={7} md={7} sm={12} xs={12} className={classes.section2}>
        <Typography variant="h3">
          <TabInfo
            activeTab={currentTab || 'account'}
            className={clsx(
              classes.tabInfo,
              currentTab ? '' : classes.tabInfoHide
            )}
            setSnackbar={setSnackbar}
          />
        </Typography>
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  inbox: {},
  indexexList: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabs: {
    position: 'sticky',
    top: 74,
    paddingTop: 0
  },
  verticalDivider: {
    background: '#e5e5e5',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset'
    }
  },
  section: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  section2: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  tabInfoHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabInfo: {
    marginTop: theme.spacing(2)
  }
}));

const mapStateToProps = state => ({
  drivers_loading: state.Driver.drivers_loading,
  drivers: state.Driver.drivers,
  user_error: state.User.user_error,
  user_message: state.User.user_message,
  payment_error: state.Payment.error
});

export default connect(mapStateToProps)(Settings);

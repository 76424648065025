import React from 'react';
import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';

import { ReactComponent as TickIncompleteIcon } from 'assets/images/bol/tick-incomplete.svg';
import { ReactComponent as TickCompleteIcon } from 'assets/images/bol/tick-complete.svg';

import { getLoadRoutes, removeShipmentPrefix } from 'services/Shipments';
import BoxLayout5 from 'components/BoxLayout5';
import RcCheckbox from 'components/Form/Checkbox';
import DocumentActions from 'containers/Loads/DocumentActions';
import ItemInfo from './ItemInfo/ItemInfo';

import { convertUtcToLocal } from 'utils/timeUtils';
import { bytesToSize, formatUsNumber2 } from 'utils/numberUtils';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { Backdrop } from '@mui/material';
import MyCarousel from '../../../components/carousel/carousel';

const S3_URL = process.env.REACT_APP_S3_URL;

const BOLBody = React.forwardRef((props, ref) => {
  const {
    setSnackbar,
    documentsToShare,
    modifyImageUrlInDocumentsToShare,
    bol,
    damageCodes,
    setOpen,
    setSelectedDamageItem,
    open,
    selectedDamageItem,
    url_load_id
  } = props;

  const classes = useStyles();

  const getMarker = (text, status, isLast = false) => {
    return (
      <Box style={{ display: 'inline-block' }}>
        {status === 'complete' ? <TickCompleteIcon /> : <TickIncompleteIcon />}
        <Box
          style={{
            display: 'inline-block',
            width: 50,
            height: 2,
            background: status === 'complete' ? '#FF2222' : '#D1D3D5',
            visibility: isLast === true ? 'hidden' : 'visible'
          }}></Box>
        <Typography
          style={{
            color: '#707070',
            fontSize: 12,
            fontWeight: 600,
            marginTop: 8,
            marginLeft: '-40px'
          }}>
          {text}
        </Typography>
      </Box>
    );
  };
const imageUrls = bol.shipment_damages.flatMap((damage) => damage.image_url.map((img) => S3_URL + img));

  const getBolStatus = status => {
    if (status === 'transporter_assigned' || status === 'truck_assigned')
      return (
        <Box style={{ marginTop: 16 }}>
          {getMarker('Requested', 'complete')}
          {getMarker('Booked', 'complete')}
          {getMarker('Picked up', 'incomplete')}
          {getMarker('Delivered', 'incomplete', true)}
        </Box>
      );
    else if (status === 'picked_up')
      return (
        <Box style={{ marginTop: 16 }}>
          {getMarker('Requested', 'complete')}
          {getMarker('Booked', 'complete')}
          {getMarker('Picked up', 'complete')}
          {getMarker('Delivered', 'incomplete', true)}
        </Box>
      );
    else if (status === 'delivered')
      return (
        <Box style={{ marginTop: 16 }}>
          {getMarker('Requested', 'complete')}
          {getMarker('Booked', 'complete')}
          {getMarker('Picked up', 'complete')}
          {getMarker('Delivered', 'complete', true)}
        </Box>
      );
    else if (status === 'cancelled')
      return (
        <Box style={{ marginTop: 16 }}>
          {getMarker('Requested', 'complete')}
          {getMarker('Archived', 'complete', true)}
        </Box>
      );
    return (
      <Box style={{ marginTop: 16 }}>
        {getMarker('Requested', 'complete')}
        {getMarker('Booked', 'incomplete')}
        {getMarker('Picked up', 'incomplete')}
        {getMarker('Delivered', 'incomplete', true)}
      </Box>
    );
  };

  let pickup_date = bol.actual_pickup_date || bol.pickup_date;
  let drop_date = bol.actual_delivery_date || bol.delivery_date;

  return (
    <div ref={ref} style={{ background: '#f5f8fa' }}>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}>
        <MyCarousel images={imageUrls} open={open} />
      </Dialog>
      {selectedDamageItem ? (
        <Dialog
          fullWidth
          onClose={() => setSelectedDamageItem(null)}
          open={Boolean(selectedDamageItem)}
          maxWidth="md"
          BackdropComponent={Backdrop}>
          <img src={selectedDamageItem.image} style={{ maxHeight: '90vh' }} />
        </Dialog>
      ) : null}

      <Grid container spacing={4} className={classes.bol}>
        <Grid item lg={7} md={12} sm={12}>
          {/* Left part */}

          {bol.pickup_lat &&
          bol.pickup_long &&
          bol.delivery_lat &&
          bol.delivery_long ? (
            <iframe
              width="100%"
              height="220"
              style={{
                border: 0,
                borderRadius: 12
              }}
              allowFullScreen={false}
              src={getLoadRoutes(bol)}></iframe>
          ) : null}

          {/* Status box start */}

          <BoxLayout5 togallable={false} style={{ marginTop: 20 }}>
            <Box style={{ textAlign: 'center' }}>
              <Typography
                style={{ fontWeight: 700, marginBottom: 16, fontSize: 12 }}>
                Job ID: {removeShipmentPrefix(url_load_id)}
              </Typography>
              <Divider style={{ background: '#eff0f4' }} />

              {/* Status box tick start */}

              {getBolStatus(bol.status)}
            </Box>

            {/* Status box tick end */}
          </BoxLayout5>

          {/* Status box end */}

          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item lg={6} md={6} sm={12} style={{ width: '100%' }}>
              <BoxLayout5
                title="Pickup"
                togallable={false}
                style={{ minHeight: 170 }}>
                <Typography
                  style={{
                    color: '#1A1818',
                    fontSize: 12,
                    fontWeight: 500,
                    marginTop: 16
                  }}>
                  {bol.pickup_full_address}
                </Typography>
                <Typography
                  style={{
                    color: '#9A9A9A',
                    fontSize: 12,
                    marginTop: 6,
                    fontWeight: 600
                  }}>
                  {formatUsNumber2(bol.pickup_contact_person_ph)}
                </Typography>
                <Typography
                  style={{
                    color: '#9A9A9A',
                    fontSize: 12,
                    marginTop: 6,
                    fontWeight: 600
                  }}>
                  {pickup_date ? convertUtcToLocal(pickup_date) : ''}
                </Typography>
              </BoxLayout5>
            </Grid>
            <Grid item lg={6} md={6} sm={12} style={{ width: '100%' }}>
              <BoxLayout5
                title="Delivery"
                togallable={false}
                style={{ minHeight: 170 }}>
                <Typography
                  style={{
                    color: '#1A1818',
                    fontSize: 12,
                    fontWeight: 500,
                    marginTop: 16
                  }}>
                  {bol.delivery_full_address}
                </Typography>
                <Typography
                  style={{
                    color: '#9A9A9A',
                    fontSize: 12,
                    marginTop: 6,
                    fontWeight: 600
                  }}>
                  {formatUsNumber2(bol.delivery_contact_person_ph)}
                </Typography>
                <Typography
                  style={{
                    color: '#9A9A9A',
                    fontSize: 12,
                    marginTop: 6,
                    fontWeight: 600
                  }}>
                  {drop_date ? convertUtcToLocal(drop_date) : ''}
                </Typography>
              </BoxLayout5>
            </Grid>
          </Grid>

          <BoxLayout5 title="Damage code definitions" style={{ marginTop: 30 }}>
            <Box style={{ marginTop: 20 }}>
              {damageCodes.map((damage, index) => (
                <Box key={index}>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      style={{
                        width: 30,
                        height: 30,
                        minWidth: 30,
                        minHeight: 30,
                        background: '#ABACC3',
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <Typography
                        style={{
                          color: '#fff',
                          fontWeight: 700,
                          fontSize: 12
                        }}>
                        {damage.abbreviation}
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        color: '#969696',
                        fontWeight: 500,
                        fontSize: 12,
                        marginLeft: 20
                      }}>
                      {damage.damage_name}
                    </Typography>
                  </Box>
                  <Divider
                    style={{
                      background: '#eff0f4',
                      marginTop: 8,
                      marginBottom: 8
                    }}
                  />
                </Box>
              ))}
              <Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#2AB4B8'
                    }}></Box>
                  <Typography
                    style={{
                      color: '#969696',
                      fontWeight: 500,
                      fontSize: 12,
                      marginLeft: 20
                    }}>
                    Pick-up damages
                  </Typography>
                </Box>
                <Divider
                  style={{
                    background: '#eff0f4',
                    marginTop: 8,
                    marginBottom: 8
                  }}
                />
              </Box>
              <Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#E8221F'
                    }}></Box>
                  <Typography
                    style={{
                      color: '#969696',
                      fontWeight: 500,
                      fontSize: 12,
                      marginLeft: 20
                    }}>
                    Delivery damages
                  </Typography>
                </Box>
              </Box>
            </Box>
          </BoxLayout5>

          {/* Left part end */}
        </Grid>
        <Grid item lg={5} md={12} sm={12} style={{ width: '100%' }}>
          {/* Right part */}

          {bol.shipment_items.length ? (
            <BoxLayout5
              title={`${
                bol.category === 'vehicle' ? 'Vehicle' : 'Item'
              } information and Pictures`}>
              <ItemInfo
                category={bol.category}
                subCategory={bol.sub_category}
                shipment_items={bol.shipment_items}
                shipment_damages={bol.shipment_damages}
                setSelectedDamageItem={setSelectedDamageItem}
              />

              <Typography
                style={{
                  color: '#EF3B2D',
                  fontWeight: 600,
                  marginLeft: 16,
                  cursor: 'pointer',
                  fontSize: 12
                }}
                onClick={() => setOpen(true)}>
                {bol.shipment_damages.length ? 'View all pictures' : ''}
              </Typography>
            </BoxLayout5>
          ) : null}

          <BoxLayout5 title="Documents" style={{ marginTop: 25 }}>
            {bol.bol_url ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <Box style={{ width: 200, whiteSpace: 'nowrap' }}>
                  <RcCheckbox
                    label={'...' + bol.bol_url.split('-').pop()}
                    checked={
                      documentsToShare.image_url.length &&
                      documentsToShare.image_url.includes(bol.bol_url)
                        ? true
                        : false
                    }
                    changeHandler={() =>
                      modifyImageUrlInDocumentsToShare(bol.bol_url)
                    }
                  />
                </Box>
                <Box style={{ width: 40 }}>
                  <Typography>BOL</Typography>
                </Box>
                <Box style={{ width: 80 }}>
                  <Typography>60KB</Typography>
                </Box>
                <DocumentActions
                  document={{ s3_bucket_url: bol.bol_url }}
                  shipment_id = {url_load_id}
                  setSnackbar={setSnackbar}
                />
              </Box>
            ) : null}
            {bol.shipment_media.length
              ? bol.shipment_media.map((media, index) => (
                  <Box
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Box style={{ width: 200, whiteSpace: 'nowrap' }}>
                      <RcCheckbox
                        label={
                          media.file_name.length > 20
                            ? '...' +
                              media.file_name.substr(
                                media.file_name.length - 20
                              )
                            : media.file_name
                        }
                        checked={
                          documentsToShare.image_url.length &&
                          documentsToShare.image_url.includes(
                            media.s3_bucket_url
                          )
                            ? true
                            : false
                        }
                        changeHandler={() =>
                          modifyImageUrlInDocumentsToShare(media.s3_bucket_url)
                        }
                      />
                    </Box>
                    <Box style={{ width: 40 }}>
                      <Typography>Doc</Typography>
                    </Box>
                    <Box style={{ width: 80 }}>
                      <Typography>{bytesToSize(media.file_size)}</Typography>
                    </Box>
                    <DocumentActions
                      document={media}
                      setSnackbar={setSnackbar}
                    />
                  </Box>
                ))
              : null}
          </BoxLayout5>

          {/* Right part end */}
        </Grid>
      </Grid>
    </div>
  );
});

const useStyles = makeStyles(theme => ({
  corousel: {
    textAlign: 'center'
  },
  bol: {
    marginTop: 10,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  }
}));

export default BOLBody;

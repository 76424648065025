import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getToken,
  setClient,
  setConvo,
  setLastMsgs,
  setMessages,
  setNewMessage,
  setTwilioStatus
} from 'reducers/Inbox';
import {
  Message,
  Conversation,
  Participant,
  Client,
  ConnectionState
} from '@twilio/conversations';

const TwilioInit = ({
  user,
  token,
  getToken,
  setClient,
  client,
  setConvo,
  currentConvo,
  setMessages,
  setLastMsgs,
  setTwilioStatus,
  setNewMessage
}) => {
  const getTwilioToken = async () => {
    if (user) getToken();
  };

  useEffect(() => {
    getTwilioToken();
  }, []);

  useEffect(() => {
    if (token) {
      const client = new Client(token);
      setClient(client);
    }
  }, [token]);

  // useEffect(() => {
  //   if (currentConvo) {
  //     currentConvo.on('messageAdded', async message => {
  //       const messages = await currentConvo.getMessages(50);
  //       setMessages(messages);
  //     });
  //   }
  // }, [currentConvo]);

  useEffect(() => {
    if (client) {
      client.on('connectionStateChanged', state => {
        // connecting, connected, disconnecting, disconnected, denied
        setTwilioStatus(state);
      });

      client.on('tokenAboutToExpire', async () => {
        await getTwilioToken();
        client.updateToken(token);
      });

      client.on('tokenExpired', () => {
        getTwilioToken();
      });

      client.on('conversationJoined', async conversation => {
        setConvo(conversation);

        const msgs = await conversation.getMessages(1);

        const msgOb = {
          convoId: conversation.sid,
          msg:
            msgs.items.length === 0
              ? ''
              : msgs.items[0]?.media
              ? 'FILE'
              : msgs.items[0]?.body
        };

        setLastMsgs(msgOb);
      });

      client.on('conversationUpdated', async ({ conversation }) => {
        setConvo(conversation);
      });

      client.on('messageAdded', message => {
        setNewMessage(message);
        const msgOb = {
          convoId: message.conversation.sid,
          msg: message.media ? 'FILE' : message.body
        };
        setLastMsgs(msgOb, true); // If true, delete existing msg with same convo id.
      });
    }
  }, [client, token]);

  return null;
};

const mapStateToProps = state => ({
  token: state.Inbox.token,
  client: state.Inbox.client,
  user: state.User.user_data,
  currentConvo: state.Inbox.currentConvo,
  messages: state.Inbox.messages
});

const mapDispatchToProps = dispatch => {
  return {
    getToken: () => dispatch(getToken()),
    setClient: client => dispatch(setClient(client)),
    setConvo: convo => dispatch(setConvo(convo)),
    setMessages: messages => dispatch(setMessages(messages)),
    setLastMsgs: (data, bool) => dispatch(setLastMsgs(data, bool)),
    setTwilioStatus: status => dispatch(setTwilioStatus(status)),
    setNewMessage: message => dispatch(setNewMessage(message))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwilioInit);

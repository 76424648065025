import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import RcTextfield from 'components/Form/Textfield';
import ButtonWithBackground from 'components/Form/Button';
import { confirmPasswordService } from 'services/Verification';
import { ERROR, SUCCESS } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { LOGIN_PAGE } from 'utils/routesUtils';
import { Box, Grid, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { validatePasswordMessage } from 'utils/validationUtils';
import ValidationPassward from '../ValidationPassword';
const ConfirmPassword = ({ setSnackbar, email, otp }) => {
  const [submitClicked, setSubmitClicked] = useState(false);

  const formDefaultValues = {
    loading: false,
    password: '',
    cpassword: ''
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: formDefaultValues
  });

  const history = useHistory();

  const onSubmit = async data => {
    setValue('loading', true);
    const passMessage = validatePasswordMessage(data.password);
    if (data.password !== data.cpassword) {
      setSnackbar({
        type: ERROR,
        message: 'New password & confirm password mismatched!'
      });
    } else if (passMessage !== true) {
      setSnackbar({
        type: ERROR,
        message: passMessage
      });
    } else {
      try {
        const res = await confirmPasswordService(email, otp, data.password);
        if (res.validate === true)
          setSnackbar({
            type: SUCCESS,
            message: 'Password has been changed!'
          });
        else
          setSnackbar({
            type: ERROR,
            message: errorMessageSingleLine()
          });

        history.push(LOGIN_PAGE);
      } catch (error) {
        setSnackbar({
          type: ERROR,
          message: errorMessageSingleLine(error.data)
        });
      }
    }
    setValue('loading', false);
  };
let passward=(watch('password'));
 const handleClick = () => {
    setSubmitClicked(true);  
  };
  return (
    <>
      <Box className="form-box">
        <Typography variant="h3" className="heading">
          Create new password
        </Typography>
        <Typography
          style={{ textAlign: 'center', color: '#6B6B6B', marginBottom: 40 }}>
          Your new password must be different from previously used passwords.
        </Typography>
        {/* <Typography
          style={{ marginBottom: 40, textAlign: 'center', color: '#6B6B6B' }}>
          previously used passwords.
        </Typography> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <RcTextfield
                InputLabelProps={{ shrink: false }}
                type="password"
                placeholder="New password"
                value={watch('password')}
                error={errors.password}
                {...register('password', {
                  required: 'Please enter new password',
                  minLength: {
                    value: 8,
                    message: 'Enter at least 8 characters'
                  }
                })}
                changeHandler={e => setValue('password', e.target.value)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <RcTextfield
                InputLabelProps={{ shrink: false }}
                type="password"
                placeholder="Re-enter password"
                value={watch('cpassword')}
                error={errors.cpassword}
                {...register('cpassword', {
                  required: 'Please enter confirm password',
                  minLength: {
                    value: 8,
                    message: 'Enter at least 8 characters'
                  }
                })}
                changeHandler={e => setValue('cpassword', e.target.value)}
              />
            </Grid>
            <ValidationPassward passward={passward} submitClicked={submitClicked} />

            <Grid item lg={12} sm={12} md={12} xs={12}>
              <ButtonWithBackground
                label="Change password"
                fullWidth
                size="large"
                buttonType="submit"
                disabled={watch('loading')}
                style={{ fontWeight: 600 }}
                clickHandler={handleClick}

              />
              <Box style={{ textAlign: 'center', marginTop: 20 }}>
                <Link
                  to={LOGIN_PAGE}
                  style={{ color: '#EF3B2D', fontWeight: 600 }}>
                  Back to login
                </Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ConfirmPassword;

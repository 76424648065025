import React from 'react';
import {
  makeStyles,
  createStyles,
  FormControl,
  FormHelperText,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const RcCheckbox = React.forwardRef(
  ({ label, checked, error, className, changeHandler, ...rest }, ref) => {
    const userType = getUserTypeService();
    const classes = useStyles({ userType });

    return (
      <FormControl required error={Boolean(error)} ref={ref}>
        <FormControlLabel
          className={classes.controlLabel}
          control={
            <Checkbox
              disableRipple
              style={{ background: 'transparent' }}
              color="primary"
              checked={checked}
              onChange={changeHandler}
              className={clsx(checked ? classes.checked : '', className)}
              {...rest}
            />
          }
          label={label}
        />

        {error && (
          <FormHelperText className={classes.helperText}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

RcCheckbox.defaultProps = {
  checked: false,
  error: ''
};

RcCheckbox.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme =>
  createStyles({
    checked: {
      color: props =>
        props.userType === SHIPPER
          ? `${theme.palette.secondary.dark} !important`
          : `${theme.palette.primary.main} !important`
    },
    controlLabel: {
      marginBottom: 0
    },
    helperText: {
      marginTop: 0
    }
  })
);

export default RcCheckbox;

import axiosClient from '../utils/axiosClient';

const version = process.env.REACT_APP_API_VER;

const getBooleanFlag = async (flag_name: string) => {
  let url = `/core/${version}/get_flag/?flag_name=${flag_name}`;
  try {
    const response = await axiosClient.get(url);
    return response.data[flag_name];
  } catch (error) {
    console.log('Error fetching feature flag: ' + error.response);
    return false;
  }
};

const getBooleanSwitch = async (switch_name: string) => {
  let url = `/core/${version}/get_switch/${switch_name}`;
  try {
    const response = await axiosClient.get(url);
    return response.data[switch_name];
  } catch (error) {
    console.log('Error fetching feature switch: ' + error.response);
    return false;
  }
};

export { getBooleanSwitch, getBooleanFlag };

import React from 'react'
import { Box, Typography, makeStyles, Grid, Divider } from '@material-ui/core';
import { ReactComponent as RightIcon } from 'assets/images/common/RightIcon.svg';
import { ReactComponent as RightIcon2 } from 'assets/images/common/RightIcon2.svg';
import { ReactComponent as BasicIcon } from 'assets/images/common/basciIcon.svg';

const Payment_details = ({
    plan,
    subHeading,
    amount
}) => {
    const classes = useStyles();
    const textArray1 = [
        "Web & mobile app access",
        "Limited TMS access",
        "Up to 3 active driver accounts",
        "Up to 100 imported jobs/ month",
        "Generate eBOLs",
        "Electronic condition reports",
        "Performance metrics"
    ];
    const textArray2 = [
        "Everything in Free",
        "Unlimited imported jobs",
        "Unlimited active driver accounts",
        "Contact book",
        "Invoicing",
        "Book loads",
        "In-app messaging"
    ];

    const renderIcon = () => {
        let iconComponent = null;
    if (plan === 'Premium') {
            iconComponent = <BasicIcon />;
        }
    return iconComponent;
    };
    
    const condition = () => {
        if (plan === 'Free') {
            return textArray1;
        } else if (plan === 'Premium') {
            return textArray2;
        }
    };
    const text = () => {
        let iconComponent;
        switch (plan) {
            case 'Premium':
                iconComponent = <RightIcon2 />
                break;
            case 'Free':
                iconComponent = <RightIcon />
                break;
        }

        return (
            <>
                {(condition()).map((text, index) => (
                    <Box
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center'
                        }}
                    >
                        <Box>
                            {iconComponent}
                        </Box>
                        <Typography
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                marginLeft: 12,
                                marginBottom: 16,
                                whiteSpace: 'nowrap',
                                color: plan === "Free" ? "#000000" : "#000000"
                            }}
                        >
                            {text}
                        </Typography>
                    </Box>
                ))}
            </>
        );
    };

    return (
        <Box className={classes.container}>
            <div className={classes.flexContainer} style={{
                backgroundColor: plan === "Premium" ? "#ED3D2E" : "none"
            }}>
                <Typography
                    style={{
                        fontSize: 20,
                        color: plan === "Free" ? "1B1B1B" : "#ffffff",
                        fontWeight: 600
                    }}>
                    {plan}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography
                        style={{
                            fontSize: 12,
                            color: plan === "Free" ? "#1B1B1B" : "#ffffff",
                            fontWeight: 500
                        }}
                    >
                        {subHeading}
                    </Typography>
                    {plan === "Free" && <div style={{ width: 50 }}></div>}
                </div>

                <Typography
                    style={{
                        fontSize: 40,
                        color: plan === "Free" ? "1B1B1B" : "#ffffff",
                        fontWeight: 700,
                        marginTop: 10
                    }}
                >
                    ${amount}
                </Typography>
                <Typography
                    style={{
                        fontSize: 12,
                        marginBottom: 20,
                        color: plan === "Free" ? "1B1B1B" : "#ffffff",
                        fontWeight: 700,
                        visibility: plan === "Free" ? "hidden" : "visible",
                        opacity: plan === "Free" ? 0 : 1,

                    }}
                >
                    per active driver /month
                </Typography>
                <Divider style={{
                    marginLeft: 10,
                    color: '#D1D1D1',
                    marginRight: 10
                }} />
            </div>
            <Box className={classes.features} style={{
                backgroundColor: plan === "Premium" ? "#ffffff" : "none",
            }}>
                {text()}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    flexContainer: {
        padding: 20,
        paddingRight: 0,
        borderTopLeftRadius: 15,
        marginBottom: 20,
        borderTopRightRadius: 15,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    iconbox: {
        display: 'flex',
        paddingRight: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 40
    },
    features: {
        padding: 20,
        marginTop: -40,
        justifyContent: 'center',
        alignItems: 'center',
        Border: 'none',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15
    }
}))
export default Payment_details

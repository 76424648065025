import React from 'react';
import { Table, TableContainer, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const CustomTable = props => {
  const { children, className, isStickyHeader, noBorder, scrolling } = props;

  const classes = useStyles({ noBorder });

  return (
    <TableContainer
      className={clsx(classes.container, 'table-container',className)}
      onScroll={e => {
        if (scrolling) scrolling(e);
      }}>
      <Table stickyHeader={isStickyHeader} className={classes.table}>
        {children}
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    borderTopLeftRadius: theme.spacing(4.5),
    borderTopRightRadius: theme.spacing(4.5)
  },
  table: {
    border: props => (props.noBorder ? 'initial' : '1px solid #cccc')
  }
}));

export default CustomTable;

//declarations
const SET_NEWSHIPMENT_CATEGORY = "SET_NEWSHIPMENT_CATEGORY"
const SET_NEWSHIPMENT_SUBCATEGORY = "SET_NEWSHIPMENT_SUBCATEGORY"
const SET_NEWSHIPMENT_ITEMS = "SET_NEWSHIPMENT_ITEMS"
const SET_NEWSHIPMENT_TRANSITDETAILS = "SET_NEWSHIPMENT_TRANSITDETAILS"
const RESET_NEWSHIPMENT = "RESET_NEWSHIPMENT"

//initial state
const initialState = {
  category: null,
  subCategory: null,
  items: [],
  transitDetails: null,
}

//reducer functions
export const setShipmentCategory = (data) => {
  return {
    type: SET_NEWSHIPMENT_CATEGORY,
    data
  }
}

export const setShipmentSubCategory = (data) => {
  return {
    type: SET_NEWSHIPMENT_SUBCATEGORY,
    data
  }
}

export const setShipmentItems = (data) => {
  return {
    type: SET_NEWSHIPMENT_ITEMS,
    data
  }
}

export const setShipmentTransit = (data) => {
  return {
    type: SET_NEWSHIPMENT_TRANSITDETAILS,
    data
  }
}

export const resetShipment = (data) => {
  return {
    type: RESET_NEWSHIPMENT,
    data
  }
}

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NEWSHIPMENT_CATEGORY:
      return {
        ...state,
        category: action.data
      }
    case SET_NEWSHIPMENT_SUBCATEGORY:
      return {
        ...state,
        subCategory: action.data
      }
    case SET_NEWSHIPMENT_ITEMS:
      return {
        ...state,
        items: action.data
      }
    case SET_NEWSHIPMENT_TRANSITDETAILS:
      return {
        ...state,
        transitDetails: action.data
      }
    case RESET_NEWSHIPMENT:
      return {
        ...state,
        ...action.data
      }
    default:
      return state;
  }
}
const capitalizeFirst = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const convertToLabel = (str: string = '') => {
  if (!str) return '';

  return capitalizeFirst(str.replace(/_/g, ' '));
};

const makePrural = (s: string) => {
  if (typeof s !== 'string') return '';
  return s + 's';
};

const showIdNumOnly = (s: string) => {
  if (typeof s !== 'string') return '';
  const splited = s.split('-');
  return splited[2];
};
const capitalizedFirstArry = function(s: string) {
  let str = s.split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substring(1);
  }
  return str.join(' ');
};
const convertToLabelArry = (str: string = '') => {
  if (!str) return '';

  return capitalizedFirstArry(str.replace(/_/g, ' '));
};
const yesOrNo = (val: boolean) => (val === true ? 'Yes' : 'No');

const formatName=(fullName:string)=> {
  const names = fullName.split(' ');
  const firstName = names[0].charAt(0).toUpperCase() + names[0].slice(1);
  const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase();

  return `${firstName} ${lastNameInitial}`;
}

const termsOfServiceIndexes = [
  { title: 'License and Acceptable Use.' },
  { title: 'Definitions.' },
  { title: 'Open Software Platform.' },
  { title: 'Access to Site and Services.' },
  { title: 'Responsibilities of Shippers and Carriers.' },
  { title: 'Insurance Coverage for Carriers.' },
  { title: 'Payment.' },
  { title: 'Fees.' },
  { title: 'Mobile Application Usage.' },
  { title: 'Information You Submit.' },
  { title: 'Prohibited and Restricted Items.' },
  { title: 'Prohibited Activities.' },
  { title: 'Dispute Resolution.' },
  { title: 'No Agency.' },
  { title: 'Release.' },
  { title: 'Confidentiality/Non-Disclosure.' },
  { title: 'Recordkeeping.' },
  { title: 'Remedies.' },
  { title: 'Limited Liability and No Warranty.' },
  { title: 'Indemnity.' },
  { title: 'Legal Compliance.' },
  { title: 'Arbitration and Waiver of Class Actions.' },
  { title: 'Trademark and Domain Name Protection.' },
  { title: 'Disclaimer of Third-Party Materials and Links.' },
  { title: 'Security.' },
  { title: 'Governing Law.' },
  { title: 'Other Terms and Conditions.' },
  { title: 'Notices.' },
  { title: 'Miscellaneous.' }
];

const privacyPoliciyIndexes = [
  { title: 'Overview.' },
  { title: 'Personal Data we collect.' },
  { title: 'Log Files.' },
  { title: 'Tracking Technologies.' },
  { title: 'How we use your personal data.' },
  { title: 'Information Sharing and Disclosure.' },
  { title: 'Roadcall.co Community.' },
  { title: 'External Service Providers.' },
  { title: 'Internal Service Providers for Our Operations.' },
  { title: 'Frames.' },
  { title: 'Legal Requests.' },
  { title: 'Communications from the Site.' },
  { title: 'Access to Personal Information.' },
  { title: 'Security.' },
  { title: 'International Privacy Laws.' },
  { title: 'Business Transitions.' },
  { title: 'Changes in this Privacy Policy.' },
  { title: 'Contact Us.' }
];

export {
  capitalizeFirst,
  makePrural,
  showIdNumOnly,
  yesOrNo,
  convertToLabel,
  capitalizedFirstArry,
  convertToLabelArry,
  formatName,
  termsOfServiceIndexes,
  privacyPoliciyIndexes
};

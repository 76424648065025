import React from 'react';

// Material UI.
import { List, makeStyles } from '@material-ui/core';

// Components.
import Tab from './Tab';
import { PRIVACY_POLICY_PAGE, TERMS_OF_SERVICE_PAGE } from 'utils/routesUtils';
import { getUserTypeService } from 'services/login';
import { BROKER, CARRIER } from 'utils/users';

// Icons.
import { ReactComponent as ProfileIcon } from 'assets/images/settings/profile.svg';
import { ReactComponent as PaymentsIcon } from 'assets/images/settings/payments.svg';
import { ReactComponent as TermsIcon } from 'assets/images/settings/terms.svg';
import { ReactComponent as DocumentsIcon } from 'assets/images/settings/documents.svg';
import { ReactComponent as ProfileActiveIcon } from 'assets/images/settings/profile-active.svg';
import { ReactComponent as PaymentsActiveIcon } from 'assets/images/settings/payments-active.svg';
import { ReactComponent as TermsActiveIcon } from 'assets/images/settings/terms-active.svg';
import { ReactComponent as DocumentsActiveIcon } from 'assets/images/settings/documents-active.svg';
import { ReactComponent as DriversActiveIcon } from 'assets/images/settings/drivers-active.svg';
import { ReactComponent as DriversIcon } from 'assets/images/settings/driver.svg';

import withDevice from 'components/HOC/withDevice';

const Tabs = ({ currentTab, isMobile, className }) => {
  const classes = useStyles();

  const userType = getUserTypeService();

  return (
    <div className={classes.tabsContainer}>

    <List className={classes.list}>
      <Tab
        name="account"
        detail="Edit your personal information and update your business profile picture."
        icon={currentTab === 'account' ? ProfileActiveIcon : ProfileIcon}
      />
        {userType === CARRIER ? (<Tab
          name="payout"
          label="Payout"
          detail="View and manage how you get paid."
          icon={currentTab === 'payout' ? PaymentsActiveIcon : PaymentsIcon}
        />
        ) : (
          <Tab
            name="payment"
            label="Payment"
            detail="View and manage payment method and payouts to carriers."
            icon={currentTab === 'payment' ? PaymentsActiveIcon : PaymentsIcon}
          />
        )}
       {userType === CARRIER && (
        <Tab
          name="drivers"
          detail="View, manage and add drivers to keep your fleet in check and running smoothly."
          icon={currentTab === 'drivers' ? DriversActiveIcon : DriversIcon}
        />
      )} 
      <Tab
        name="privacy_policy"
        detail="Let’s get down to the nitty-gritty details with some boring legal stuff."
        icon={currentTab === 'privacy_policy' ? TermsActiveIcon : TermsIcon}
        externalLink={isMobile ? PRIVACY_POLICY_PAGE : ''}
      />
      <Tab
        name="terms_of_service"
        detail="Let’s get down to the nitty-gritty details with some boring legal stuff."
        icon={currentTab === 'terms_of_service' ? TermsActiveIcon : TermsIcon}
        externalLink={isMobile ? TERMS_OF_SERVICE_PAGE : ''}
      />
      {userType === CARRIER && (
        <Tab
          name="documents"
          detail="View and manage carrier verification documents."
          icon={
            currentTab === 'documents' ? DocumentsActiveIcon : DocumentsIcon
          }
        />
      )}
       <Tab
        name="report_a_bug"
        label="Report a bug"
        detail="Hit a road Bump? Let us know the details so we can work to get this fixed in no time."
        icon={
          currentTab === 'report_a_bug' ? TermsActiveIcon : TermsIcon
        }
      />
        {userType === CARRIER && (<Tab
          name="plans_and_pricing"
          label="Plans and Pricing"
          detail="View and manage your subscription."
          icon={
            currentTab === 'Plans_and_pricing' ? TermsActiveIcon : TermsIcon
          }
        />
        )}
        {userType === CARRIER && (<Tab
          name="accounts_and_billing"
          label="Accounts and Billing"
          detail="View and manage subscription summary and payment method."
          icon={
            currentTab === 'accounts_and_billing' ? TermsActiveIcon : TermsIcon
          }
        />
        )}
    </List>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    position: 'sticky',
    top: 74,
    paddingTop: 0,
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
  },
  list: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}));

export default withDevice(Tabs);

//declarations
const SET_FILTER_SETTINGS = "SET_FILTER_SETTINGS"
const SET_FILTER_MODE = "SET_FILTER_MODE"
const RESET_FILTER = "RESET_FILTER"

//initial state
const initialState = {
  filter_settings: null,
  filter_mode: 'by_id',
}

//reducer functions
export const setFilterSettings = (data) => {
  return {
    type: SET_FILTER_SETTINGS,
    data
  }
}

export const setFilterMode = (data) => {
  return {
    type: SET_FILTER_MODE,
    data
  }
}

export const resetFilter = () => {
  return {
    type: RESET_FILTER
  }
}

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FILTER_SETTINGS:
      return {
        ...state,
        filter_settings: action.data
      }
    case SET_FILTER_MODE:
      return {
        ...state,
        filter_mode: action.data
      }
    case RESET_FILTER:
      return {
        ...initialState
      }
    default:
      return state;
  }
}
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  createStyles,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField
} from '@material-ui/core';
import { errorTypeToLabel } from 'utils/errorUtils';
import SpinLoader from 'components/loader/SpinLoader';

const RcDropdown = React.forwardRef(
  (
    {
      label,
      disabled,
      loading,
      items,
      error,
      className,
      value,
      changeHandler,
      dropdownType,
      ...rest
    },
    ref
  ) => {
    const errorText =
      typeof error === 'object' && error.type
        ? errorTypeToLabel(error.type, error.message)
        : error;

    const classes = useStyles();

    return (
      <TextField
        ref={ref}
        InputLabelProps={{ shrink: true }}
        label={label}
        disabled={disabled || loading}
        error={Boolean(errorText)}
        helperText={errorText}
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       {loading && <SpinLoader size={20} className={classes.progress} />}
        //     </InputAdornment>
        //   )
        // }}
        variant="outlined"
        fullWidth
        select
        className={clsx(
          dropdownType === 'secondary' ? 'inpt-fld-select' : 'input-box',
          value ? classes.root : null,
          className
        )}
        value={value}
        onChange={e => {
          if (changeHandler) changeHandler(e);
        }}
        {...rest}>
        {items &&
          items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.value}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                {item.label}
                {item.image_url && <img src={item.image_url} />}
              </MenuItem>
            );
          })}
      </TextField>
    );
  }
);

RcDropdown.defaultProps = {
  label: '',
  disabled: false,
  loading: false,
  items: []
};

RcDropdown.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool
};

const useStyles = makeStyles(theme =>
  createStyles({
    progress: {
      color: theme.palette.primary.dark
    },
    root: {
      '& > div > div': {
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }
    }
  })
);

export default RcDropdown;

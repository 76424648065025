import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DownArrowIcon from 'assets/images/common/down-black.png';

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';

const BoxLayout3 = props => {
  const { children, className, collapse, title, ...rest } = props;

  const [open, setOpen] = useState(collapse);

  const classes = useStyles({ isOpen: open });

  return (
    <Box className={clsx(classes.box, className)} {...rest}>
      <Box className={classes.header} onClick={() => setOpen(!open)}>
        <Box>
          <Typography style={{ fontWeight: 600 }}>{title}</Typography>
        </Box>
        <Box>
          <img
            alt={open ? 'Open' : 'Close'}
            src={DownArrowIcon}
            className={clsx(classes.icon, open ? classes.collapse : '')}
            width="12"
          />
        </Box>
      </Box>
      <Box className={clsx(classes.info, open ? classes.infoHide : '')}>
        {children}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    box: {
      borderRadius: 8
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      background: '#f7f7f7',
      padding: 12,
      paddingLeft: 20,
      paddingRight: 20,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: props => (props.isOpen ? 8 : 0),
      borderBottomRightRadius: props => (props.isOpen ? 8 : 0)
    },
    icon: {
      transform: 'rotate(180deg)',
      transition: 'transform 0.5s',
      cursor: 'pointer'
    },
    collapse: {
      transform: 'unset'
    },
    info: {
      padding: theme.spacing(2.5),
      paddingBottom: 10,
      background: '#f7f7f7',
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1)
    },
    infoHide: {
      display: 'none'
    }
  })
);

BoxLayout3.defaultProps = {
  collapse: false
};

BoxLayout3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  collapse: PropTypes.bool
};

export default BoxLayout3;

import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const SpinLoader = (props: any) => {
  const classes = useStyles();

  const userType = getUserTypeService();

  return (
    <CircularProgress
      className={userType === SHIPPER ? classes.progress2 : classes.progress}
      {...props}
    />
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    progress: {
      color: theme.palette.primary.dark
    },
    progress2: {
      color: theme.palette.secondary.dark
    }
  })
);

export default SpinLoader;

import React from 'react';

// Material UI.
import { Typography } from '@material-ui/core';

// Components.
import { connect } from 'react-redux';

const PaymentMethodErrors = ({ stripeAccounts }) => {
  return (
    <>
      {stripeAccounts && stripeAccounts.payouts_enabled === false ? (
        <Typography style={{ color: '#ee392c', fontSize: 12, marginTop: 8 }}>
          Payout disabled
        </Typography>
      ) : null}

      {stripeAccounts && stripeAccounts.requirements.currently_due.length ? (
        <>
          <Typography style={{ color: '#ee392c', fontSize: 12, marginTop: 8 }}>
            Currently due
          </Typography>
          {stripeAccounts.requirements.currently_due.map(
            (currently_due, index) => (
              <Typography style={{ fontSize: 10 }} key={index} variant="h6">
                {++index}: {currently_due}
              </Typography>
            )
          )}
        </>
      ) : null}
    </>
  );
};



const mapStateToProps = state => ({
  stripeAccounts: state.Payment.stripeAccounts
});

export default connect(mapStateToProps)(PaymentMethodErrors);

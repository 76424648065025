import React from 'react';
import Skeleton from './Skeleton';
import { CardHeader, Divider, Grid } from '@material-ui/core';
import DriverSingleLoading from './DriverSingleLoading';

const DriverListLoading = () => {
  return (
    <Grid
      item
      lg={3}
      md={3}
      style={{
        padding: 12
      }}>
      <DriverSingleLoading />
      <Divider orientation="horizontal" style={{ background: '#e4e5e4' }} />
      <DriverSingleLoading />
      <Divider orientation="horizontal" style={{ background: '#e4e5e4' }} />
      <DriverSingleLoading />
    </Grid>
  );
};

export default DriverListLoading;

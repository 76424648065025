import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link, useLocation, useHistory, matchPath } from 'react-router-dom';

import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';

import { connect } from 'react-redux';

import {
  INBOX_PAGE,
  JOB_COPY_PAGE,
  JOB_EDIT_PAGE,
  LOADS,
  POST_JOB_PAGE,
  routeToLable,
  SETTINGS_PAGE,
  TRUCKS_ADD_PAGE,
  TRUCKS_EDIT_PAGE,
  TRUCKS_PAGE,
  DRIVERS_PAGE,
  CONTACT_BOOK
} from 'utils/routesUtils';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';
import projectLogo from 'assets/images/logos/rc_logomark_192px.png';
import { ReactComponent as PlusIcon } from 'assets/images/common/Plus.svg';

import HeaderLogo from './HeaderLogo';
import HeaderUserbox from './HeaderUserbox';
import { setAddCustomerModelOpen } from 'reducers/Job';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import NotificationBell from './NotificationBell';
import { getUserTypeService } from 'services/login';
import { ADMIN, CARRIER, SHIPPER } from 'utils/users';
import ButtonWithBackground from 'components/Form/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import withDevice from 'components/HOC/withDevice';
import { DASHBOARD_PAGE,USERS} from '../../utils/routesUtils';

const Header = (props) => {
  const { setSnackbar, isMobile } = props;
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const param_tab = params.get('tab');
  const param_id = params.get('id');

  const history = useHistory();

  const userType = getUserTypeService();

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const { headerFixed, sidebarToggleMobile, setSidebarToggleMobile, setAddCustomerModelOpen } = props;

  const isHeaderCurvy = () => {
    if (isMobile) return false;
    return location.pathname !== LOADS;
  };

  const classes = useStyles({
    headerCurvy: isHeaderCurvy(),
    userType
  });

  const addLoadButton = () => {
    if (userType === ADMIN) return null;
    else if (location.pathname === LOADS)
      return (
        <ButtonWithBackground
          clickHandler={() =>
            history.push({
              pathname: POST_JOB_PAGE
            })
          }
          label={userType === SHIPPER ? 'Post a load' : 'Add a load'}
          size="medium"
          style={{
            marginRight: 20,
            borderRadius: 11,
            fontWeight: 700,
            paddingRight: 30,
            paddingLeft: 30,
            marginBottom: 0,
            fontSize: 10
          }}
        />
      );
  };

  const addContact = () => {
    return (<div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <PlusIcon style={{ marginLeft: -2, marginRight: 2, height: 24 }} />
      <Typography style={{ fontWeight: 600, fontSize: 10 }}>Add Contact</Typography>
    </div>)
  }

  const addContactButton = () => {
    return (
      <ButtonWithBackground
        size="medium"
        style={{
          marginRight: 20,
          borderRadius: 11,
          fontWeight: 700,
          paddingLeft: 10,
          paddingRight: 30,
          paddingTop: -12,
          paddingBottom: -12,
          marginBottom: 0,
          fontSize: 10
        }}
        clickHandler={() => { setAddCustomerModelOpen(true) }}
        label={addContact()}
      />
    )
  }

  const previousPageWasLoadDetail = () => {
    return (
      document.referrer &&
      document.referrer.includes('loads?id=') &&
      !location.state
    );
  };

  const backButton = () => {
    if (
      location.pathname === POST_JOB_PAGE ||
      location.pathname == JOB_EDIT_PAGE ||
      location.pathname === JOB_COPY_PAGE ||
      location.pathname === TRUCKS_ADD_PAGE ||
      (location.pathname === TRUCKS_PAGE && userType === CARRIER) ||
      matchPath(location.pathname, { path: TRUCKS_EDIT_PAGE })?.isExact ===
        true ||
      (location.pathname === INBOX_PAGE && previousPageWasLoadDetail())
    )
      return (
        <KeyboardBackspaceIcon
          onClick={() => {
            history.goBack();
            return;

            if (
              document.referrer &&
              document.referrer.includes('loads?id=') &&
              !location.state
            )
              window.location.href = document.referrer;
            else history.push(LOADS);
          }}
          className={classes.backBttn}
        />
      );
  };

  const rightSection = () => {
    if (location.pathname === TRUCKS_PAGE && userType === CARRIER) {
      return (
        <ButtonWithBackground
          clickHandler={() => history.push(TRUCKS_ADD_PAGE)}
          size="medium"
          label={'Pin Truck'}
          style={{
            marginRight: 45,
            borderRadius: 11,
            fontWeight: 700,
            paddingRight: 30,
            paddingLeft: 30,
            marginBottom: 0,
            fontSize: 10
          }}
        />
      );
    } else if (
      location.pathname === POST_JOB_PAGE ||
      location.pathname == JOB_EDIT_PAGE ||
      location.pathname === JOB_COPY_PAGE ||
      location.pathname === TRUCKS_ADD_PAGE ||
      matchPath(location.pathname, { path: TRUCKS_EDIT_PAGE })?.isExact ===
        true ||
        (location.pathname === SETTINGS_PAGE &&
          param_tab === 'drivers' &&
          param_id)
      )
      return null;
    return (
      <>
        <NotificationBell />
        <HeaderUserbox setSnackbar={setSnackbar} />
      </>
    );
  };

  const mobileMenuToggleBar = () => {
    return (
      <Box className="toggle-sidebar-btn-mobile">
        <IconButton
          style={{ background: 'transparent' }}
          disableRipple
          color="inherit"
          onClick={toggleSidebarMobile}
          size="medium">
          {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
        </IconButton>
      </Box>
    );
  };
const getAdminHeader = () =>{
  switch(location.pathname)
  {
  case DASHBOARD_PAGE:
    return <Typography className='app-header-text3'  style={{fontSize:28}}>Analytics </Typography>;
  case USERS:
    return <Typography className='app-header-text3' style={{fontSize:28}}>User Management</Typography> ;
   }
  
}
  const brandIcon = () => {
    return (
      <Link to="/" className="app-logo-link">
        <IconButton color="primary" size="medium" className="app-logo-btn">
          <img className="app-logo-img" alt="Logo" src={projectLogo} />
        </IconButton>
      </Link>
    );
  };

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', classes.header, {
          'app-header-collapsed-sidebar': props.isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box className="app-logo-wrapper">
              {brandIcon()}
              <Hidden smDown>
                <Box className="app-logo-text"></Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {backButton()}
             {userType!==ADMIN ?(
             
              <Typography
                variant="h5"
                className={clsx(
                  'd-flex align-items-center',
                  userType === SHIPPER ? 'app-header-text2' : 'app-header-text'
                )}>
                {routeToLable(location.pathname)}

                
              </Typography>
             ):( getAdminHeader())}
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            {addLoadButton()}
            { (location.pathname === CONTACT_BOOK) &&
 (addContactButton())} 
            {rightSection()}

            {mobileMenuToggleBar()}
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  backBttn: {
    cursor: 'pointer',
    stroke: props =>
      props.userType === SHIPPER
        ? theme.palette.secondary.dark
        : theme.palette.primary.dark,
    color: props =>
      props.userType === SHIPPER
        ? theme.palette.secondary.dark
        : theme.palette.primary.dark
  },
  header: {
    borderBottomRightRadius: props => (props.headerCurvy ? 26 : 0)
  },
  headerText:{
    fontSize:'28px',
    color:theme.palette.inherit.dark

  }
}));

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
  setAddCustomerModelOpen: (boolean) => dispatch(setAddCustomerModelOpen(boolean))

});

export default connect(mapStateToProps, mapDispatchToProps)(withDevice(Header));

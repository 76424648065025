import React from 'react';
import clsx from 'clsx';
import MUSkeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

const Skeleton = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <MUSkeleton
      {...rest}
      className={clsx(
        classes.skeleton,
        rest.variant !== 'circle' ? classes.notCircle : '',
        className
      )}
    />
  );
};

const useStyles = makeStyles(theme => ({
  skeleton: {
    background: '#d8d8d8cc'
  },
  notCircle: {
    borderRadius: 10
  }
}));

export default Skeleton;

import React from 'react';

// Components
import MarkerIcon from './MarkerIcon';

const MarkerIcons = ({ icons, mouseLocation }) => {
  return (
    <>
      {icons.map((icon, index) => {
        return (
          <MarkerIcon key={index} mouseLocation={mouseLocation} type={icon} />
        );
      })}
    </>
  );
};

export default MarkerIcons;

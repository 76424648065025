import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@mui/material';
import {
  createStripeAccount,
  createPaymentMethod,
  setPaymentMethodPopupVisible,
  addDebitCard
} from 'reducers/Payment';
import ButtonWithBackground from 'components/Form/Button';
import { connect } from 'react-redux';
import { ERROR } from 'utils/constants';
import Popup from 'components/Popup';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';

const AddCard = ({
  setPaymentMethodPopupVisible,
  paymentMethodPopupVisible,
  debitCardLoading,
  addDebitCard,
  setSnackbar
}) => {
  const classes = useStyles();

  const stripe = useStripe();

  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    stripe.createToken(cardNumberElement).then(function(result) {
      if (result.error) {
        setLoading(false);
        setSnackbar({
          type: ERROR,
          message: result.error.message
        });

        return;
      }

      cardNumberElement.clear();
      cardCvcElement.clear();
      cardExpiryElement.clear();

      if (result.token.card.funding === 'credit') {
        setSnackbar({
          type: ERROR,
          message: 'We are only accepting debit cards!'
        });
        setLoading(false);
        return;
      }

      setLoading(false);

      addDebitCard(result.token.id);
    });
  };

  return (
    <Popup
      open={paymentMethodPopupVisible}
      onclosehandler={() => setPaymentMethodPopupVisible(false)}>
      <Box style={{ maxWidth: 600, padding: 16 }}>
        <Typography variant="h5" style={{ marginBottom: 12 }}>
          Add payment method
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box style={{ marginTop: 10, display: 'flex' }}>
            <Box style={{ width: 200, marginRight: 8 }}>
              <Typography className={classes.label}>Card number</Typography>
              <Box className={classes.field}>
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        color: '#797979'
                      }
                    }
                  }}
                />
              </Box>
            </Box>
            <Box style={{ width: 100, marginRight: 8 }}>
              <Typography className={classes.label}>Expiry date</Typography>
              <Box className={classes.field}>
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        color: '#797979'
                      }
                    }
                  }}
                />
              </Box>
            </Box>
            <Box style={{ width: 100 }}>
              <Typography className={classes.label}>CVV</Typography>
              <Box className={classes.field}>
                <CardCvcElement
                  options={{
                    placeholder: '123',
                    style: {
                      base: {
                        color: '#797979'
                      }
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          <ButtonWithBackground
            disabled={loading || debitCardLoading}
            disabledOnly={!stripe}
            loaderSize={14}
            style={{
              marginTop: 16,
              borderRadius: 9,
              fontSize: 10,
              textTransform: 'capitalize',
              minWidth: 100,
              height: 30,
              position: 'absolute',
              bottom: 16
            }}
            label={`add card`}
            buttonType="submit"
          />
        </form>
      </Box>
    </Popup>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    field: {
      borderRadius: 9,
      border: '2px solid #ccc',
      padding: 10
    },
    label: {
      color: '#454647',
      fontSize: 12,
      marginBottom: 8,
      marginLeft: 12,
      fontWeight: 600
    }
  })
);

const mapStateToProps = state => ({
  user: state.User.user_data,
  createPaymentMethodLoading: state.Payment.createPaymentMethodLoading,
  createStripeAccountLoading: state.Payment.createStripeAccountLoading,
  paymentMethodPopupVisible: state.Payment.paymentMethodPopupVisible,
  debitCardLoading: state.Payment.debitCardLoading
});

const mapDispatchToProps = dispatch => {
  return {
    createStripeAccount: data => dispatch(createStripeAccount(data)),
    createPaymentMethod: data => dispatch(createPaymentMethod(data)),
    setPaymentMethodPopupVisible: bool =>
      dispatch(setPaymentMethodPopupVisible(bool)),
    addDebitCard: token_id => dispatch(addDebitCard(token_id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCard);
